import {
  createApiRef,
  DiscoveryApi,
  OAuthApi,
} from '@backstage/core-plugin-api';

export type BaseControlsData = {
  failed_critical_controls: number;
  failed_high_controls: number;
  failed_low_controls: number;
  failed_medium_controls: number;
  passed_controls: number;
  total_controls: number;
};

export type AwsSecurityHubControlsDataPerTeam = BaseControlsData & {
  team_id: string;
};
export type AwsSecurityHubControlsDataPerCostCenter = BaseControlsData & {
  cost_center_id: string;
};

export class ComplianceAndSecurityAwsSecurityHubApi {
  private readonly discoveryApi: DiscoveryApi;
  private readonly oauth2Api: OAuthApi;

  constructor(options: { discoveryApi: DiscoveryApi; oauth2Api: OAuthApi }) {
    this.discoveryApi = options.discoveryApi;
    this.oauth2Api = options.oauth2Api;
  }

  async fetchAwsSecurityHubControlsByTeam(
    teamId?: string,
  ): Promise<
    AwsSecurityHubControlsDataPerTeam | AwsSecurityHubControlsDataPerTeam[]
  > {
    const token = await this.oauth2Api.getAccessToken();
    const proxyUrl = await this.discoveryApi.getBaseUrl('proxy');

    const apiUrl = `${proxyUrl}/compliance-and-security-aws-security-hub-controls-by-team-api${
      teamId ? `/${teamId}` : ''
    }`;

    const response = await fetch(apiUrl, {
      headers: { Authorization: `Bearer ${token}` },
    });

    return await response.json();
  }

  async fetchAwsSecurityHubFailedControlsByCostCenter(
    costCenter?: string,
  ): Promise<
    | AwsSecurityHubControlsDataPerCostCenter
    | AwsSecurityHubControlsDataPerCostCenter[]
  > {
    const token = await this.oauth2Api.getAccessToken();
    const proxyUrl = await this.discoveryApi.getBaseUrl('proxy');

    const apiUrl = `${proxyUrl}/compliance-and-security-aws-security-hub-controls-by-cost-center-api${
      costCenter ? `/${costCenter}` : ''
    }`;

    const response = await fetch(apiUrl, {
      headers: { Authorization: `Bearer ${token}` },
    });

    return await response.json();
  }
}

export const complianceAndSecurityAwsSecurityHubApiRef =
  createApiRef<ComplianceAndSecurityAwsSecurityHubApi>({
    id: 'plugin.compliance-and-security.aws-security-hub-controls-by-team',
  });
