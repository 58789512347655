import React from 'react';
import { SectionRadio } from '../common';
import { RestApiStats } from './RestApiStats';

export function API() {
  return (
    <section>
      <dl>
        <dt>REST APIs</dt>
        <dd>
          <RestApiStats />
        </dd>
      </dl>

      <SectionRadio name="api_ok" />
    </section>
  );
}
