import React from 'react';
import { ConfigFilter, SearchConfigForGCS } from '../models';
import { AccentColors } from '../constants/AccentColors';
import ExtensionIcon from '@material-ui/icons/Extension';
import { HighlightedGCSTexts } from '../components/SearchResultHighlighter/HighlightedGCSTexts';
import {
  extractURLFromGoogleReferralURL,
  formatIndexLabelToID,
} from '../utils';
import { ConfluenceIcon } from '../icons/ConfluenceIcon';
import { ZalandoIcon } from '../icons/ZalandoIcon';
import { GCSSearchHit } from '../types';

type OtherType = 'zLife' | 'Confluence' | 'Compass' | 'Other';

enum OtherObjectTypes {
  zLife = 'dynamiccontent',
  Confluence = 'page',
  Compass = 'article',
}

/** Get a readable label for the object type */
function getReadableType(objectType: OtherObjectTypes | string): OtherType {
  switch (objectType) {
    case OtherObjectTypes.zLife:
      return 'zLife';
    case OtherObjectTypes.Confluence:
      return 'Confluence';
    case OtherObjectTypes.Compass:
      return 'Compass';
    default:
      return 'Other';
  }
}

const label = 'Other';
const id = formatIndexLabelToID(label);
export const otherIndex = new SearchConfigForGCS({
  id,
  label,
  datasource: ['zlife', 'confluence', 'servicenow'].join(','),
  icon: ExtensionIcon,
  accentColor: AccentColors.Default,
  getUrl: hit => extractURLFromGoogleReferralURL(hit.url),
  getTitle: hit =>
    `[${getReadableType(hit.metadata?.objectType ?? '')}] ${hit.title}`,
  getTitleIcon: (hit: GCSSearchHit) => {
    switch (hit.metadata?.objectType) {
      case OtherObjectTypes.Confluence:
        return ConfluenceIcon;
      case OtherObjectTypes.Compass:
        return ZalandoIcon;
      case OtherObjectTypes.zLife:
        return ZalandoIcon;
      default:
        return ExtensionIcon;
    }
  },
  filters: [
    new ConfigFilter({
      indexId: id,
      label: 'Type',
      field: 'objectType',
      isMultiple: true,
      getOptions: async () => [
        {
          label: getReadableType(OtherObjectTypes.zLife),
          value: OtherObjectTypes.zLife,
        },
        {
          label: getReadableType(OtherObjectTypes.Confluence),
          value: OtherObjectTypes.Confluence,
        },
        {
          label: getReadableType(OtherObjectTypes.Compass),
          value: OtherObjectTypes.Compass,
        },
      ],
    }),
  ],
  render: hit => {
    return <HighlightedGCSTexts snippet={hit.snippet} />;
  },
});
