import React from 'react';
import {
  Avatar,
  Divider,
  Link,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from '@material-ui/core';
import BusinessIcon from '@material-ui/icons/Business';
import * as S from '../styles';
import { useUser } from 'plugin-ui-components';

export const ReviewerCard = ({
  reviewer = {} as IAppReview.StoredReviewReviewer,
}) => {
  const { value: user } = useUser(reviewer.uid || '');

  return (
    <S.StyledCard>
      <S.StyledCardHeader
        title="Reviewer"
        titleTypographyProps={{ component: 'h2' } as any}
      />
      <Divider />
      <S.StyledCardContent>
        <List>
          <ListItem disableGutters>
            <ListItemAvatar>
              <Avatar alt={reviewer.name} src={user?.spec.profile?.picture} />
            </ListItemAvatar>
            <ListItemText
              primary={
                <Typography
                  component="span"
                  variant="body2"
                  color="textPrimary"
                >
                  <Link href={`/catalog/default/user/${reviewer.uid}`}>
                    {reviewer.name || ''}
                  </Link>
                </Typography>
              }
              secondary={reviewer.job_title}
            />
          </ListItem>
          <ListItem disableGutters>
            <ListItemAvatar>
              <Avatar>
                <BusinessIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={
                <Typography
                  component="span"
                  variant="body2"
                  color="textPrimary"
                >
                  {reviewer.team}, {reviewer.department}
                </Typography>
              }
              secondary={reviewer.company}
            />
          </ListItem>
        </List>
      </S.StyledCardContent>
    </S.StyledCard>
  );
};
