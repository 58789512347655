import React from 'react';
import { observer } from 'mobx-react-lite';
import { IWithRepoInfo, RunGroupsModel } from '../../../../models';
import { Emoji, Tooltip } from '../../../../common';
import { QuickActions } from '../../QuickActions';

import {
  BranchTag,
  BuildVersionTag,
  CommitTag,
  PullRequestTag,
  TagGroup,
  UserTag,
  StyledGridColumn,
  StyledGridRow,
  StepsList,
} from '../../';
import {
  durationHumanized,
  getFormattedShortDateTime,
  getFormattedDateTime,
  timeAgo,
} from '../../../../utils/time';
import { IPreferencesService } from '../../../../services';
import * as S from './styles';
import { Status } from '../../Status';
import {
  GitHubTag,
  TagDependency,
  TagHead,
  TagRetrigger,
  TagSchedule,
} from '../../PipelineExecutionTags/PipelineExecutionTags';

interface Props {
  run: RunGroupsModel;
  repoUrl: (run: IWithRepoInfo) => string;
  reload: () => Promise<void>;
  preferencesService: IPreferencesService;
}

export const RunGroupsItemBody = observer(
  ({ run, repoUrl, reload, preferencesService }: Props) => {
    return (
      <StyledGridRow key={`run-${run.id}`}>
        <StyledGridColumn item spaced>
          <Status status={run.status} dense />
          <TagGroup>
            {run.is_head && <TagHead />}

            {run.isSchedule && (
              <TagSchedule
                buildVersion={run.retriggered_from_pipeline_build_version}
              />
            )}

            {run.isRetriggered && (
              <TagRetrigger
                buildVersion={run.retriggered_from_pipeline_build_version}
              />
            )}

            {run.isTriggeredByDependency && (
              <TagDependency
                buildVersion={run.retriggered_from_pipeline_build_version}
                dependency={run.triggered_by_dependency}
              />
            )}

            {run.tags.labels.map(label => (
              <GitHubTag name={label} />
            ))}

            <S.Message
              to={`${repoUrl(run)}/${run.id}/run`}
              title={`${run.commit_message || run.pr_title || 'n/a'}`}
            >
              {run.commit_message || run.pr_title}
            </S.Message>
          </TagGroup>

          <TagGroup>
            <BuildVersionTag
              buildVersion={run.build_version}
              url={`${repoUrl(run)}/${run.id}/run`}
            />
            <BranchTag branch={run.tags.branch} repo={run.target_repo} />
            <UserTag user={run.tags.accountable_user} />
            {run.isPullRequest && (
              <PullRequestTag prNumber={run.pr_number} repo={run.target_repo} />
            )}
            <CommitTag id={run.commit_id} repo={run.target_repo} />
          </TagGroup>
        </StyledGridColumn>
        <StyledGridColumn item>
          <Tooltip title={getFormattedDateTime(run.created_at)}>
            <S.Text>
              {preferencesService.absoluteDate
                ? getFormattedShortDateTime(run.created_at)
                : timeAgo(run.created_at)}
            </S.Text>
          </Tooltip>

          <S.TextLight>
            {run.finished_at &&
              durationHumanized(run.created_at, run.finished_at)}
          </S.TextLight>
        </StyledGridColumn>

        <StyledGridColumn>
          {run.steps && run.steps.length ? (
            <StepsList run={run} repoUrl={repoUrl} />
          ) : (
            <S.ErrorMessage>
              <Tooltip title={run.error}>
                <span>
                  Pipeline creation failed&nbsp;
                  <Emoji name="dizzy face" emoji="😵" />
                </span>
              </Tooltip>
            </S.ErrorMessage>
          )}
        </StyledGridColumn>
        <StyledGridColumn item>
          <QuickActions
            pipelineStatus={run.status}
            steps={run.steps}
            runId={run.id}
            callback={reload}
            hasDependencies={run.hasDependencyVars}
          />
        </StyledGridColumn>
      </StyledGridRow>
    );
  },
);
