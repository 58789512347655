import React from 'react';

const CyberWeekIcon: React.FC<JSX.IntrinsicElements['svg']> = props => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 17 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.1 1.5C6.1 1.22386 6.32386 1 6.6 1C6.87614 1 7.1 1.22386 7.1 1.5C7.1 1.77614 6.87614 2 6.6 2C6.32386 2 6.1 1.77614 6.1 1.5ZM6.6 0C5.77157 0 5.1 0.671573 5.1 1.5C5.1 2.32843 5.77157 3 6.6 3C7.42843 3 8.1 2.32843 8.1 1.5C8.1 0.671573 7.42843 0 6.6 0ZM11.9536 1.25355C12.1488 1.05829 12.1488 0.741709 11.9536 0.546447C11.7583 0.351184 11.4417 0.351184 11.2464 0.546447L5.64645 6.14645C5.45118 6.34171 5.45118 6.65829 5.64645 6.85355C5.84171 7.04882 6.15829 7.04882 6.35355 6.85355L11.9536 1.25355ZM10.5 5.9C10.5 5.62386 10.7239 5.4 11 5.4C11.2761 5.4 11.5 5.62386 11.5 5.9C11.5 6.17614 11.2761 6.4 11 6.4C10.7239 6.4 10.5 6.17614 10.5 5.9ZM11 4.4C10.1716 4.4 9.5 5.07157 9.5 5.9C9.5 6.72843 10.1716 7.4 11 7.4C11.8284 7.4 12.5 6.72843 12.5 5.9C12.5 5.07157 11.8284 4.4 11 4.4ZM3.208 16.5C3.208 15.62 3.92 14.9 4.8 14.9C5.68 14.9 6.4 15.62 6.4 16.5C6.4 17.38 5.68 18.1 4.8 18.1C3.92 18.1 3.208 17.38 3.208 16.5ZM11.208 16.5C11.208 15.62 11.92 14.9 12.8 14.9C13.68 14.9 14.4 15.62 14.4 16.5C14.4 17.38 13.68 18.1 12.8 18.1C11.92 18.1 11.208 17.38 11.208 16.5ZM4.96 12.204L4.936 12.3C4.936 12.412 5.032 12.5 5.136 12.5H14.4V14.1H4.8C3.92 14.1 3.2 13.38 3.2 12.5C3.2 12.22 3.272 11.956 3.4 11.732L4.48 9.772L1.6 3.7H0V2.1H2.616L3.368 3.7L4.128 5.3L5.92 9.084L6.024 9.3H11.64L13.848 5.3L14.728 3.7H14.736L16.128 4.468L13.04 10.076C12.768 10.572 12.24 10.9 11.64 10.9H5.68L4.96 12.204Z"
      fill="currentColor"
    />
  </svg>
);

export default CyberWeekIcon;
