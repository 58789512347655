import React, { FC } from 'react';
import { Grid, GridProps, Typography } from '@material-ui/core';
import { useStyles } from './styles';
import isEmpty from 'lodash/isEmpty';
import { CopyField } from '../CopyField';

export interface AboutFieldProps extends GridProps {
  /** The label you want to display */
  label: React.ReactNode;
  value?: React.ReactNode;
  children?: React.ReactNode;
  canCopy?: boolean;
}

export function AboutField({
  label,
  value,
  children,
  canCopy,
  ...props
}: AboutFieldProps) {
  const styles = useStyles();
  const text = value || children;
  const unmounted = React.useRef(false);

  React.useEffect(() => {
    return () => {
      unmounted.current = true;
    };
  }, []);

  const TextWrapper: FC = canCopy ? CopyField : ({ children: c }) => <>{c}</>;

  return (
    <Grid item {...props}>
      <Typography variant="h6">{label}</Typography>
      <div className={styles.valueContainer}>
        {React.isValidElement(text) ? (
          <TextWrapper>{text}</TextWrapper>
        ) : (
          <span className={styles.value}>
            {isEmpty(text) ? 'unknown' : <TextWrapper>{text}</TextWrapper>}
          </span>
        )}
      </div>
    </Grid>
  );
}
