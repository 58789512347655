import cmp from 'semver-compare';

export function executionViaZflowEnabled(version: string | undefined): boolean {
  if (!version) {
    return false;
  }
  return cmp('1.0.9', version) <= 0;
}

export function getTooltipTitle(executionEnabled: boolean): string {
  if (!executionEnabled) {
    return 'Upgrade to zflow version >= 1.0.9 to use this feature';
  }
  return '';
}
