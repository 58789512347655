import React, { FunctionComponent } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

type ConfirmationDialogProps = {
  open: boolean;
  onClose: Function;
  onSave: Function;
};

const ConfirmationDialog: FunctionComponent<ConfirmationDialogProps> = ({
  open,
  onClose,
  onSave,
  children,
}) => {
  return (
    <Dialog
      open={open}
      onClose={() => onClose()}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="lg"
    >
      <DialogTitle
        disableTypography
        id="alert-dialog-title"
        style={{ display: 'flex', justifyContent: 'space-between' }}
      >
        <Box>
          <Typography variant="h5">Confirmation</Typography>
          <Typography>Are you sure about the changes?</Typography>
        </Box>
        <IconButton aria-label="close" onClick={() => onClose()}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent style={{ width: '500px' }} id="alert-dialog-description">
        {children}
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="secondary" onClick={() => onClose()}>
          Cancel
        </Button>
        <Button variant="contained" color="primary" onClick={() => onSave()}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationDialog;
