import React from 'react';
import { useEntity } from '@backstage/plugin-catalog-react';
import {
  InfoCard,
  StatusError,
  StatusOK,
  StatusWarning,
} from '@backstage/core-components';
import { useApi, configApiRef } from '@backstage/core-plugin-api';
import { Grid, IconButton, Typography } from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { eventTypesApiRef } from '../../api/EventTypesApi';
import { EventTypesManager } from '../../api/EventTypesApi/EventTypesApi';
import { EventType } from '../../domain/EventTypes';
import { EventTypeList } from '../EventTypeList';
import { DLQList } from '../DLQList';
import { useApiCall } from '../../hooks/useApiCall';
import { FeatureFlagged } from '@backstage/core-app-api';
import { Subscriptions } from '../../domain/Subscriptions';
import {
  subscriptionApiRef,
  SubscriptionsManager,
} from '../../api/SubscriptionsApi';
import { SubscriptionList } from '../SubscriptionList';

export function EventsTab() {
  const { entity } = useEntity();
  const application = entity.metadata.name;

  const {
    payLoad: eventTypes = [],
    isLoading: isLoadingEventTypes,
    status: loadingEventTypesStatus,
  } = useApiCall<EventTypesManager, EventType[]>(eventTypesApiRef, api =>
    api.getEventTypes(application),
  );
  const {
    payLoad: subscriptions = [],
    isLoading: isLoadingSubscriptions,
    status: loadingSubscriptionsStatus,
  } = useApiCall<SubscriptionsManager, Subscriptions[]>(
    subscriptionApiRef,
    api => api.getSubscriptions(application),
  );

  const configApi = useApi(configApiRef);
  const nakadiUiURL = configApi.getOptionalString('nakadi.ui.url') ?? '';

  return (
    <Grid container>
      <Grid item sm={12}>
        <Grid item xs={12}>
          <EventTypeList
            status={loadingEventTypesStatus}
            application={application}
            eventTypes={eventTypes}
            isLoading={isLoadingEventTypes}
            nakadiUiURL={nakadiUiURL}
          />
        </Grid>
        <br />
        <FeatureFlagged with="nakadi-dlq">
          <Grid item xs={12}>
            <DLQList application={application} nakadiUiURL={nakadiUiURL} />
          </Grid>
        </FeatureFlagged>
        <FeatureFlagged without="nakadi-dlq">
          <Grid item xs={12}>
            <SubscriptionList
              status={loadingSubscriptionsStatus}
              application={application}
              subscriptions={subscriptions}
              isLoading={isLoadingSubscriptions}
              nakadiUiURL={nakadiUiURL}
            />
          </Grid>
        </FeatureFlagged>
      </Grid>
      <Grid item sm={12}>
        <InfoCard title="Notes">
          <Typography variant="h4">Event Types data</Typography>
          <Typography paragraph>
            <IconButton aria-label="expand row" size="small">
              <KeyboardArrowDownIcon />
            </IconButton>{' '}
            shows dettailed publishing stats.
            <br />
            <br />
            <StatusOK />
            in front of a traffic stat indicates that your publishing rate is
            OK. We recommend you to publish no more than 1 MB/s per partition to
            avoid publishing latencies.
            <br />
            <br />
            <StatusWarning />
            indicates that you are publishing more than 1MB/s per partition.
            <br />
            <br />
            <StatusError />
            indicates that you are publishing more than 2MB/s per partition.
          </Typography>
          <Typography variant="h4">Subscription data</Typography>
          <Typography paragraph>
            <strong>Unconsumed Events</strong>
            is the currently retained number of events unconsumed accross all
            partitions. If this number is higher than events available in
            Nakadi, it means that some events are deleted permanently and never
            consumed.
            <br />
            <br />
            <strong>Consumer lag</strong>
            is the maximum lag accross all partitions.
          </Typography>
        </InfoCard>
      </Grid>
    </Grid>
  );
}
