import React from 'react';
import { SearchConfigForGCS } from '../models';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import { Box, capitalize, Typography } from '@material-ui/core';
import { extractGCSFields, formatIndexLabelToID } from '../utils';
import { TeamReference } from 'plugin-ui-components';
import { HighlightedGCSTexts } from '../components/SearchResultHighlighter/HighlightedGCSTexts';
import { ResultDataList } from '../components/ResultDataList';
import { AccentColors } from '../constants/AccentColors';

type ZDocsMetadata = {
  team: string;
  domain: string;
  author?: string;
  filepath?: string;
  gitRepo?: string;
  priority?: string;
};

const label = 'Documentation';
const id = formatIndexLabelToID(label);
export const zDocsIndex = new SearchConfigForGCS({
  id,
  label,
  datasource: 'zdocs',
  icon: MenuBookIcon,
  accentColor: AccentColors.Documentation,
  objectTypes: ['page'],
  dynamicFilters: {
    domain: {
      optionsLabelMapper: l => capitalize(l.replace(/[-_]/, ' ')),
    },
  },
  getUrl: hit => {
    const { domain, filepath } = extractGCSFields<ZDocsMetadata>(hit);
    let url = `https://${domain}.docs.zalando.net/`;
    if (filepath) url += filepath;
    return url;
  },
  getTitle: hit => hit.title,
  render: hit => {
    const { metadata } = hit;
    const { team } = extractGCSFields<ZDocsMetadata>(hit);

    return (
      <>
        <ResultDataList
          dense
          list={[
            {
              label: 'Owner',
              value: team && <TeamReference team={team} />,
            },
          ]}
        />
        <HighlightedGCSTexts snippet={hit.snippet} />
        {metadata?.updateTime && (
          <Box marginTop={1}>
            <Typography variant="body2">
              Last update on {new Date(metadata?.updateTime).toISODateString()}
            </Typography>
          </Box>
        )}
      </>
    );
  },
});
