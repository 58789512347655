import {
  Entity,
  parseEntityRef,
  stringifyEntityRef,
} from '@backstage/catalog-model';
import { useApi } from '@backstage/core-plugin-api';
import { CatalogApi, catalogApiRef } from '@backstage/plugin-catalog-react';
import { useUser, useUserTeamsApps } from 'plugin-ui-components';
import { useQuery } from 'react-query';

const getScorecardsWithAssessments = async (
  application: Entity,
  catalogApi: CatalogApi,
  scorecardsToShow?: IEntityScorecard[],
): Promise<ScorecardEntityWithAssessment[]> => {
  try {
    // Get all scorecards and assessments related to this application
    const scorecardRefs = application.relations
      ?.filter(rel => rel.type === 'consumesScorecard')
      .filter(rel => {
        // If scorecardsToFilter is provided, only get the scorecards that are in the list
        if (scorecardsToShow) {
          return scorecardsToShow.some(
            scorecard =>
              scorecard.metadata.name === parseEntityRef(rel.targetRef).name,
          );
        }
        return true;
      })
      .map(rel => rel.targetRef);

    const assessmentRefs = application.relations
      ?.filter(rel => rel.type === 'produces')
      .map(rel => rel.targetRef);

    if (scorecardRefs && assessmentRefs) {
      // Fetch scorecards and assessments
      const response = await catalogApi.getEntitiesByRefs({
        entityRefs: [...scorecardRefs, ...assessmentRefs],
      });

      const scorecards = response.items.filter(
        item => item?.kind === 'Scorecard',
      ) as IEntityScorecard[];
      const assessments = response.items.filter(item => {
        const assessment = item as IEntityScorecardAssessment;
        const baseCheck = assessment?.kind === 'ScorecardAssessment';
        // If scorecardsToFilter is provided, only get the assessments for the scorecards in the list
        if (scorecardsToShow) {
          return (
            baseCheck &&
            scorecardsToShow.some(
              scorecard =>
                scorecard.metadata.name ===
                parseEntityRef(assessment.spec.assessment.scorecard).name,
            )
          );
        }
        return baseCheck;
      }) as IEntityScorecardAssessment[];

      const appScorecardsWithAssements = [];
      // Merge scorecards with assessments
      for (const scorecard of scorecards) {
        if (!scorecard) continue;
        const scorecardAppAssessment = assessments.find(
          item =>
            item?.spec.assessment.scorecard === stringifyEntityRef(scorecard),
        );
        if (!scorecardAppAssessment) continue;
        appScorecardsWithAssements.push({
          ...scorecard,
          spec: {
            ...scorecard.spec,
            ...scorecardAppAssessment.spec.assessment,
          },
        });
      }
      return appScorecardsWithAssements;
    }
    return [];
  } catch (e) {
    throw new Error(`Failed to fetch scorecards for this application`);
  }
};

// Hook to fetch all scorecards and assessments related to an application
export const useApplicationScorecards = (
  application: Entity,
  scorecardsToShow?: IEntityScorecard[],
) => {
  const catalogApi = useApi(catalogApiRef);
  return useQuery(`scorecards-${application.metadata.name}`, () =>
    getScorecardsWithAssessments(application, catalogApi, scorecardsToShow),
  );
};

// Hook to fetch all scorecards and assessments related to the user's (watched, owned...) applications
export const useUserApplicationsScorecards = () => {
  const catalogApi = useApi(catalogApiRef);
  const { value: apps = [] } = useUserTeamsApps();
  const { value: user } = useUser();
  /* Query key is linked to the user so we can properly cache the data */
  return useQuery(
    `applications-scorecards-user-${user?.metadata.uid}`,
    () =>
      Promise.all(
        apps.map(app => getScorecardsWithAssessments(app, catalogApi)),
      ),
    {
      enabled: !!user && !!apps.length,
    },
  );
};
