import React from 'react';
import {
  Header,
  Page,
  Content,
  Table,
  TableColumn,
  LinkButton,
} from '@backstage/core-components';
import { Link } from 'react-router-dom';
import { useAsync } from 'react-use';
import { useApi } from '@backstage/core-plugin-api';
import { loadTestingApiRef } from '../../api/LoadTestOrchestratorApi';
import Alert from '@material-ui/lab/Alert';
import { CopyField, FeatureFlagRibbon } from 'plugin-ui-components';
import { useStyles } from '../../StylingComponent/styles';
import Grid from '@material-ui/core/Grid';
import { LinearProgress, Typography } from '@material-ui/core';
import DescriptionIcon from '@material-ui/icons/Description';

interface HttpError extends Error {
  response?: {
    status?: number;
  };
}

export const TestsTableComponent = () => {
  const classes = useStyles();

  const columns: TableColumn<{
    id: string;
    name: string;
    description: string;
    scheduled_for: Date;
  }>[] = [
    {
      title: 'Test ID',
      field: 'id',
      render: rowData => (
        <Grid container spacing={1} alignItems="center">
          <Grid item>
            <CopyField>{rowData.id}</CopyField>
          </Grid>
        </Grid>
      ),
    },
    {
      title: 'Name',
      field: 'name',
      render: rowData => (
        <Link to={`/load-tests/${rowData.id}`} className={classes.link}>
          {rowData.name}
        </Link>
      ),
    },
    {
      title: 'Description',
      field: 'description',
    },
    {
      title: 'Scheduled For',
      field: 'scheduled_for',
      defaultSort: 'desc',
      type: 'datetime',
    },
  ];

  const loadTestingApi = useApi(loadTestingApiRef);

  const {
    value: response = { data: [] },
    loading,
    error,
  } = useAsync(async () => {
    return await loadTestingApi.getTests();
  }, [loadTestingApi]);

  const tests = response.data || [];

  const data = tests.map(test => ({
    id: test.id,
    name: test.metadata.name,
    description: test.metadata.description,
    scheduled_for: test.scheduled_for,
  }));

  return (
    <Page themeId="tool">
      <FeatureFlagRibbon
        name="load-testing"
        theme="purple"
        page
        details="This whole load testing service is enabled by the feature flag"
        forceShow
      >
        <Header title="Welcome to Load Testing">
          <LinkButton
            to="https://continuous-load-testing.docs.zalando.net/"
            size="small"
            variant="outlined"
            color="inherit"
            className={classes.docsButton}
          >
            Docs &nbsp; <DescriptionIcon />
          </LinkButton>
        </Header>
      </FeatureFlagRibbon>
      <Content>
        {loading && <LinearProgress className={classes.linearProgress} />}
        {error ? (
          <Alert severity="error">
            {(error as HttpError)?.response?.status === 401 ? (
              <Typography>
                Access denied. Please reach out to{' '}
                <a
                  href="https://mail.google.com/mail/u/0/#chat/space/AAAAusXPyBM"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ color: 'inherit', textDecoration: 'underline' }}
                >
                  Rendering Engine contributors chat
                </a>{' '}
                if you need access to this API.
              </Typography>
            ) : (
              (error as Error).message
            )}
          </Alert>
        ) : (
          <Table
            title="Your Load Tests"
            data={data}
            isLoading={loading}
            columns={columns}
            options={{
              padding: 'dense',
              pageSize: 20,
              headerStyle: { textTransform: 'capitalize' },
              tableLayout: 'auto',
            }}
          />
        )}
      </Content>
    </Page>
  );
};
