import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import { ReviewContext } from '../../context';
import { steps } from '../../steps';

interface Props {
  toggle: boolean;
  onClose: VoidFunction;
}

export function IncompleteFormDialog({ toggle, onClose }: Props) {
  const { review } = React.useContext(ReviewContext);

  const uncompleted = steps.filter(s => {
    if (s.validate) return !s.validate(review);
    return !s.optional && !review[s.field];
  });

  return (
    <Dialog open={toggle}>
      <DialogTitle>Cannot submit review</DialogTitle>
      <DialogContent>
        Incomplete review! You <em>MUST</em> complete all the required sections:
        <ul className="checklist error" style={{ marginTop: '1rem' }}>
          {uncompleted.map(s => (
            <li key={s.field}>{s.header}</li>
          ))}
        </ul>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
}
