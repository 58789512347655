import React, { useEffect, useState, useCallback } from 'react';
import { useApi } from '@backstage/core-plugin-api';
import { InfoCard, Content } from '@backstage/core-components';
import { useParams } from 'react-router';
import {
  BaseLayout,
  DetailsCard,
  NavigateToLatest,
  NotebookActionBar,
  Artifact,
} from '../../components';
import { Grid, CardContent, CircularProgress } from '@material-ui/core';
import { createPoller } from '../../utils';
import {
  zflowApiRef,
  MLPipelineRun,
  PLACEHOLDER_PIPELINE_RUN,
} from 'plugin-ml-platform';
import { AboutField } from 'plugin-ui-components';

const entries = [
  { key: 'status', displayName: 'Status', type: 'status' },
  { key: 'created_at', displayName: 'Created At', type: 'time' },
  { key: 'last_modified_at', displayName: 'Updated At', type: 'time' },
  { key: 'created_by', displayName: 'Created By', type: 'user' },
  { key: 'params', displayName: 'Params', type: 'kv_table' },
  { key: 'metrics', displayName: 'Metrics', type: 'kv_table' },
];

export const ZflowExperimentDetails = () => {
  const { experimentId = '', experimentNotebookId = '' } = useParams();
  const [zflowExperimentPipelineNmae, setZflowExperimentPipelineName] =
    useState('');
  const [details, setDetails] = useState<MLPipelineRun>(
    PLACEHOLDER_PIPELINE_RUN,
  );
  const zflowApiClient = useApi(zflowApiRef);
  const getDetails = useCallback(async () => {
    const res = await zflowApiClient.getPipelineRun(experimentId);
    setDetails(res);
  }, [experimentId, zflowApiClient]);

  useEffect(() => {
    if (experimentId !== 'latest') {
      getDetails();
    }
  }, [experimentId, getDetails]);

  useEffect(() => {
    const getExperimentNotebook = async () => {
      const res = await zflowApiClient.getPipeline(experimentNotebookId);
      setZflowExperimentPipelineName(res.name);
    };
    getExperimentNotebook();
  }, [experimentNotebookId, zflowApiClient]);

  const onDelete = async (id: string) => {
    return await zflowApiClient.archiveExperimentRun(id);
  };

  const shouldPoll = createPoller(status, getDetails);
  const actionBarComponent = (showFullScreenButton: boolean) => {
    return (
      <Grid item xs={12}>
        <NotebookActionBar
          id={details.run_id}
          name={details.name}
          parentId={experimentNotebookId}
          status={details.status}
          createdBy={details.created_by}
          archived={details.archived}
          showFullScreenButton={showFullScreenButton}
          onDelete={onDelete}
          onPollCallback={getDetails}
        />
      </Grid>
    );
  };
  return experimentId === 'latest' ? (
    <NavigateToLatest parentEntityKey="experimentNotebook" />
  ) : (
    <BaseLayout
      notebookName={zflowExperimentPipelineNmae}
      executionName={details.name}
    >
      <Content>
        <Grid container>
          {actionBarComponent(false)}
          <Grid item xs={12}>
            <InfoCard
              title={
                <>
                  Experiment Details{' '}
                  {shouldPoll ? <CircularProgress size="1rem" /> : null}
                </>
              }
            >
              <CardContent>
                <DetailsCard columns={entries} data={details} />
              </CardContent>
              {details.artifacts && (
                <CardContent>
                  <AboutField
                    label="Artifacts"
                    children={
                      <Grid container spacing={2}>
                        {details.artifacts.map(artifact => (
                          <Artifact id={artifact.id} type={artifact.type} />
                        ))}
                      </Grid>
                    }
                  />
                </CardContent>
              )}
            </InfoCard>
          </Grid>
        </Grid>
      </Content>
    </BaseLayout>
  );
};
