import { Content, Link } from '@backstage/core-components';
import {
  Box,
  Card,
  CardContent,
  LinearProgress,
  Typography,
} from '@material-ui/core';
import { EventTracker, useIsAdmin, useUserTeams } from 'plugin-ui-components';
import React from 'react';
import { useAsyncEntity } from '@backstage/plugin-catalog-react';
import { oauth2ApiRef } from 'plugin-core';
import { Alert } from '@material-ui/lab';
import { useAsync } from 'react-use';
import { configApiRef, useApi } from '@backstage/core-plugin-api';
import { stringifyEntityRef } from '@backstage/catalog-model';
import { catalogAdditionalApiRef } from 'plugin-catalog';
import { ToolingForm, ToolingFormInput } from '../ToolingForm';
import { useMutation } from 'react-query';
import { Tracking } from '../../tracking';
import Container from '@mui/material/Container';

export function ToolingEditPage() {
  const {
    entity,
    loading: entityLoading,
    error: entityError,
  } = useAsyncEntity<IEntityTooling>();
  const {
    value: { memberTeams = [], ledTeams = [] },
    loading: userTeamsLoading,
  } = useUserTeams({ include: { member: true, led: true, accountable: true } });
  const oauth2Api = useApi(oauth2ApiRef);
  const configApi = useApi(configApiRef);
  const catalogAdditionalApi = useApi(catalogAdditionalApiRef);
  const { value: token } = useAsync(() => oauth2Api.getAccessToken());
  const isLoading = entityLoading || userTeamsLoading;

  const isAdmin = useIsAdmin();
  const isOwner = [...memberTeams, ...ledTeams].some(team =>
    [team.spec.fullName, team.metadata.name].includes(entity?.spec.sapId || ''),
  );
  const canEdit = isOwner || isAdmin;
  // * Create a mutation to send the tooling object to the API
  const toolingEditMutation = useMutation({
    mutationFn: async (newTooling: ToolingFormInput) => {
      // Clean empty fields to avoid validation error in backend
      for (const field in newTooling) {
        if (newTooling.hasOwnProperty(field)) {
          const value = (newTooling as any)[field];
          // compare value with an empty string
          if (value === '') {
            // remove the field from the object
            delete (newTooling as any)[field];
          }
        }
      }
      const resp = await fetch(
        `${configApi.getString('backend.baseUrl')}/tooling/${entity?.spec.id}`,
        {
          method: 'PATCH',
          headers: new Headers({
            authorization: `Bearer ${token}`,
            'content-type': 'application/json',
          }),
          body: JSON.stringify(newTooling),
        },
      );
      if (!resp.ok) throw new Error('Failed to update tooling');

      // Refresh entity after update
      const entityRef = {
        kind: 'Tooling',
        namespace: 'default',
        name: `${entity?.metadata.name}`,
      };
      const toRefresh = [];
      toRefresh.push(
        catalogAdditionalApi.refreshEntity(stringifyEntityRef(entityRef), 5),
      );
      if (newTooling.name !== entity?.metadata.name) {
        toRefresh.push(
          catalogAdditionalApi.refreshEntity(
            stringifyEntityRef({
              ...entityRef,
              name: newTooling.name,
            }),
            5,
          ),
        );
        if (entity?.metadata.name) entity.metadata.name = newTooling.name;
      }
      const refreshResponses = await Promise.allSettled(toRefresh);
      refreshResponses.forEach(refreshResp => {
        if (refreshResp.status === 'rejected' || !refreshResp.value.refreshed) {
          throw new Error(
            'Tooling was updated but we failed to refresh the entity, so you may not see the changes immediately.',
          );
        }
      });
    },
  });

  return (
    <Content>
      <Container maxWidth="lg">
        <Card>
          <CardContent>
            <Typography variant="h3" style={{ marginBottom: '1rem' }}>
              Edit Tooling information
            </Typography>
            {!canEdit && (
              <Box marginBottom={2}>
                <Alert severity="warning">
                  Only the owner of this tooling can edit it.
                </Alert>
              </Box>
            )}
            {toolingEditMutation.isSuccess && (
              <EventTracker {...Tracking.editTooling()}>
                <Box marginBottom={2}>
                  <Alert severity="success">
                    Tooling has been successfully updated. If you changed the
                    title, please click on {/* TODO: get new Tooling name */}
                    <Link
                      to={`/catalog/default/Tooling/${entity?.metadata.name}`}
                    >
                      this link
                    </Link>{' '}
                    to refresh the page.
                  </Alert>
                </Box>
              </EventTracker>
            )}
            {toolingEditMutation.isError && (
              <Box marginBottom={5}>
                <Alert severity="error">
                  An error occurred while submitting the form:{' '}
                  {(toolingEditMutation.error as Error).message}
                </Alert>
              </Box>
            )}
            {isLoading && <LinearProgress color="primary" />}
            {entity && !isLoading && (
              <ToolingForm
                entity={entity}
                mode="edit"
                onSubmit={data => toolingEditMutation.mutate(data)}
                isLoading={toolingEditMutation.isLoading}
                canEdit={canEdit}
              />
            )}
            {entityError && (
              <h1>An error occurred while fetching this entity.</h1>
            )}
          </CardContent>
        </Card>
      </Container>
    </Content>
  );
}
