import React from 'react';
import * as Sentry from '@sentry/react';
import { configApiRef, useApi } from '@backstage/core-plugin-api';

const isProduction = process.env.NODE_ENV === 'production';

const ErrorFallbackComponent: React.FC = () => {
  return <div>An error has occurred</div>;
};

export const WithSentry: React.FC = props => {
  const [initialized, setInitialized] = React.useState<boolean>(false);
  const configApi = useApi(configApiRef);
  const dsn = configApi.getOptionalString('features.sentry.dsn');
  const version = configApi.getOptionalString('release.version') || '';
  const environment =
    configApi.getOptionalString('features.sentry.environment') || 'dev';

  React.useEffect(() => {
    if (isProduction && dsn && !initialized) {
      Sentry.init({
        dsn: dsn,
        release: version,
        environment: environment,
        debug: environment !== 'prod',
        autoSessionTracking: false,
      });
      setInitialized(true);
    }
  }, [initialized, dsn, version, environment]);

  if (!initialized) {
    return <>{props.children}</>;
  }

  return (
    <Sentry.ErrorBoundary fallback={<ErrorFallbackComponent />}>
      {props.children}
    </Sentry.ErrorBoundary>
  );
};
