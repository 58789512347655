import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import { v4 as uuidv4 } from 'uuid';
import { ThemeProvider, Tooltip } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import { useApi } from '@backstage/core-plugin-api';
import { zflowApiRef } from '../../../api/zflowApiClient';
import { RunStatus } from '../../../api/definitions';
import { getTooltipTitle } from '../../../utils/goto';
import { ButtonTheme } from '../../common/customTheme';
import { ManageRunButton } from './ManageRunButton';

interface GotoProps {
  projectId: string;
  pipelineId: string;
  stepName: string;
  executionId: string;
  executionViaZflowEnabled: boolean;
  gotoEnabledPipeline: boolean;
  gotoEnabledStep: boolean;
}

export const GotoButton = (props: GotoProps) => {
  const zflowApi = useApi(zflowApiRef);
  const navigate = useNavigate();
  const randomRunID = uuidv4();
  const [responseError, setResponseError] = useState<Array<string>>([]);
  const tooltipTitle = getTooltipTitle(
    props.executionViaZflowEnabled,
    props.gotoEnabledPipeline,
    props.gotoEnabledStep,
  );

  function gotoRunInput(stepId: string, executionId: string) {
    return `{"start_from_stage": "${stepId}", "execution_id": "${executionId}"}`;
  }

  function handleStartRun() {
    setResponseError([]);
    zflowApi
      .startRun(
        props.projectId,
        props.pipelineId,
        randomRunID,
        RunStatus.Initialized,
        gotoRunInput(props.stepName, props.executionId),
      )
      .then(result => {
        if (Array.isArray(result)) {
          setResponseError(result);
        } else {
          navigate(
            `/ml/projects/${props.projectId}/pipelines/${props.pipelineId}/runs/${result.run_id}`,
          );
        }
      });
  }

  return (
    <ThemeProvider theme={ButtonTheme}>
      <Tooltip title={tooltipTitle}>
        <ManageRunButton
          data-testid="goto-button"
          variant="contained"
          color="primary"
          onClick={handleStartRun}
          startIcon={<PlayArrowIcon />}
          disabled={
            !props.executionViaZflowEnabled ||
            !props.gotoEnabledPipeline ||
            !props.gotoEnabledStep
          }
        >
          New Run From Here
        </ManageRunButton>
      </Tooltip>
      {responseError.length > 0 && (
        <Alert
          severity="error"
          onClick={() => {
            setResponseError([]);
          }}
          style={{ width: 'min-content' }}
        >
          <AlertTitle>Unable to start a new run</AlertTitle>
          {responseError && (
            <ul>
              {responseError.map(violation => {
                return <li key={uuidv4()}>{violation}</li>;
              })}
            </ul>
          )}
        </Alert>
      )}
    </ThemeProvider>
  );
};
