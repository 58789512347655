import React from 'react';
import { useParams } from 'react-router';
import { TabbedLayout } from '@backstage/core-components';

import { ExperimentNotebooks } from '../ExperimentNotebooks';
import { ScheduledNotebooks } from '../ScheduledNotebooks';
import { SharedNotebooks } from '../SharedNotebooks';
import { Dashboards } from '../Dashboards';
import { BaseLayout } from '../../components';

export const ml_experimentation_tabs = (projectId: string) => [
  {
    path: '/shared_notebooks',
    title: 'Shared notebooks',
    component: <SharedNotebooks projectId={projectId} />,
  },
  {
    path: '/scheduled_notebooks',
    title: 'Scheduled notebooks',
    component: <ScheduledNotebooks projectId={projectId} />,
  },
  {
    path: '/experiment_notebooks',
    title: 'Experiments',
    component: <ExperimentNotebooks projectId={projectId} />,
  },
  {
    path: '/r_shiny_apps',
    title: 'R shiny apps',
    component: <Dashboards projectId={projectId} />,
  },
];

export const Home = () => {
  const { projectId = '' } = useParams();
  return (
    <BaseLayout>
      <TabbedLayout>
        {ml_experimentation_tabs(projectId).map(
          ({ path, title, component }) => (
            <TabbedLayout.Route key={path} path={path} title={title}>
              {component}
            </TabbedLayout.Route>
          ),
        )}
      </TabbedLayout>
    </BaseLayout>
  );
};
