import Assignment from '@material-ui/icons/Assignment';
import Build from '@material-ui/icons/Build';
import ImportContacts from '@material-ui/icons/ImportContacts';
import Code from '@material-ui/icons/Code';
import Settings from '@material-ui/icons/Settings';
import Visibility from '@material-ui/icons/Visibility';
import Storage from '@material-ui/icons/Storage';
import BusinessCenter from '@material-ui/icons/BusinessCenter';

export default [
  {
    title: 'PLAN',
    Icon: Assignment,
    links: [
      {
        link: 'https://github.bus.zalan.do/technologist-guild/tech-radar/issues/new',
        linkName: 'Tech Radar',
        channel: 'Github',
      },
      {
        link: 'mailto:jira-support@zalando.de',
        linkName: 'Jira',
        channel: 'Email',
      },
      {
        link: 'https://github.bus.zalan.do/machinery/support/issues/new/choose',
        linkName: 'ZenHub',
        channel: 'Github',
      },
    ],
  },
  {
    title: 'SETUP',
    Icon: Build,
    links: [
      {
        link: 'mailto:iam-support@zalando.de',
        linkName: 'IAM / Oauth',
        channel: 'Email',
      },
      {
        link: 'https://github.bus.zalan.do/zooport/issues/issues/new/choose',
        linkName: 'FabricGateway',
        channel: 'Github',
      },
      {
        link: 'https://github.bus.zalan.do/zooport/issues/issues/new/choose',
        linkName: 'Skipper',
        channel: 'Github',
      },
      {
        link: 'https://chat.google.com/room/AAAAI1Ckbas?cls=7',
        linkName: 'Image, Video and Static Assets CDNs',
        channel: 'Google Chat',
      },
      {
        link: 'https://chat.google.com/room/AAAARnCf5Jc?cls=7',
        linkName: 'Lokalisation',
        channel: 'Google Chat',
      },
      {
        link: 'https://github.bus.zalan.do/machinery/support/issues/new/choose',
        linkName: 'ComPR',
        channel: 'Github',
      },
      {
        link: 'https://github.bus.zalan.do/machinery/support/issues/new/choose',
        linkName: 'Zappr',
        channel: 'Github',
      },
      {
        link: 'https://mail.google.com/chat/u/0/#chat/space/AAAALloXBkk',
        linkName: 'Docker on MacOs',
        channel: 'Google Chat',
      },
      {
        link: 'https://zalandoprod.service-now.com/ep?id=sc_cat_item&sys_id=7dc74c1a13995050f4cbb3776144b0f4',
        linkName: 'Microsoft / Windows',
        channel: 'Compass',
      },
      {
        link: 'https://zalandoprod.service-now.com/ep?id=sc_cat_item&sys_id=7dc74c1a13995050f4cbb3776144b0f4',
        linkName: 'Apple / Mac / iPhone',
        channel: 'Compass',
      },
      {
        link: 'https://zalandoprod.service-now.com/ep?id=sc_cat_item&sys_id=7dc74c1a13995050f4cbb3776144b0f4',
        linkName: 'Linux',
        channel: 'Compass',
      },
      {
        link: 'https://chat.google.com/room/AAAAcQp8juk',
        linkName: 'Slurm (HPC)',
        channel: 'Google Chat',
      },
      {
        link: 'https://zalandoprod.service-now.com/ep?id=sc_cat_item&sys_id=7dc74c1a13995050f4cbb3776144b0f4',
        linkName: 'Zack',
        channel: 'Compass',
      },
      {
        link: 'https://github.bus.zalan.do/pitchfork/issues/issues/new/choose',
        linkName: 'Application Registry / Kio',
        channel: 'Github',
      },
      {
        link: 'https://github.bus.zalan.do/machinery/support/issues/new/choose',
        linkName: 'Create (Repository Manager)',
        channel: 'Github',
      },
      {
        link: 'https://github.bus.zalan.do/pitchfork/issues/issues/new/choose',
        linkName: 'Sunrise',
        channel: 'Github',
      },
    ],
  },
  {
    title: 'DESIGN',
    Icon: ImportContacts,
    links: [
      {
        link: 'https://github.bus.zalan.do/machinery/support/issues/new/choose',
        linkName: 'API Portal',
        channel: 'Github',
      },
      {
        link: 'https://github.bus.zalan.do/pitchfork/issues/issues/new/choose',
        linkName: 'API Linter',
        channel: 'Github',
      },
      {
        link: 'https://github.bus.zalan.do/team-architecture/issues/issues/new/choose',
        linkName: 'Functional Component',
        channel: 'Github',
      },
      {
        link: 'mailto:it-security-consulting@zalando.de',
        linkName: 'IT Security Consulting',
        channel: 'Email',
      },
    ],
  },
  {
    title: 'CODE',
    Icon: Code,
    links: [
      {
        link: 'https://github.bus.zalan.do/pitchfork/issues/issues/new/choose',
        linkName: 'Documentation Hosting',
        channel: 'Github',
      },
      {
        link: 'https://github.bus.zalan.do/machinery/support/issues/new/choose',
        linkName: 'GitHub Enterprise',
        channel: 'Github',
      },
      {
        link: 'https://github.bus.zalan.do/machinery/support/issues/new/choose',
        linkName: 'GitHub Copilot',
        channel: 'Github',
      },
      {
        link: 'https://github.bus.zalan.do/aruha/issues/issues/new/choose',
        linkName: 'Nakadi',
        channel: 'Github',
      },
      {
        link: 'https://github.bus.zalan.do/machinery/support/issues/new/choose',
        linkName: 'Dependabot',
        channel: 'Github',
      },
      {
        link: 'https://github.bus.zalan.do/machinery/support/issues/new/choose',
        linkName: 'Nexus',
        channel: 'Github',
      },
      {
        link: 'https://github.bus.zalan.do/Security/Prodsec-Platform-Issue-Board/issues/new/choose',
        linkName: 'Checkmarx',
        channel: 'Github',
      },
      {
        link: 'https://github.bus.zalan.do/machinery/support/issues/new/choose',
        linkName: 'Jetbrains IDEs',
        channel: 'Github',
      },
      {
        link: 'https://github.bus.zalan.do/machinery/support/issues/new/choose',
        linkName: 'Software Bill of Materials (SBOM)',
        channel: 'Github',
      },
      {
        link: 'https://github.bus.zalan.do/machinery/support/issues/new/choose',
        linkName: 'VS Code Live Share Extension',
        channel: 'Github',
      },
      {
        link: 'https://zalandoprod.service-now.com/ep?id=sc_cat_item&sys_id=7dc74c1a13995050f4cbb3776144b0f4',
        linkName: 'SQL Server / MSSQL Server',
        channel: 'Compass',
      },
    ],
  },
  {
    title: 'BUILD, TEST, DEPLOY',
    Icon: Settings,
    links: [
      {
        link: 'https://github.bus.zalan.do/zooport/issues/issues/new/choose',
        linkName: 'Continuous Delivery (CDP +)',
        channel: 'Github',
      },
      {
        link: 'https://github.bus.zalan.do/zooport/issues/issues/new/choose',
        linkName: 'Kubernetes',
        channel: 'Github',
      },
      {
        link: 'https://github.bus.zalan.do/zooport/issues/issues/new/choose',
        linkName: 'Managed AWS',
        channel: 'Github',
      },
      {
        link: 'https://github.bus.zalan.do/zooport/issues/issues/new/choose',
        linkName: 'SauceLabs',
        channel: 'Github',
      },
      {
        link: 'https://github.bus.zalan.do/machinery/support/issues/new/choose',
        linkName: 'Codacy',
        channel: 'Github',
      },
      {
        link: 'https://github.bus.zalan.do/zooport/issues/issues/new/choose',
        linkName: 'Pierone',
        channel: 'Github',
      },
    ],
  },
  {
    title: 'MONITOR',
    Icon: Visibility,
    links: [
      {
        link: 'https://github.bus.zalan.do/SRE/tasks/issues/new?assignees=&labels=type%3Asupport%2Capp%3Aobs-sdk&projects=SRE%2F8&template=support.yml',
        linkName: 'Observability SDKs',
        channel: 'Github',
      },
      {
        link: 'https://github.bus.zalan.do/SRE/tasks/issues/new?assignees=&labels=type%3Asupport%2Capp%3Agrafana&projects=SRE%2F8&template=support.yml',
        linkName: 'Grafana',
        channel: 'Github',
      },
      {
        link: 'https://github.bus.zalan.do/SRE/tasks/issues/new?assignees=&labels=type%3Asupport%2Capp%3Atracing&projects=SRE%2F8&template=support.yml',
        linkName: 'Lightstep (Tracing)',
        channel: 'Github',
      },
      {
        link: 'https://github.bus.zalan.do/SRE/tasks/issues/new?assignees=&labels=type%3Asupport%2Capp%3Azmon&projects=SRE%2F8&template=support.yml',
        linkName: 'ZMON (Metrics)',
        channel: 'Github',
      },
      {
        link: 'https://github.bus.zalan.do/machinery/support/issues/new/choose',
        linkName: 'API Monitoring',
        channel: 'Github',
      },
      {
        link: 'https://github.bus.zalan.do/SRE/tasks/issues/new?assignees=&labels=type%3Asupport%2Capp%3Asentry&projects=SRE%2F8&template=support.yml',
        linkName: 'Sentry',
        channel: 'Github',
      },
      {
        link: 'https://github.bus.zalan.do/SRE/tasks/issues/new?assignees=&labels=type%3Asupport%2Capp%3Alogging-agent&projects=SRE%2F8&template=support.yml',
        linkName: 'Logging (incl. DataSet/Scalyr)',
        channel: 'Github',
      },
    ],
  },
  {
    title: 'OPERATE',
    Icon: Storage,
    links: [
      {
        link: 'https://github.bus.zalan.do/acid/elephant/issues/new?assignees=&labels=question%2Ctype%3Amigration%2Capp%3Aeventlog%2Ctriage&projects=acid%2F2&template=40-eventlog.yml&title=%5BEVENTLOG%5D%3A+',
        linkName: 'Eventlog',
        channel: 'Github',
      },
      {
        link: 'https://github.bus.zalan.do/acid/elephant/issues/new?assignees=&labels=question%2Ctriage&projects=acid%2F2&template=30-support-request.yml&title=%5BSUPPORT%5D%3A+',
        linkName: 'PostgreSQL',
        channel: 'Github',
      },
      {
        link: 'https://chat.google.com/room/AAAANSIqf-w',
        linkName: 'Cassandra',
        channel: 'Google Chat',
      },
      {
        link: 'https://github.bus.zalan.do/SRE/support/issues/new/choose',
        linkName: 'Nobl9 (SLOs)',
        channel: 'Github',
      },
      {
        link: 'https://github.bus.zalan.do/SRE/support/issues/new/choose',
        linkName: 'Adaptive Paging',
        channel: 'Github',
      },
      {
        link: 'https://github.bus.zalan.do/inciders/support/issues/new/choose',
        linkName: 'Risk Register',
        channel: 'Github',
      },
      {
        link: 'https://github.bus.zalan.do/inciders/issues/issues/new/choose',
        linkName: 'OpsGenie',
        channel: 'Github',
      },
      {
        link: 'https://github.bus.zalan.do/zooport/issues/issues/new/choose',
        linkName: 'DNS & Certificates',
        channel: 'Github',
      },
      {
        link: 'https://chat.google.com/preview/room/AAAAir9d-PE',
        linkName: 'Datacenters',
        channel: 'Google Chat',
      },
      {
        link: 'https://github.bus.zalan.do/machinery/support/issues/new/choose',
        linkName: 'Code Insights (Software Performance Indicators)',
        channel: 'Github',
      },
      {
        link: 'https://chat.google.com/room/AAAAMKaWI44',
        linkName: 'Cloud Cost Reporting',
        channel: 'Google Chat',
      },
      {
        link: 'https://zalandoprod.service-now.com/ep?id=sc_cat_item&sys_id=399890071b1d0114a12f85926b4bcb19',
        linkName: 'Security Incident',
        channel: 'Compass',
      },
      {
        link: 'https://github.bus.zalan.do/inciders/issues/issues/new/choose',
        linkName: 'Incident Tooling & Reporting',
        channel: 'Github',
      },
      {
        link: 'https://github.bus.zalan.do/pitchfork/issues/issues/new/choose',
        linkName: 'Application Reviews',
        channel: 'Github',
      },
      {
        link: 'https://github.bus.zalan.do/zooport/issues/issues/new/choose',
        linkName: 'Akamai (Edge Protection)',
        channel: 'Github',
      },
    ],
  },
  {
    title: 'UTILITY',
    Icon: BusinessCenter,
    links: [
      {
        link: 'https://zalandoprod.service-now.com/ep?id=sc_cat_item&table=sc_cat_item&sys_id=7dc74c1a13995050f4cbb3776144b0f4',
        linkName: 'Company Laptop',
        channel: 'Compass',
      },
      {
        link: 'https://zalandoprod.service-now.com/ep?id=sc_cat_item&table=sc_cat_item&sys_id=7dc74c1a13995050f4cbb3776144b0f4',
        linkName: 'VPN',
        channel: 'Compass',
      },
      {
        link: 'https://aws.zalando.net/',
        linkName: 'AWS Enterprise',
        channel: 'External Link',
      },
      {
        link: 'https://zalandoprod.service-now.com/ep?category=5eff9e701bde4d109eca777c8b4bcb85&id=sc_category&spa=1',
        linkName: 'Google Suite',
        channel: 'Compass',
      },
      {
        link: 'mailto:team_changes@zalando.de',
        linkName: 'Team Change',
        channel: 'Email',
      },
      {
        link: 'https://zlife.zalando.net/pages/zlife-support/apps/content/zlife-support',
        linkName: 'zLife',
        channel: 'External Link',
      },
    ],
  },
];
