import React, { useEffect, useState } from 'react';
import { Grid, makeStyles, Typography } from '@material-ui/core';
import { DependencyGraph } from '@backstage/core-components';
import { InfoCard } from 'plugin-ui-components';

import {
  MLPipelineRun,
  MLPipelineRunStep,
  RunStatus,
} from '../../api/definitions';
import { usePluginTheme } from '../../hooks/usePluginTheme';
import { RunStepDetails } from './RunStepDetails';
import { RunHelpers, GotoHelpers } from './helpers';

import { GotoButton } from './ManageRuns/GotoButton';
import { CDPUtils } from '../../utils/cdp';
import { executionViaZflowEnabled } from '../../utils/version';
import { STEP_NAME_PREFIX } from '../../constants/common';

interface GraphEdges {
  to?: string;
  from: string;
}

const useStyles = makeStyles({
  highlightSelected: (props: any) => ({
    border: '5px solid #666',
    strokeWidth: '1',
    stroke: props.pluginTheme === 'light' ? '#666' : '#eee',
  }),
});

export const RunGraph = ({ run }: { run: MLPipelineRun }) => {
  const pluginTheme = usePluginTheme();
  const styles = useStyles({ pluginTheme: pluginTheme });
  const [selectedStep, setSelectedStep] = useState<string>();

  const cdpUtils = new CDPUtils(run.metadata, run.run_id, 'run');
  const executionEnabled = executionViaZflowEnabled(cdpUtils.zflowVersion());

  const data = run.steps.map(x => ({
    ...x,
    next_step_ids: x.next_step_ids || [],
  }));

  const nodes = data.map(step => {
    return {
      id: step.name,
      color: RunHelpers.getColor(step.status),
    };
  });

  function getEdges() {
    const edges: GraphEdges[] = [];
    data.map((step: MLPipelineRunStep) => {
      const nextStep: any = step?.next_step_ids;
      if (nextStep) {
        nextStep.map((nextStepId: any) => {
          const currentStepId = data.find(
            runStep => runStep.step_id === nextStepId,
          );
          edges.push({
            from: step.name,
            to: currentStepId?.name,
          });
        });
      }
    });

    return edges;
  }

  const edges = getEdges().filter(value => Object.keys(value).length);

  function getStepDetails(stepName: string | undefined) {
    return data.find(steps => steps.name === stepName);
  }

  function getCleanStepName(stepName: string) {
    return stepName.replace(STEP_NAME_PREFIX, '');
  }

  const gotoStepName = getCleanStepName(
    getStepDetails(selectedStep)?.name ?? 'default-step-name',
  );

  const selectedStepHasDetails = selectedStep
    ? !!getStepDetails(selectedStep)
    : false;

  const gotoEnabledPipeline = GotoHelpers.getGotoEnabledPipelineFlag(run);

  const selectedStepStatus = getStepDetails(selectedStep)?.status;
  const gotoEnabledStep = GotoHelpers.getGotoEnabledStepFlag(
    selectedStepStatus ?? RunStatus.Unknown,
    gotoStepName,
  );

  const getSubHeader =
    run.status === RunStatus.Initialized
      ? 'Initializing graph...'
      : 'TIP: You can zoom and drag the graph';

  const canvas: HTMLCanvasElement = document.createElement('canvas');
  const context: CanvasRenderingContext2D | null = canvas.getContext('2d');

  function getTextWidth(text: string) {
    const metrics = context?.measureText(text);
    const width = metrics?.width || 0;
    return width * 1.8;
  }

  useEffect(() => {
    setSelectedStep(undefined);
  }, [run.run_id]);

  return (
    <Grid container>
      <Grid item lg={6} xs={12}>
        <InfoCard title="Run Graph" subheader={getSubHeader}>
          <div style={{ width: '100%' }}>
            <DependencyGraph
              nodes={nodes}
              edges={edges as any}
              paddingX={30}
              preserveAspectRatio="xMinYMin"
              paddingY={30}
              renderNode={props => (
                <g
                  onClick={_ => setSelectedStep(props.node.id)}
                  data-testid={`pipeline-step-${props.node.id
                    .replace(/ /g, '')
                    .toLowerCase()}`}
                >
                  <rect
                    className={
                      selectedStep === props.node.id
                        ? styles.highlightSelected
                        : undefined
                    }
                    width={getTextWidth(props.node.id)}
                    height={35}
                    rx={5}
                    ry={5}
                    fill={props.node.color}
                  />
                  <text
                    x={getTextWidth(props.node.id) / 2}
                    y={22}
                    textAnchor="middle"
                    alignmentBaseline="baseline"
                    style={{ cursor: 'pointer' }}
                  >
                    {props.node.id}
                  </text>
                </g>
              )}
            />
          </div>
        </InfoCard>
      </Grid>
      <Grid item lg={6} xs={12}>
        <InfoCard
          title="Step Details"
          cardHeaderProps={{
            action: (
              <div>
                {selectedStepHasDetails && (
                  <GotoButton
                    projectId={run.project_id}
                    pipelineId={run.pipeline_id}
                    stepName={gotoStepName}
                    executionId={run.name}
                    executionViaZflowEnabled={executionEnabled}
                    gotoEnabledPipeline={gotoEnabledPipeline}
                    gotoEnabledStep={gotoEnabledStep}
                  />
                )}
              </div>
            ),
          }}
          subheader="Click on any step to get more details"
        >
          {selectedStepHasDetails ? (
            <RunStepDetails stepDetails={getStepDetails(selectedStep) as any} />
          ) : (
            <Typography align="center" variant="body2">
              No step selected
            </Typography>
          )}
        </InfoCard>
      </Grid>
    </Grid>
  );
};
