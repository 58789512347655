import React from 'react';
import { SectionRadio } from '../common';
import { LightstepStats } from './LightstepStats';

export function OpenTracing() {
  return (
    <section>
      <dl>
        <dt>LightStep</dt>
        <dd>
          <LightstepStats />
        </dd>
      </dl>

      <SectionRadio name="opentracing_ok" />
    </section>
  );
}
