import React from 'react';
import { Box, Typography } from '@material-ui/core';
import Slider, { Mark } from '@material-ui/core/Slider';
import { useQueryParamState } from '@backstage/core-components';
import { useStyles } from './styles';

interface Props {
  marks: Mark[];
  maxDepth: number;
}

export const DepthSlider = React.memo(({ marks, maxDepth }: Props) => {
  const styles = useStyles();
  const [depth, setDepth] = useQueryParamState<number>('depth');
  const [sliderValue, setSliderValue] = React.useState(-Number(depth || 1));

  React.useEffect(() => {
    setSliderValue(-Number(depth || 1));
  }, [depth]);

  return (
    <div>
      <Box className={styles.sliderWrapper}>
        <Typography variant="h6" className={styles.sliderHeader}>
          Depth to show
        </Typography>
        <div className={styles.sliderSize}>
          <Slider
            orientation="vertical"
            value={sliderValue}
            onChange={(_, val) => setSliderValue(val as number)}
            onChangeCommitted={(_, val: any) => setDepth(Math.abs(val))}
            marks={marks}
            valueLabelDisplay="auto"
            step={1}
            min={-maxDepth}
            max={-1}
            scale={x => -x}
            track="inverted"
          />
        </div>
      </Box>
    </div>
  );
});
