import { Entity } from '@backstage/catalog-model';

export const calculateScorecardCompletion = (
  scorecards: ScorecardEntityWithAssessment[],
) => {
  if (!scorecards) return 0;
  const totalScorecards = scorecards.filter(
    sc => !!sc.spec.checks.length,
  ).length;
  const completedScorecards = scorecards
    .filter(sc => !!sc.spec.checks.length)
    .filter(scorecard => scorecard.spec.completionPercentage === 100).length;
  return Math.round((completedScorecards / totalScorecards) * 100) || 0;
};

export const getColor = (
  completionPercentage: number,
): 'success' | 'warning' | 'error' => {
  if (completionPercentage >= 90) {
    return 'success';
  }
  if (completionPercentage >= 50) {
    return 'warning';
  }
  return 'error';
};

/**
 * Copied from plugins/scorecards-backend/src/utils/general.util.ts
 */
export function getPropertyValueFromObject(
  obj: any,
  chainedProperties: string,
) {
  try {
    return chainedProperties.split('.').reduce((acc, key) => acc[key], obj);
  } catch (e) {
    return null;
  }
}

/**
 * Copied from plugins/scorecards-backend/src/utils/assessment.util.ts
 */
export function injectEntityProperties(str: string, entity: IEntity | Entity) {
  let formattedUrl = str;
  // find and replace all ${} in the url with entity properties
  const matches = str.match(/\${(.*?)}/g) ?? [];
  matches.forEach(match => {
    const property = match.replace('${', '').replace('}', '');
    formattedUrl = formattedUrl.replace(
      match,
      getPropertyValueFromObject(entity, property),
    );
  });
  return formattedUrl;
}
