import React, { useEffect, useState, useCallback } from 'react';
import { InfoCard, Content } from '@backstage/core-components';
import { useApi } from '@backstage/core-plugin-api';
import { useParams } from 'react-router';
import {
  BaseLayout,
  DetailsCard,
  NavigateToLatest,
  NotebookActionBar,
} from '../../components';
import { ExperimentResponse, notebooksApiRef } from '../../api';
import { Grid, CardContent, CircularProgress } from '@material-ui/core';
import { createPoller } from '../../utils';

const entries = [
  { key: 'status', displayName: 'Status', type: 'status' },
  { key: 'created_at', displayName: 'Created At', type: 'time' },
  { key: 'last_modified_at', displayName: 'Updated At', type: 'time' },
  { key: 'created_by', displayName: 'Created By', type: 'user' },
  {
    key: 'notebook_path',
    displayName: 'Source notebook',
    type: 'notebook_path',
  },
  { key: 'kernel_name', displayName: 'Kernel' },
  { key: 'params', displayName: 'Params', type: 'kv_table' },
  { key: 'metrics', displayName: 'Metrics', type: 'kv_table' },
  { key: 'error_message', displayName: 'Exception', type: 'error_message' },
];

export const ExperimentDetails = () => {
  const { experimentNotebookId = '', experimentId = '' } = useParams();
  const [notebookName, setNotebookName] = useState('');
  const [details, setDetails] = useState<ExperimentResponse>();
  const notebookClient = useApi(notebooksApiRef);
  const getDetails = useCallback(async () => {
    const res = await notebookClient.getExperimentDetails(experimentId);
    setDetails(res);
  }, [experimentId, notebookClient]);

  useEffect(() => {
    if (experimentId !== 'latest') {
      getDetails();
    }
  }, [experimentId, getDetails]);

  useEffect(() => {
    const getExperimentNotebook = async () => {
      const res = await notebookClient.getExperimentNotebookDetails(
        experimentNotebookId,
      );
      setNotebookName(res.name);
    };
    getExperimentNotebook();
  }, [experimentNotebookId, notebookClient]);

  const onDelete = async (id: string) => {
    return await notebookClient.deleteExperiment(id, experimentNotebookId);
  };

  const shouldPoll = createPoller(status, getDetails);
  const actionBarComponent = (showFullScreenButton: boolean) => {
    return (
      details && (
        <Grid item xs={12}>
          <NotebookActionBar
            s3Path={details.s3_path}
            notebook_path={details.notebook_path}
            id={details.id}
            name={details.name}
            parentId={details.experiment_notebook_id}
            status={details.status}
            createdBy={details.created_by}
            isInteractive={false}
            shouldPublish={false}
            archived={details.archived}
            showFullScreenButton={showFullScreenButton}
            onDelete={onDelete}
            onPollCallback={getDetails}
          />
        </Grid>
      )
    );
  };
  return experimentId === 'latest' ? (
    <NavigateToLatest parentEntityKey="experimentNotebook" />
  ) : (
    (details && (
      <BaseLayout notebookName={notebookName} executionName={details.name}>
        <Content>
          <Grid container>
            {actionBarComponent(false)}
            <Grid item xs={12}>
              <InfoCard
                title={
                  <>
                    Experiment Details{' '}
                    {shouldPoll ? <CircularProgress size="1rem" /> : null}
                  </>
                }
              >
                <CardContent>
                  <DetailsCard columns={entries} data={details} />
                </CardContent>
              </InfoCard>
            </Grid>
          </Grid>
        </Content>
      </BaseLayout>
    )) ||
      null
  );
};
