export function getTooltipTitle(
  executionViaZflowEnabled: boolean,
  gotoEnabledPipeline: boolean,
  gotoEnabledStep: boolean,
): string {
  if (!executionViaZflowEnabled) {
    return 'Upgrade to zflow version >= 1.0.9 to use this feature';
  } else if (!gotoEnabledPipeline) {
    return 'Set `should_enable_goto=True` to use this feature';
  } else if (!gotoEnabledStep) {
    return 'You cannot start the pipeline from this step.';
  }
  return '';
}
