import { SkipAction } from './SkipAction';
import { StepAction } from './StepAction';

import {
  STEP_ACTION_TYPES,
  STEP_STATUS,
  STEP_TYPE,
} from '../../../../constants';
import { ActionProps, StepActionType } from './types';
import { SecurityErrorAction } from './SecurityErrorAction';

/**
 * This function is used to determine if the traffic is approximately zero
 * for the current stack, because stackset controller can return non-zero but
 * close-to-zero values for stacks with no traffic.
 */
function isTrafficApproximatelyZero(currentPercentage: number): boolean {
  return currentPercentage < 0.1;
}

export const getStepActions = ({
  runService,
  skippingStepDisabled,
  currentPercentage,
  isAutomatedRollbackDisabled = false,
}: ActionProps) => ({
  [STEP_ACTION_TYPES.abort]: {
    component: StepAction,
    type: STEP_ACTION_TYPES.abort,
    request: runService.abortStep,
    actionProps: { title: 'abort step', color: 'warning' },
  },
  [STEP_ACTION_TYPES.rollback]: {
    component: StepAction,
    type: STEP_ACTION_TYPES.rollback,
    request: runService.abortStep,
    actionProps: { title: 'rollback', color: 'warning' },
  },
  [STEP_ACTION_TYPES.abortDeployment]: {
    component: StepAction,
    type: STEP_ACTION_TYPES.abortDeployment,
    request: runService.abortStep,
    actionProps: { title: 'abort deployment', color: 'warning' },
  },
  [STEP_ACTION_TYPES.approve]: {
    component: StepAction,
    type: STEP_ACTION_TYPES.approve,
    request: runService.approveStep,
    actionProps: { title: 'approve', color: 'success' },
  },
  [STEP_ACTION_TYPES.reject]: {
    component: StepAction,
    type: STEP_ACTION_TYPES.reject,
    request: runService.rejectStep,
    actionProps: { title: 'reject', color: 'warning' },
  },
  [STEP_ACTION_TYPES.skip]: {
    component: SkipAction,
    type: STEP_ACTION_TYPES.skip,
    request: runService.skipStep,
    actionProps: {
      title: 'skip',
      color: 'warning',
      skippingStepDisabled,
    },
  },
  [STEP_ACTION_TYPES.retry]: {
    component: StepAction,
    type: STEP_ACTION_TYPES.retry,
    request: runService.retriggerStep,
    actionProps: { title: 'retry from here', color: 'primary' },
  },
  [STEP_ACTION_TYPES.retriggerStepAndUnblockSecurityError]: {
    component: SecurityErrorAction,
    type: STEP_ACTION_TYPES.retriggerStepAndUnblockSecurityError,
    request: runService.retriggerStepAndUnblockSecurityError,
    actionProps: {
      title: 'acknowledge error and retry',
      color: 'primary',
    },
  },
  [STEP_ACTION_TYPES.pauseTraffic]: {
    component: StepAction,
    type: STEP_ACTION_TYPES.pauseTraffic,
    request: runService.pauseTraffic,
    actionProps: {
      title: 'pause',
      color: 'primary',
      disabled: false,
    },
  },
  [STEP_ACTION_TYPES.resumeTraffic]: {
    component: StepAction,
    type: STEP_ACTION_TYPES.resumeTraffic,
    request: () => runService.resumeTraffic(false),
    actionProps: {
      title: isTrafficApproximatelyZero(currentPercentage)
        ? 'restart'
        : 'resume',
      color: 'primary',
      disabled: false,
    },
  },
  [STEP_ACTION_TYPES.resumeTrafficNoAutomatedRollback]: {
    component: StepAction,
    type: STEP_ACTION_TYPES.resumeTrafficNoAutomatedRollback,
    request: () => runService.resumeTraffic(true),
    actionProps: {
      title: `${
        isTrafficApproximatelyZero(currentPercentage) ? 'restart' : 'resume'
      } and skip automated rollback`,
      color: 'primary',
      disabled: isAutomatedRollbackDisabled,
      disabledTooltip: 'Automated rollback already disabled',
    },
  },
  [STEP_ACTION_TYPES.rollbackTraffic]: {
    component: StepAction,
    type: STEP_ACTION_TYPES.rollbackTraffic,
    request: runService.rollbackTraffic,
    actionProps: {
      title: 'rollback and cancel',
      color: 'primary',
      disabled: false,
    },
  },
  [STEP_ACTION_TYPES.cancelTraffic]: {
    component: StepAction,
    type: STEP_ACTION_TYPES.cancelTraffic,
    request: runService.cancelTraffic,
    actionProps: {
      title: 'cancel',
      color: 'primary',
      disabled: false,
    },
  },
});

export function getActionsByStepType(
  actions: { [key: string]: StepActionType },
  type: string,
  isAutomatedTraffic: boolean,
  isTrafficPaused: boolean,
  currentPercentage: number,
  hasOnlySecurityDeploymentErrors: boolean,
): {
  [key: string]: Array<StepActionType>;
} {
  switch (type) {
    case STEP_TYPE.SCRIPT:
    case STEP_TYPE.SLEEP:
    case STEP_TYPE.OVERLAY:
      return {
        [STEP_STATUS.NOT_STARTED]: [actions.skip],
        [STEP_STATUS.INIT]: [actions.abort],
        [STEP_STATUS.IN_PROGRESS]: [actions.abort],
        [STEP_STATUS.PENDING_APPROVAL]: [actions.approve, actions.reject],
        [STEP_STATUS.SUCCEEDED]: [actions.retry],
        [STEP_STATUS.FAILED]: [actions.retry, actions.skip],
        [STEP_STATUS.ABORTED]: [actions.retry, actions.skip],
        [STEP_STATUS.ERROR]: [actions.retry, actions.skip],
        [STEP_STATUS.TIMEOUT]: [actions.retry, actions.skip],
        [STEP_STATUS.ABORTING]: [],
        [STEP_STATUS.SKIPPED]: [],
      };
    case STEP_TYPE.PROCESS:
    case STEP_TYPE.DEPLOY:
      return {
        [STEP_STATUS.NOT_STARTED]: [actions.skip],
        [STEP_STATUS.INIT]: [actions.abortDeployment],
        [STEP_STATUS.IN_PROGRESS]: [actions.abortDeployment],
        [STEP_STATUS.PENDING_APPROVAL]: [actions.approve, actions.reject],
        [STEP_STATUS.SUCCEEDED]: [actions.retry],
        [STEP_STATUS.FAILED]: hasOnlySecurityDeploymentErrors
          ? [actions.retriggerStepAndUnblockSecurityError]
          : [actions.retry, actions.skip],
        [STEP_STATUS.ABORTED]: [actions.retry, actions.skip],
        [STEP_STATUS.ERROR]: [actions.retry, actions.skip],
        [STEP_STATUS.TIMEOUT]: [actions.retry, actions.skip],
        [STEP_STATUS.ABORTING]: [],
        [STEP_STATUS.SKIPPED]: [],
      };
    case STEP_TYPE.TRAFFIC:
      if (isAutomatedTraffic) {
        return {
          [STEP_STATUS.NOT_STARTED]: [actions.skip],
          [STEP_STATUS.INIT]: [],
          [STEP_STATUS.IN_PROGRESS]: getAutomatedTrafficActions(
            actions,
            isTrafficPaused,
            currentPercentage,
          ),
          [STEP_STATUS.PENDING_APPROVAL]: [actions.approve, actions.reject],
          [STEP_STATUS.SUCCEEDED]: [actions.retry],
          [STEP_STATUS.FAILED]: [actions.retry, actions.skip],
          [STEP_STATUS.ABORTED]: [actions.retry, actions.skip],
          [STEP_STATUS.ERROR]: [actions.retry, actions.skip],
          [STEP_STATUS.TIMEOUT]: [actions.retry, actions.skip],
          [STEP_STATUS.ABORTING]: [],
          [STEP_STATUS.SKIPPED]: [],
        };
      }
      return {
        [STEP_STATUS.NOT_STARTED]: [actions.skip],
        [STEP_STATUS.INIT]: [],
        [STEP_STATUS.IN_PROGRESS]: [],
        [STEP_STATUS.PENDING_APPROVAL]: [actions.approve, actions.reject],
        [STEP_STATUS.SUCCEEDED]: [actions.retry],
        [STEP_STATUS.FAILED]: [actions.retry, actions.skip],
        [STEP_STATUS.ABORTED]: [actions.retry, actions.skip],
        [STEP_STATUS.ERROR]: [actions.retry, actions.skip],
        [STEP_STATUS.TIMEOUT]: [actions.retry, actions.skip],
        [STEP_STATUS.ABORTING]: [],
        [STEP_STATUS.SKIPPED]: [],
      };
    default:
      return {};
  }
}

function getAutomatedTrafficActions(
  actions: { [key: string]: StepActionType },
  isTrafficPaused: boolean,
  currentPercentage: number,
): Array<StepActionType> {
  const availableActions: Array<StepActionType> = [];

  if (isTrafficPaused) {
    availableActions.push(actions.resumeTraffic);
    availableActions.push(actions.resumeTrafficNoAutomatedRollback);
  } else {
    availableActions.push(actions.pauseTraffic);
  }

  if (isTrafficApproximatelyZero(currentPercentage)) {
    availableActions.push(actions.cancelTraffic);
  } else {
    availableActions.push(actions.rollbackTraffic);
  }

  return availableActions;
}
