import React from 'react';
import { CardContent } from '@material-ui/core';
import { Entity } from '@backstage/catalog-model';
import { AlertsSummary } from '../AlertsSummary';
import { InfoCard } from 'plugin-ui-components';
import { OPSGENIE_ANNOTATION } from '../../integration';
import { MissingAnnotationEmptyState } from '@backstage/core-components';

type AlertsCardProps = {
  entity: Entity;
  title?: string;
};

export const AlertsCard = ({ entity, title }: AlertsCardProps) => {
  const query = entity.metadata.annotations?.[OPSGENIE_ANNOTATION];

  if (!query) {
    return <MissingAnnotationEmptyState annotation={OPSGENIE_ANNOTATION} />;
  }
  return (
    <InfoCard title={title || 'Anomalies'}>
      <CardContent>
        <AlertsSummary entity={entity} query={query} />
      </CardContent>
    </InfoCard>
  );
};
