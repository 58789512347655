import React from 'react';
import { Box, LinearProgress, Link } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import UndoIcon from '@material-ui/icons/Undo';
import PauseIcon from '@material-ui/icons/PauseCircleFilled';
import PlayIcon from '@material-ui/icons/PlayCircleFilled';
import InfoIcon from '@material-ui/icons/Info';
import LinkIcon from '@material-ui/icons/Link';
import {
  InfoCard,
  Emoji,
  Text,
  fontSize,
  ListItem,
  List,
  Tooltip,
  TagSmall,
} from '../../../../common';
import {
  STEP_PRE_IN_PROGRESS_STATUSES,
  STEP_STATUS,
} from '../../../../constants';
import { useServices } from '../../../../services';
import { useStacksetDetails } from './useStacksetDetails';
import { useInterval } from 'react-use';
import { Progress } from '@backstage/core-components';
import { StacksetTrafficMap } from './StacksetTrafficMap';
import { colors } from 'plugin-ui-components';

const AUTOMATED_ROLLBACK_INFO = `If rollback is automated, the traffic switch engine can decide to rollback based on alerts you configured.
  When this is disabled, the engine ignores active alerts and continues switching traffic.`;

const PAUSE_INFO = `Traffic switching can be paused, either by you or the engine.
  When paused, the engine doesn't change traffic until resumed by the user.`;

const LOGS_INFO =
  'You can access logs from the traffic switch engine in Scalyr for 30 days to better understand the sequence of actions.';

export function TrafficProgress() {
  const {
    runService: { step },
    trafficService,
  } = useServices();

  const stackName = `${step.stackset}-${step.stack_version}`;
  const shouldPollTraffic =
    trafficService.getCurrentPercentage(step.run.id) !== 100 ||
    step.status === STEP_STATUS.IN_PROGRESS;

  const [{ error, loading }, { retry }] = useStacksetDetails(
    step.run.id,
    stackName,
    trafficService.set,
  );

  useInterval(retry, shouldPollTraffic ? 5000 : null);

  if (STEP_PRE_IN_PROGRESS_STATUSES.includes(step.status)) {
    return (
      <InfoCard title={<Title live={false} />}>
        <Text display="block" marginBottom={1}>
          {step.status === STEP_STATUS.INIT
            ? 'Setting up traffic switch engine ...'
            : "Traffic increase didn't start yet ..."}
        </Text>
      </InfoCard>
    );
  }

  return (
    <InfoCard title={<Title live={shouldPollTraffic} />}>
      {loading && !trafficService.getLoaded(step.run.id) && (
        <Box marginBottom={2}>
          <Text display="block" marginBottom={1}>
            Fetching current traffic percentage ...
          </Text>
          <Progress />
        </Box>
      )}

      {!!error && !loading && (
        <Box marginBottom={2}>
          <Alert severity="error">
            {`An error occurred while fetching stackset traffic status: ${error.message}.`}
          </Alert>
        </Box>
      )}

      {!error && trafficService.getLoaded(step.run.id) && (
        <Box display="flex" alignItems="center" marginBottom={3}>
          <TrafficProgressbar
            current={trafficService.getCurrentPercentage(step.run.id)}
            target={step.target_percentage || 100}
          />
        </Box>
      )}

      {!error && trafficService.getVersionNotFound(step.run.id) && (
        <Box marginBottom={2}>
          <Alert severity="warning">
            {`Stack ${`${step.stackset}-${step.stack_version}`} was not found. Retrying this step may not work, please make sure the stack exists first.`}
          </Alert>
        </Box>
      )}

      <Box>
        <List>
          <ListItem icon={<UndoIcon />}>
            Automated rollback:&nbsp;
            <strong>
              {step.is_automated_rollback_disabled ? 'disabled' : 'enabled'}
            </strong>
            &nbsp;
            <Tooltip title={AUTOMATED_ROLLBACK_INFO}>
              <span>
                <InfoIcon fontSize="inherit" />
              </span>
            </Tooltip>
          </ListItem>

          <ListItem icon={step.traffic_paused ? <PauseIcon /> : <PlayIcon />}>
            Traffic increase:&nbsp;
            <strong>{step.traffic_paused ? 'paused' : 'enabled'}</strong>&nbsp;
            <Tooltip title={PAUSE_INFO}>
              <span>
                <InfoIcon fontSize="inherit" />
              </span>
            </Tooltip>
          </ListItem>

          {!!step.run.id && (
            <ListItem icon={<LinkIcon />}>
              Engine logs:&nbsp;
              <Link
                target="_blank"
                rel="noopener noreferrer"
                href={`https://app.eu.scalyr.com/events?filter=%24cluster_alias%3D%3D%22${step.target_cluster}%22+%24namespace+contains+%22${step.run.id}%22`}
              >
                scalyr
              </Link>
              &nbsp;
              <Tooltip title={LOGS_INFO}>
                <span>
                  <InfoIcon fontSize="inherit" />
                </span>
              </Tooltip>
            </ListItem>
          )}
        </List>
      </Box>

      {Object.keys(trafficService.stackset.traffic).length !== 0 &&
        trafficService.getLoaded(step.run.id) && (
          <Box marginTop={3}>
            <StacksetTrafficMap
              currentVersion={step.stack_version}
              stackset={trafficService.stackset}
            />
          </Box>
        )}
    </InfoCard>
  );
}

function Title({ live }: { live: boolean }) {
  return (
    <span>
      <Emoji name="info" emoji="🚦" />
      &nbsp;Traffic switch status&nbsp;&nbsp;
      {live && (
        <Tooltip title="Status refreshed every 5 seconds.">
          <TagSmall color={colors.semantic.errorLight}>live</TagSmall>
        </Tooltip>
      )}
    </span>
  );
}

function TrafficProgressbar({
  current,
  target,
}: {
  current: number;
  target: number;
}) {
  return (
    <>
      <Box marginRight={1}>
        <Text bold ellipsis color="text.secondary" fontSize={fontSize.tiny}>
          {`${current} / ${target || 100}`}
        </Text>
      </Box>

      <Box width="100%" mr={1}>
        <LinearProgress
          variant="determinate"
          value={current === 0 ? 0 : (current * 100) / target || 100}
        />
      </Box>
    </>
  );
}
