import { Entity } from '@backstage/catalog-model';
import { CDP_ANNOTATION } from '../../constants';
import { parseRepositoryURL } from '../../utils/parseRepositoryURL';

type Repository = {
  source: string;
  url: string | null;
};

export type WidgetPipeline = {
  id: string;
  accountable_user: string;
  commit_message: string | null;
  pr_title: string | null;
  status: string;
  target_repo: string;
};

export function getRepsitoriesFromEntities(entities: Entity[]): string[] {
  const repos = entities.reduce((allEntitiesRepos: string[], entity) => {
    const annotation = entity.metadata.annotations?.[CDP_ANNOTATION] ?? '';
    let repositories: Repository[] = [];

    try {
      repositories = JSON.parse(annotation);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(
        `Could not parse CDP annotation of this entity. (${entity.metadata.name})`,
      );

      return allEntitiesRepos;
    }

    const singleEntityRepos = repositories.reduce(
      (acc: string[], repo: Repository) => {
        if (!repo.url) return acc;

        const data = parseRepositoryURL(repo.url);
        if (!data) return acc;

        return [...acc, `${data.domain}/${data.org}/${data.name}`];
      },
      [],
    );

    return [...allEntitiesRepos, ...singleEntityRepos];
  }, []);

  return repos;
}

export function getPipelinesQuery(repositories: string[]) {
  const filtering = repositories.map(repository => {
    return `{k: "target_repo", v: "${repository}"}`;
  });

  return `{
    pipeline_groups (filtering: [${filtering.join(',')}], limit: 3, offset: 0) {
      pipelines {
        id
        accountable_user
        commit_message
        pr_title
        status
        target_repo
      }
    }
  }`;
}

export function getPipelinesFromGroups(
  groups: { pipelines: [WidgetPipeline] }[],
): WidgetPipeline[] {
  return groups.map(group => group.pipelines[0]);
}
