import React from 'react';
import { PIPELINE_STATUS, STEP_STATUS } from '../../../constants';
import { SvgIconProps } from '@material-ui/core';
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
import FlagIcon from '@material-ui/icons/Flag';
import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';
import SkipNextIcon from '@material-ui/icons/SkipNext';
import PanToolIcon from '@material-ui/icons/PanTool';
import PauseIcon from '@material-ui/icons/Pause';
import ThumbDownAltIcon from '@material-ui/icons/ThumbDownAlt';
import TimerOffIcon from '@material-ui/icons/TimerOff';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { ProgressSpinIcon } from '../../common';

type Props = {
  status: string;
  size?: SvgIconProps['fontSize'];
  color?: Color;
};

type Color =
  | 'inherit'
  | 'primary'
  | 'secondary'
  | 'action'
  | 'disabled'
  | 'error';

export function StatusIcons({
  status,
  size = 'medium',
  color = 'inherit',
}: Props) {
  switch (status) {
    case PIPELINE_STATUS.NOT_STARTED:
      return <HourglassEmptyIcon fontSize={size} color={color} />;
    case STEP_STATUS.SKIPPED:
      return <SkipNextIcon fontSize={size} color={color} />;
    case PIPELINE_STATUS.SUCCEEDED:
      return <CheckIcon fontSize={size} color={color} />;
    case STEP_STATUS.PENDING_APPROVAL:
      return <PanToolIcon fontSize={size} color={color} />;
    case PIPELINE_STATUS.FAILED:
    case PIPELINE_STATUS.ERROR:
      return <CloseIcon fontSize={size} color={color} />;
    case STEP_STATUS.INIT:
      return <FlagIcon fontSize={size} color={color} />;
    case PIPELINE_STATUS.IN_PROGRESS:
      return <ProgressSpinIcon fontSize={size} color={color} />;
    case PIPELINE_STATUS.PENDING:
      return <PauseIcon fontSize={size} color={color} />;
    case PIPELINE_STATUS.ABORTED:
    case STEP_STATUS.ABORTING:
      return <ThumbDownAltIcon fontSize={size} color={color} />;
    case STEP_STATUS.TIMEOUT:
      return <TimerOffIcon fontSize={size} color={color} />;
    default:
      return <HelpOutlineIcon fontSize={size} color={color} />;
  }
}
