import React from 'react';
import {
  CardContent,
  Grid,
  List,
  ListItem,
  ListItemIcon,
} from '@material-ui/core';
import { MLModel } from '../../api/definitions';
import { AboutField, InfoCard } from 'plugin-ui-components';
import ListItemText from '@material-ui/core/ListItemText';
import ListIcon from '@material-ui/icons/List';
import { ModelEthicalConsiderations } from './ModelEthicalConsiderations';
import { MissingFieldPlaceholder } from '../common/MissingFieldPlaceholder';

const renderList = (entry: string) => {
  return (
    <ListItem key={entry}>
      <ListItemIcon>
        <ListIcon />
      </ListItemIcon>
      <ListItemText primary={entry} />
    </ListItem>
  );
};

export const ModelUseCasesAndEthicalConsiderations = ({
  model,
}: {
  model: MLModel;
}) => {
  return (
    <>
      <InfoCard title="Users">
        <CardContent>
          <Grid container>
            <AboutField
              label=" "
              children={
                <List>
                  {model.considerations?.users ? (
                    model.considerations?.users?.map(user => {
                      return renderList(user);
                    })
                  ) : (
                    <MissingFieldPlaceholder
                      field="user data"
                      type="model-card"
                    />
                  )}
                </List>
              }
              xs={12}
              sm={12}
              lg={12}
            />
          </Grid>
        </CardContent>
      </InfoCard>

      <InfoCard title="Use Cases">
        <CardContent>
          <Grid container>
            <AboutField
              label=" "
              children={
                <List>
                  {model.considerations?.use_cases ? (
                    model.considerations?.use_cases?.map(use_case => {
                      return renderList(use_case);
                    })
                  ) : (
                    <MissingFieldPlaceholder
                      field="use case"
                      type="model-card"
                    />
                  )}
                </List>
              }
              xs={12}
              sm={12}
              lg={12}
            />
          </Grid>
        </CardContent>
      </InfoCard>

      <InfoCard title="Limitations">
        <CardContent>
          <Grid container>
            <AboutField
              label=" "
              children={
                <List>
                  {model.considerations?.limitations ? (
                    model.considerations?.limitations?.map(limitation => {
                      return renderList(limitation);
                    })
                  ) : (
                    <MissingFieldPlaceholder
                      field="model limitation"
                      type="model-card"
                    />
                  )}
                </List>
              }
              xs={12}
              sm={12}
              lg={12}
            />
          </Grid>
        </CardContent>
      </InfoCard>

      <InfoCard title="Tradeoffs">
        <CardContent>
          <Grid container>
            <AboutField
              label=" "
              children={
                <List>
                  {model.considerations?.tradeoffs ? (
                    model.considerations?.tradeoffs?.map(tradeoff => {
                      return renderList(tradeoff);
                    })
                  ) : (
                    <MissingFieldPlaceholder
                      field="model tradeoff"
                      type="model-card"
                    />
                  )}
                </List>
              }
              xs={12}
              sm={12}
              lg={12}
            />
          </Grid>
        </CardContent>
      </InfoCard>

      <InfoCard title="Ethical Considerations">
        <ModelEthicalConsiderations
          ethical_considerations={model.considerations?.ethical_considerations}
        />
      </InfoCard>
    </>
  );
};
