import React, { useEffect, useState, useCallback } from 'react';
import { useParams } from 'react-router';
import {
  BaseLayout,
  ListEntities,
  KVTable,
  StatusTableCell,
  ActionsTableCell,
  CopyActionsBar,
  StatusFilter,
} from '../../components';
import { useApi } from '@backstage/core-plugin-api';
import { RunStatus, notebooksApiRef } from '../../api';
import { Graph } from '../../components/Graph';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
} from '@material-ui/core';
import ExpandMore from '@material-ui/icons/ExpandMore';
import TableChartOutlined from '@material-ui/icons/TableChartOutlined';
import InsertChartOutlined from '@material-ui/icons/InsertChartOutlinedOutlined';
import { constructLinkToDataLabNotebook } from '../../utils';
import { Content, SubvalueCell } from '@backstage/core-components';
import {
  SourceNotebookLinkTag,
  TagGroup,
  UserTag,
} from '../../components/Tags';

export const Experiments = () => {
  const { experimentNotebookId = '' } = useParams();
  const notebookClient = useApi(notebooksApiRef);
  const [refreshList, setRefreshList] = useState(false);
  const [page, setPage] = useState(0);
  const [activeAccordian, setActiveAccordian] = useState('table');
  const [plotData, setPlotData] = useState<any[]>([]);
  const [statusFilter, setStatusFilter] = useState<RunStatus>();
  const [archiveExecutionError, setArchiveExecutionError] = useState<Error>();
  const [notebookName, setNotebookName] = useState('');

  useEffect(() => {
    const getExperimentNotebook = async () => {
      const res = await notebookClient.getExperimentNotebookDetails(
        experimentNotebookId,
      );
      setNotebookName(res.name);
    };
    getExperimentNotebook();
  }, [experimentNotebookId, notebookClient]);

  const onDelete = async (event: any, id: string) => {
    event?.stopPropagation();
    try {
      await notebookClient.deleteExperiment(id, experimentNotebookId);
    } catch (err) {
      setArchiveExecutionError(err as Error);
    }
    setRefreshList(!refreshList);
  };

  const COLUMNS = [
    {
      title: 'Experiment',
      field: 'name',
      render: (row: any): React.ReactNode => (
        <SubvalueCell
          value={<b>{row.name}</b>}
          subvalue={
            <TagGroup>
              <UserTag user={row.created_by} />
              <SourceNotebookLinkTag
                link={constructLinkToDataLabNotebook(row.notebook_path)}
              />
            </TagGroup>
          }
        />
      ),
    },
    {
      title: 'Status',
      field: 'row.status',
      filtering: true,
      filterComponent: () => (
        <StatusFilter value={statusFilter} onChange={setStatusFilter} />
      ),
      render: (row: any) => <StatusTableCell status={row?.status} />,
    },
    {
      title: 'Created At',
      field: 'created_at',
    },
    {
      title: 'Metrics',
      render: (row: any) => <KVTable data={row.metrics} />,
    },
    {
      title: 'Params',
      render: (row: any) => <KVTable data={row.params} />,
    },
    {
      title: 'Actions',
      render: (row: any) => (
        <ActionsTableCell
          parentEntityType="experiment_notebooks"
          id={row.experiment_notebook_id}
          name={row.name}
          is_interactive={row.is_interactive}
          archived={row.archived}
          childEntityType="experiments"
          childId={row.id}
          onDelete={onDelete}
        />
      ),
    },
  ];

  const getPlotData = useCallback(async () => {
    const res = await notebookClient.getMetricsAndParams(experimentNotebookId);
    const metrics = res.metrics.map((x: any) => ({
      ...x,
      type: 'metrics',
      parentId: x.experiment_name,
    }));
    const params = res.params.map((x: any) => ({
      ...x,
      type: 'params',
      parentId: x.experiment_name,
    }));
    setPlotData(metrics.concat(params));
  }, [experimentNotebookId, notebookClient]);

  useEffect(() => {
    getPlotData();
  }, [experimentNotebookId, getPlotData]);

  const accordianComponents = [
    {
      id: 'graph',
      icon: <InsertChartOutlined />,
      title: 'Graph view',
      component: <Graph data={plotData} />,
    },
    {
      id: 'table',
      title: 'Table view',
      icon: <TableChartOutlined />,
      component: (
        <ListEntities
          getEntitiesPromise={notebookClient.getExperiments(
            experimentNotebookId,
            page,
            statusFilter,
          )}
          entityKey="experiments"
          columns={COLUMNS}
          childEntityKey=""
          title="Experiments"
          refreshList={refreshList}
          page={page}
          onPageChange={setPage}
          error={archiveExecutionError}
          setError={setArchiveExecutionError}
        />
      ),
    },
  ];

  return (
    <BaseLayout notebookName={notebookName}>
      <Content>
        <CopyActionsBar type="experiments" id={experimentNotebookId} />
        {accordianComponents.map(c => (
          <Accordion
            key={c.id}
            expanded={c.id === activeAccordian}
            onChange={() => setActiveAccordian(c.id)}
          >
            <AccordionSummary expandIcon={<ExpandMore />}>
              <Box paddingRight="10px">{c.icon}</Box>
              <Box paddingTop="2px">{c.title}</Box>
            </AccordionSummary>
            <AccordionDetails style={{ display: 'block' }}>
              {c.component}
            </AccordionDetails>
          </Accordion>
        ))}
      </Content>
    </BaseLayout>
  );
};
