import { OAuth2, UrlPatternDiscovery } from '@backstage/core-app-api';
import {
  AnyApiFactory,
  configApiRef,
  createApiFactory,
  discoveryApiRef,
  identityApiRef,
  oauthRequestApiRef,
  storageApiRef,
} from '@backstage/core-plugin-api';
import { catalogApiRef } from '@backstage/plugin-catalog-react';
import {
  techdocsApiRef,
  techdocsStorageApiRef,
} from '@backstage/plugin-techdocs-react';
import { ZalandoDocsApi } from './techDocsApis';
import { ZalandoDocsStorageApi } from './ZalandoDocsStorageApi';
import {
  oauth2ApiRef,
  PersonalizationApi,
  personalizationApiRef,
  TeamOwnedEntitiesApi,
  teamOwnedEntitiesApiRef,
  ZalandoIdentityApi,
  ZalandoIdentityApiRef,
} from 'plugin-core';
import {
  ScmIntegrationsApi,
  scmIntegrationsApiRef,
} from '@backstage/integration-react';
import { shortcutsApiRef } from '@backstage-community/plugin-shortcuts';
import { ShortcutsTrackingWrapper } from './tracking/ShortcutsTrackingWrapper';
import { searchApiRef } from '@backstage/plugin-search-react';
import { SearchClient } from './utils/SearchApi';
import { CostInsightsClient } from './utils/CostInsightsClient';
import { costInsightsApiRef } from 'plugin-cloud-cost-insights';
import { TechInsightsApiClient, techInsightsApiRef } from 'plugin-scorecards';

export const apis: Iterable<AnyApiFactory> = [
  createApiFactory({
    api: oauth2ApiRef,
    deps: {
      discoveryApi: discoveryApiRef,
      oauthRequestApi: oauthRequestApiRef,
    },
    factory: ({ discoveryApi, oauthRequestApi }) => {
      return OAuth2.create({ discoveryApi, oauthRequestApi });
    },
  }),
  createApiFactory({
    api: scmIntegrationsApiRef,
    deps: { configApi: configApiRef },
    factory: ({ configApi }) => ScmIntegrationsApi.fromConfig(configApi),
  }),
  createApiFactory({
    api: discoveryApiRef,
    deps: { configApi: configApiRef },
    factory: ({ configApi }) =>
      UrlPatternDiscovery.compile(
        `${configApi.getString('backend.baseUrl')}/api/{{ pluginId }}`,
      ),
  }),
  createApiFactory({
    api: techdocsStorageApiRef,
    deps: {
      configApi: configApiRef,
      oauth2Api: oauth2ApiRef,
      discoveryApi: discoveryApiRef,
      identityApi: identityApiRef,
    },
    factory: ({ configApi, oauth2Api, discoveryApi, identityApi }) =>
      new ZalandoDocsStorageApi({
        configApi: configApi,
        discoveryApi: discoveryApi,
        oauth2Api: oauth2Api,
        identityApi: identityApi,
      }),
  }),
  createApiFactory({
    api: techdocsApiRef,
    deps: {
      configApi: configApiRef,
      oauth2Api: oauth2ApiRef,
      discoveryApi: discoveryApiRef,
      identityApi: identityApiRef,
    },
    factory: ({ configApi, oauth2Api, discoveryApi, identityApi }) =>
      new ZalandoDocsApi({
        configApi: configApi,
        discoveryApi: discoveryApi,
        oauth2Api: oauth2Api,
        identityApi: identityApi,
      }),
  }),
  createApiFactory({
    api: ZalandoIdentityApiRef,
    deps: { identityApi: identityApiRef },
    factory: ({ identityApi }) => new ZalandoIdentityApi(identityApi),
  }),
  createApiFactory({
    api: teamOwnedEntitiesApiRef,
    deps: { catalogApi: catalogApiRef },
    factory: ({ catalogApi }) => new TeamOwnedEntitiesApi(catalogApi),
  }),
  createApiFactory({
    api: shortcutsApiRef,
    deps: { storageApi: storageApiRef },
    factory: ({ storageApi }) => new ShortcutsTrackingWrapper(storageApi),
  }),
  createApiFactory({
    api: searchApiRef,
    deps: {
      discoveryApi: discoveryApiRef,
      oauth2Api: oauth2ApiRef,
    },
    factory: ({ discoveryApi, oauth2Api }) => {
      return new SearchClient(discoveryApi, oauth2Api);
    },
  }),
  createApiFactory({
    api: personalizationApiRef,
    deps: {
      oauth2Api: oauth2ApiRef,
      discoveryApi: discoveryApiRef,
      identityApi: ZalandoIdentityApiRef,
    },
    factory: options => new PersonalizationApi(options),
  }),
  createApiFactory({
    api: costInsightsApiRef,
    deps: {
      oauth2Api: oauth2ApiRef,
      personalizationApi: personalizationApiRef,
      catalogApi: catalogApiRef,
      discoveryApi: discoveryApiRef,
    },
    factory: ({ oauth2Api, personalizationApi, catalogApi, discoveryApi }) =>
      new CostInsightsClient(
        oauth2Api,
        personalizationApi,
        catalogApi,
        discoveryApi,
      ),
  }),
  createApiFactory({
    api: techInsightsApiRef,
    deps: {
      oauth2Api: oauth2ApiRef,
      configApi: configApiRef,
      discoveryApi: discoveryApiRef,
    },
    factory: ({ configApi, oauth2Api, discoveryApi }) =>
      new TechInsightsApiClient({
        configApi,
        oauth2Api,
        discoveryApi,
      }),
  }),
];
