import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import { v4 as uuidv4 } from 'uuid';
import { Button, ThemeProvider, Tooltip, Typography } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import { useApi } from '@backstage/core-plugin-api';
import { zflowApiRef } from '../../../api/zflowApiClient';
import { RunStatus } from '../../../api/definitions';
import { getTooltipTitle } from '../../../utils/version';
import { ButtonTheme } from '../../common/customTheme';
import { RunNameInput } from '../../Forms/InputComponents/RunNameInput';
import { RunInput } from '../../Forms/InputComponents/RunInput';
import { ManageRunButton } from './ManageRunButton';

interface StartRunProps {
  projectId: string;
  pipelineId: string;
  previousRunInput: { [key: string]: any } | null;
  executionViaZflowEnabled: boolean;
  setStopError?: React.Dispatch<React.SetStateAction<Array<string>>>;
  setShowSuccess?: React.Dispatch<React.SetStateAction<boolean>>;
}

export const StartRunButton = (props: StartRunProps) => {
  const zflowApi = useApi(zflowApiRef);
  const navigate = useNavigate();
  const [disabled, setDisabled] = useState<boolean>(false);
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [newRunName, setNewRunName] = useState<string | null>(null);
  const [responseError, setResponseError] = useState<Array<string>>([]);
  const randomRunID = uuidv4();
  const tooltipTitle = getTooltipTitle(props.executionViaZflowEnabled);
  const [runInput, setRunInput] = useState<string | null>(
    props.previousRunInput ? JSON.stringify(props.previousRunInput) : null,
  );
  const hasErrors = responseError.length > 0;

  function handleCloseDialog() {
    setOpenDialog(false);
    setDisabled(false);
    setResponseError([]);
  }
  function handleStartRun() {
    setResponseError([]);
    zflowApi
      .startRun(
        props.projectId,
        props.pipelineId,
        newRunName ? newRunName : randomRunID,
        RunStatus.Initialized,
        runInput ? runInput : null,
      )
      .then(result => {
        if (Array.isArray(result)) {
          setResponseError(result);
        } else {
          setOpenDialog(false);
          navigate(
            `/ml/projects/${props.projectId}/pipelines/${props.pipelineId}/runs/${result.run_id}`,
          );
        }
      });
  }

  function handleOpenStartDialog() {
    if (props.setStopError) {
      props.setStopError([]);
    }
    if (props.setShowSuccess) {
      props.setShowSuccess(false);
    }
    setOpenDialog(true);
  }

  return (
    <>
      <Dialog
        data-testid="start-run-dialog"
        fullWidth
        maxWidth="lg"
        open={openDialog}
      >
        <DialogTitle>Start New Run</DialogTitle>
        {hasErrors && (
          <Alert
            severity="error"
            onClose={() => {
              setResponseError([]);
            }}
          >
            <AlertTitle>Unable to start a new run</AlertTitle>
            {responseError && (
              <ul>
                {responseError.map(violation => {
                  return <li key={uuidv4()}>{violation}</li>;
                })}
              </ul>
            )}
          </Alert>
        )}
        <DialogContent dividers>
          <Typography>
            Start a new run using the latest definition of your pipeline
          </Typography>
          <RunNameInput
            setNewRunName={setNewRunName}
            disableStart={setDisabled}
            randomRunID={randomRunID}
          />
          <RunInput
            setRunInput={setRunInput}
            setDisabled={setDisabled}
            defaultInput={runInput}
          />
        </DialogContent>
        <DialogActions>
          <ThemeProvider theme={ButtonTheme}>
            <Button
              data-testid="start-run-cancel"
              onClick={handleCloseDialog}
              color="inherit"
            >
              Cancel
            </Button>
            <Button
              data-testid="start-run-confirm"
              onClick={handleStartRun}
              color="primary"
              disabled={disabled}
            >
              Start
            </Button>
          </ThemeProvider>
        </DialogActions>
      </Dialog>
      <ThemeProvider theme={ButtonTheme}>
        <Tooltip title={tooltipTitle}>
          <span>
            <ManageRunButton
              data-testid="start-run-button"
              variant="contained"
              color="primary"
              onClick={handleOpenStartDialog}
              startIcon={<PlayArrowIcon />}
              disabled={!props.executionViaZflowEnabled}
            >
              New Run
            </ManageRunButton>
          </span>
        </Tooltip>
      </ThemeProvider>
    </>
  );
};
