import { createApiRef } from '@backstage/core-plugin-api';
import { RunStatus } from './types/payloads';
import {
  ExperimentsResponse,
  ExperimentNotebooksResponse,
  SharedNotebooksResponse,
  ScheduledNotebooksResponse,
  VersionsResponse,
  RunsResponse,
  RunResponse,
  VersionResponse,
  ExperimentResponse,
  ScheduledNotebookResponse,
  SharedNotebookResponse,
  NotebookSearchResponse,
  TriggerRunResponse,
  MetricsAndParamsResponse,
  ExperimentNotebookResponse,
} from './types/responses';

export interface NotebooksApi {
  getSharedNotebooks(
    projectId: string,
    pageNumber: number,
    status?: RunStatus,
  ): Promise<SharedNotebooksResponse>;
  getScheduledNotebooks(
    projectId: string,
    pageNumber: number,
    status?: RunStatus,
  ): Promise<ScheduledNotebooksResponse>;
  getExperimentNotebooks(
    projectId: string,
    pageNumber: number,
    status?: RunStatus,
  ): Promise<ExperimentNotebooksResponse>;
  getVersions(
    sharedNotebookId: string,
    page: number,
    status?: RunStatus,
  ): Promise<VersionsResponse>;
  getRuns(
    scheduledNotebookId: string,
    page: number,
    status?: RunStatus,
  ): Promise<RunsResponse>;
  getExperiments(
    experimentNotebookId: string,
    page: number,
    status?: RunStatus,
  ): Promise<ExperimentsResponse>;
  getScheduledNotebookDetails(
    scheduledNotebookId: string,
  ): Promise<ScheduledNotebookResponse>;
  getSharedNotebookDetails(
    sharedNotebookId: string,
  ): Promise<SharedNotebookResponse>;
  getExperimentNotebookDetails(
    experimentNotebookId: string,
  ): Promise<ExperimentNotebookResponse>;
  getRunDetails(runId: string): Promise<RunResponse>;
  getVersionDetails(versionId: string): Promise<VersionResponse>;
  getExperimentDetails(experimentId: string): Promise<ExperimentResponse>;
  updateScheduledNotebook(
    id: string,
    disabled: boolean,
  ): Promise<ScheduledNotebookResponse>;
  deleteRun(id: string, scheduledNotebookId: string): Promise<RunResponse>;
  deleteVersion(id: string, sharedNotebookId: string): Promise<VersionResponse>;
  deleteExperiment(
    id: string,
    experimentNotebookId: string,
  ): Promise<ExperimentResponse>;
  deleteExperimentNotebook(
    experimentNotebookId: string,
  ): Promise<ExperimentNotebookResponse>;
  deleteSharedNotebook(
    sharedNotebookId: string,
  ): Promise<SharedNotebookResponse>;
  deleteScheduledNotebook(
    scheduledNotebookId: string,
  ): Promise<ScheduledNotebookResponse>;
  runScheduledNotebook(
    scheduledNotebookId: string,
  ): Promise<TriggerRunResponse>;
  getMetricsAndParams(
    experimentNotebookId: string,
  ): Promise<MetricsAndParamsResponse>;
  search(query: string, PAGE_SIZE: number): Promise<NotebookSearchResponse>;
}

export const notebooksApiRef = createApiRef<NotebooksApi>({
  id: 'notebook-api',
});
