import { Theme, CardContent, makeStyles, styled } from '@material-ui/core';
import { ToggleButtonClassKey, ToggleButtonGroup } from '@material-ui/lab';
import { CSSProperties } from 'react';

const colors: Record<
  IAppReview.DataClassification,
  IAppReview.DataClassification
> = {
  RED: 'RED',
  ORANGE: 'ORANGE',
  YELLOW: 'YELLOW',
  GREEN: 'GREEN',
};

export const ToggleGroup = styled(ToggleButtonGroup)(
  ({ theme }: { theme: Theme }) => ({
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: '1rem',
    marginBlock: '2rem',

    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr',
    },

    '& > button': {
      height: 'auto',
      border: `1px solid ${theme.palette.grey.A100} !important`,
      borderRadius: '8px !important',
      boxShadow: theme.shadows[1],

      '&.Mui-selected': {
        [`&[value=${colors.RED}]`]: {
          backgroundColor: theme.palette.error.main,

          '&:hover': {
            backgroundColor: theme.palette.error.light,
          },
        },
        [`&[value=${colors.ORANGE}]`]: {
          backgroundColor: theme.palette.warning.main,

          '&:hover': {
            backgroundColor: theme.palette.warning.light,
          },
        },
        [`&[value=${colors.YELLOW}]`]: {
          backgroundColor: theme.palette.gold,
          color: theme.palette.common.black,

          '&:hover': {
            backgroundColor: '#ffe570',
          },
        },
        [`&[value=${colors.GREEN}]`]: {
          backgroundColor: theme.palette.success.main,

          '&:hover': {
            backgroundColor: theme.palette.success.light,
          },
        },
      },

      '& > span': {
        flexDirection: 'column',
      },
    },
  }),
);

export const ToggleRadio = styled('input')(() => ({
  pointerEvents: 'none',
  float: 'left',
  marginLeft: '-1.5rem',
  position: 'relative',
  top: 8,
}));

const toggleButtonClasses = (): Partial<
  Record<ToggleButtonClassKey, CSSProperties>
> => ({
  root: {
    textTransform: 'none',
  },
});

export const FlexCardContent = styled(CardContent)(
  ({ theme }: { theme: Theme }) => ({
    paddingBottom: theme.spacing(),
    display: 'flex',
    alignItems: 'baseline',
    justifyContent: 'end',
  }),
);

export const useToggleButtonClasses = makeStyles(toggleButtonClasses as any);
