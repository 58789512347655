import LinearProgress from '@mui/material/LinearProgress';
import Grid from '@mui/material/Grid';
import {
  applicationColumns,
  ApplicationResultsFilter,
  CatalogTable,
  FilterGroupsContext,
  useProvideEntityFilters,
} from 'plugin-ui-components';
import React from 'react';
import { useQuery } from 'react-query';
import { catalogApiRef } from '@backstage/plugin-catalog-react';
import { useApi } from '@backstage/core-plugin-api';
import Alert from '@mui/material/Alert';
import chunk from 'lodash/chunk';

export const ScorecardTargets = ({
  scorecard,
}: {
  scorecard: IEntityScorecard;
}) => {
  const catalogApi = useApi(catalogApiRef);

  // Fetch target applications
  const {
    data: targetApplications,
    isLoading: isLoadingTargets,
    error: errorTargets,
  } = useQuery(`scorecard-targets-${scorecard.metadata.name}`, async () => {
    const targets = scorecard.relations?.map(rel => rel.target.name) || [];
    /* Split the targets into chunks to avoid failing requests with long query params.*/
    const targetChunks = chunk(targets, 100);

    // Fetch the entities for each chunk
    const fetchChunk = async (part: string[]) => {
      const { items } = await catalogApi.getEntities({
        filter: part.map(target => ({
          kind: 'Component',
          'metadata.name': target,
        })),
      });
      return items;
    };

    // Execute all chunked requests
    const allResults = await Promise.all(targetChunks.map(fetchChunk));

    // Flatten the results into a single array
    return allResults.flat() as IEntityApp[];
  });

  const state = useProvideEntityFilters(
    'service',
    targetApplications,
    isLoadingTargets,
  );
  return (
    <FilterGroupsContext.Provider value={state}>
      <Grid container>
        <Grid item xs={3}>
          <ApplicationResultsFilter />
        </Grid>
        {/* Target applications */}
        <Grid item xs={9} marginTop="1.5rem">
          {targetApplications && (
            <CatalogTable
              entities={state.matchingEntities}
              loading={isLoadingTargets}
              error={errorTargets}
              titlePreamble="Applies to"
              columns={applicationColumns('asc', ['prr', 'star'], {
                scorecardsList: [scorecard],
              })}
            />
          )}
          {isLoadingTargets && <LinearProgress />}
          {errorTargets && (
            <Alert severity="error">
              An error occured while loading target entities:{' '}
              {(errorTargets as Error).message}
            </Alert>
          )}
        </Grid>
      </Grid>
    </FilterGroupsContext.Provider>
  );
};
