import React, { useState } from 'react';
import { Button, ThemeProvider, Tooltip, Typography } from '@material-ui/core';
import StopIcon from '@material-ui/icons/Stop';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import { useApi } from '@backstage/core-plugin-api';
import { zflowApiRef } from '../../../api/zflowApiClient';
import { getTooltipTitle } from '../../../utils/version';
import { ButtonTheme } from '../../common/customTheme';
import { ManageRunButton } from './ManageRunButton';

interface RunProps {
  runId: string;
  executionViaZflowEnabled: boolean;
  setStopError: React.Dispatch<React.SetStateAction<Array<string>>>;
  setShowSuccess: React.Dispatch<React.SetStateAction<boolean>>;
}

export const AbortRunButton = (props: RunProps) => {
  const zflowApi = useApi(zflowApiRef);

  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [disabled, setDisabled] = useState<boolean>(
    !props.executionViaZflowEnabled,
  );

  const tooltipTitle = getTooltipTitle(props.executionViaZflowEnabled);

  function handleCloseDialog() {
    setOpenDialog(false);
    props.setStopError([]);
  }

  function handleAbortRun() {
    setDisabled(true);
    zflowApi.abortRun(props.runId).then(result => {
      if (Array.isArray(result)) {
        props.setStopError(result);
      } else {
        setOpenDialog(false);
        props.setShowSuccess(true);
      }
    });
  }

  return (
    <>
      <Dialog data-testid="abort-run-dialog" fullWidth open={openDialog}>
        <DialogTitle>Abort Run</DialogTitle>
        <DialogContent dividers>
          <Typography>Abort this run?</Typography>
        </DialogContent>
        <DialogActions>
          <ThemeProvider theme={ButtonTheme}>
            <Button
              data-testid="abort-run-cancel"
              onClick={handleCloseDialog}
              color="inherit"
            >
              Cancel
            </Button>
            <Button
              data-testid="abort-run-confirm"
              onClick={handleAbortRun}
              color="primary"
            >
              Abort
            </Button>
          </ThemeProvider>
        </DialogActions>
      </Dialog>
      <ThemeProvider theme={ButtonTheme}>
        <Tooltip title={tooltipTitle}>
          <span>
            <ManageRunButton
              data-testid="abort-run-button"
              variant="contained"
              color="default"
              onClick={() => setOpenDialog(true)}
              startIcon={<StopIcon />}
              disabled={disabled}
            >
              Abort
            </ManageRunButton>
          </span>
        </Tooltip>
      </ThemeProvider>
    </>
  );
};
