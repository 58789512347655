import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Chip from '@mui/material/Chip';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import FormHelperText from '@mui/material/FormHelperText';
import FormLabel from '@mui/material/FormLabel';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import Autocomplete from '@mui/material/Autocomplete';
import { Link } from '@backstage/core-components';
import {
  ChangeTracker,
  HStack,
  VStack,
  useAllTeams,
  ChangedLineProps,
} from 'plugin-ui-components';
import {
  formatTeamName,
  getObjectDiff,
  getTeamNameFromFullName,
  slugify,
} from 'plugin-core';
import { stepsValues } from './EditToolingPage/stepsValues';

interface ToolingFormProps {
  mode: 'create' | 'edit';
  entity?: any;
  onSubmit: (formData: any) => void;
  isLoading: boolean;
  canEdit?: boolean;
}

interface FormControlledState {
  team: IEntityGroup | undefined;
  journey: string[];
  step: string[] | undefined;
  status: string;
}

export interface ToolingFormInput {
  title: string;
  name: string;
  owner: string;
  journey: string[];
  status: string;
  interface: string;
  training: string;
  purpose: string;
  support: string;
  url: string;
  chat: string;
  step: string[] | string;
  journeyDev?: string;
  journeyData?: string;
}

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 48 * 4.5 + 8,
      width: 250,
    },
  },
};

function sortTeams(res: IEntityGroup[] | undefined) {
  return res?.sort((a, b) =>
    getTeamNameFromFullName(a.spec?.fullName).localeCompare(
      getTeamNameFromFullName(b.spec?.fullName),
    ),
  );
}

export const ToolingForm = (props: ToolingFormProps) => {
  const { mode, entity, onSubmit, isLoading, canEdit } = props;
  const { value: teams = [], loading: teamsLoading } = useAllTeams(sortTeams);
  const disabled = mode === 'edit' && !canEdit;

  const [dialogToggle, setDialogToggle] = React.useState(false);
  const [journeyAlert, setJourneyAlert] = React.useState(false);
  const [formData, setFormData] = React.useState<ToolingFormInput>();
  const [controlledState, setControlledState] =
    React.useState<FormControlledState>({
      journey: entity?.spec.journey || [],
      step: entity?.spec.step,
      status: entity?.spec.status,
      team: undefined,
    });

  // Disable step selection if no journey is selected
  const stepDisabled = disabled || controlledState.journey.length === 0;

  const stepTooltipText = stepDisabled
    ? 'Please select a Journey first to see available steps'
    : '';
  // Show confirmation modal when edit form is submitted
  const toggleConfirmation = (formInput: ToolingFormInput) => {
    setFormData({
      ...formInput,
      step: (formInput.step as string).split(','),
    });
    setDialogToggle(true);
  };

  const submitForm = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const data = new FormData(e.currentTarget);
    const formInput = Object.fromEntries(
      data.entries(),
    ) as unknown as ToolingFormInput;

    // Convert journey checkboxes to array
    formInput.journey = [];
    if (formInput.hasOwnProperty('journeyDev')) {
      formInput.journey.push('Development');
      delete formInput.journeyDev;
    }
    if (formInput.hasOwnProperty('journeyData')) {
      formInput.journey.push('Data');
      delete formInput.journeyData;
    }
    if (formInput.journey.length === 0) {
      // abort the submit and display an erorr message
      return setJourneyAlert(true);
    }
    // Show confirmation dialog in edit mode otherwise submit form
    return mode === 'create'
      ? onSubmit({
          ...formInput,
          step: (formInput.step as string).split(','),
          owner: getTeamNameFromFullName(
            controlledState.team?.spec?.fullName || '',
          ),
          sap_id: controlledState.team?.spec?.team_id,
          name: slugify(formInput.title),
        })
      : toggleConfirmation(formInput);
  };

  const handleJourneyChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    value: string,
  ) => {
    if (e.target.checked) {
      setControlledState({
        ...controlledState,
        journey: [...controlledState.journey, value],
      });
    } else {
      setControlledState({
        ...controlledState,
        journey: controlledState.journey.filter(item => item !== value),
      });
    }
  };

  // Get the changes between the entity and the form data
  const mapEntityChanges = (): Array<ChangedLineProps> => {
    return getObjectDiff(entity.spec, formData, [
      'id',
      'createdBy',
      'createdAt',
      'lastUpdatedBy',
      'updatedAt',
      'sapId',
    ]).map((item: any) => {
      const key = Object.keys(item)[0];
      return {
        label: key.charAt(0).toUpperCase() + key.slice(1),
        oldValue: entity.spec[key],
        // @ts-ignore
        newValue: formData[key],
        changeType: 'changed',
      };
    });
  };

  React.useEffect(() => {
    // Set default team value in edit mode
    if (entity && teams.length) {
      const defaultTeam = teams.find(team => {
        return entity?.relations
          ? team.spec.team_id === entity.spec.sapId
          : false;
      });
      setControlledState(prev => ({
        ...prev,
        team: defaultTeam,
      }));
    }
  }, [entity, teams]);

  return (
    <form onSubmit={submitForm}>
      <Grid container spacing={5}>
        <Grid item xs={12} md={6}>
          <VStack spacing="1.5rem">
            <TextField
              name="title"
              helperText="The official name of this tool or service."
              label="Title"
              variant="outlined"
              defaultValue={entity && entity.spec.title}
              required
              size="small"
              disabled={disabled}
            />
            <Autocomplete
              options={teams.map(team => ({
                label: formatTeamName(team),
                ...team,
              }))}
              value={{
                label: formatTeamName(controlledState.team),
                ...controlledState.team,
              }}
              onChange={(__, value) => {
                setControlledState({
                  ...controlledState,
                  team: value as IEntityGroup,
                });
              }}
              isOptionEqualToValue={(option, value) =>
                option.spec?.team_id === value.spec?.team_id
              }
              renderOption={(elProps, option) => (
                <li {...elProps} key={option?.metadata?.name}>
                  {formatTeamName(option as IEntityGroup)}
                </li>
              )}
              renderInput={params => (
                <TextField
                  {...params}
                  helperText="The owning team should be composed of the people directly responsible for operating and maintaining this tool or service, its metadata, its resources. Owners will be able to edit this entity after creation."
                  label="Owning Team"
                  name="owner"
                  required
                />
              )}
              size="small"
              disabled={teamsLoading || disabled}
            />
            <Autocomplete
              options={['Adopt', 'Trial', 'Assess', 'Hold', 'Retired']}
              value={controlledState.status}
              onChange={(__, value) => {
                setControlledState({
                  ...controlledState,
                  status: value as string,
                });
              }}
              getOptionLabel={option => option}
              isOptionEqualToValue={(option, value) => option === value}
              renderInput={params => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Status"
                  helperText={
                    <>
                      The status that indicates to which degree this tooling or
                      service is recommended for users and stakeholders. For
                      details, please check the{' '}
                      <Link to="https://sunrise.docs.zalando.net/features/tooling-catalog/#definitions-of-tooling-catalog-statuses">
                        Definitions of Tooling Catalog Statuses
                      </Link>
                      .
                    </>
                  }
                  name="status"
                  required
                />
              )}
              size="small"
              disabled={disabled}
            />
            <div>
              <FormGroup>
                <FormLabel required component="legend">
                  Journey
                </FormLabel>
                <HStack>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="journeyDev"
                        defaultChecked={
                          entity && entity.spec.journey.includes('Development')
                        }
                        onChange={e => handleJourneyChange(e, 'Development')}
                        disabled={disabled}
                      />
                    }
                    label="Development"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="journeyData"
                        defaultChecked={
                          entity && entity.spec.journey.includes('Data')
                        }
                        onChange={e => handleJourneyChange(e, 'Data')}
                        disabled={disabled}
                      />
                    }
                    label="Data"
                  />
                </HStack>
              </FormGroup>
              <Tooltip title={stepTooltipText} placement="top-end" arrow>
                <FormControl
                  disabled={stepDisabled}
                  variant="outlined"
                  size="small"
                  margin="dense"
                  required
                >
                  <InputLabel id="tooling-step-label">Steps</InputLabel>
                  <Select
                    variant="outlined"
                    labelId="tooling-step-label"
                    name="step"
                    label="Steps"
                    multiple
                    onChange={val =>
                      setControlledState({
                        ...controlledState,
                        step: val.target.value as Array<string>,
                      })
                    }
                    value={controlledState.step || []}
                    renderValue={selected => (
                      <Box display="flex" flexWrap="wrap" gap={0.5}>
                        {(selected as Array<string>).map(value => (
                          <Chip key={value} label={value} size="small" />
                        ))}
                      </Box>
                    )}
                    MenuProps={MenuProps}
                  >
                    {controlledState.journey.map(item => {
                      return stepsValues[
                        `${item as 'Development' | 'Data'}`
                      ].map(step => (
                        <MenuItem key={step} value={step}>
                          {step}
                        </MenuItem>
                      ));
                    })}
                  </Select>
                  <FormHelperText>
                    The steps in the respective Software Development or Data
                    Science journeys that this tool supports.
                  </FormHelperText>
                </FormControl>
              </Tooltip>
            </div>
            <TextField
              id="outlined-multiline-static"
              label="Purpose"
              helperText={
                <>
                  Describe the purpose of this tool or service so that your
                  users and stakeholders can find it more easily. For tips on
                  how to write a good description, please check the{' '}
                  <Link to="https://sunrise.docs.zalando.net/features/tooling-catalog/#how-to-write-a-great-ie-useful-discoverable-description-of-your-tooling-or-service">
                    available documentation
                  </Link>
                  .
                </>
              }
              name="purpose"
              multiline
              minRows={5}
              defaultValue={entity && entity.spec.purpose}
              variant="outlined"
              size="small"
              required
              disabled={disabled}
            />
          </VStack>
        </Grid>
        <Grid item xs={12} md={6}>
          <VStack spacing="1.5rem">
            <TextField
              label="Interface URL"
              defaultValue={entity && entity.spec.interface}
              helperText="Optional:  Link to the user-facing interface of the service, if available."
              variant="outlined"
              name="interface"
              size="small"
              disabled={disabled}
            />
            <TextField
              label="Support"
              helperText="Please provide a URL or Email for support. This should be a support channel maintained by the Owning team for users to reach out in case of questions or issues."
              defaultValue={entity && entity.spec.support}
              variant="outlined"
              name="support"
              size="small"
              disabled={disabled}
            />
            <TextField
              label="Documentation URL"
              helperText="Link to the tooling documentation."
              defaultValue={entity && entity.spec.url}
              variant="outlined"
              name="url"
              size="small"
              disabled={disabled}
            />
            <TextField
              label="Chat URL"
              helperText="Optional:  Link to a Google Chat Space where users can seek advice on the tooling or service (e.g. community chat)."
              defaultValue={entity && entity.spec.chat}
              variant="outlined"
              name="chat"
              size="small"
              disabled={disabled}
            />
            <TextField
              label="Training"
              helperText="Optional:  Link to training resources for this tool or service (e.g. bookable training session or video)."
              defaultValue={entity && entity.spec.training}
              variant="outlined"
              name="training"
              size="small"
              disabled={disabled}
            />
          </VStack>
        </Grid>
      </Grid>
      <Button
        type="submit"
        variant="outlined"
        color="primary"
        disabled={isLoading || disabled}
        style={{ marginTop: '1rem' }}
      >
        Submit
      </Button>

      {/* Edit form confirmation dialog */}
      <Dialog onClose={() => setDialogToggle(false)} open={dialogToggle}>
        <DialogTitle>Confirmation</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to submit your changes?
          </DialogContentText>
          {formData && <ChangeTracker changes={mapEntityChanges()} />}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialogToggle(false)}>Cancel</Button>
          <Button
            variant="outlined"
            color="primary"
            disabled={isLoading}
            onClick={() => {
              onSubmit({
                ...formData,
                owner: getTeamNameFromFullName(
                  controlledState.team?.spec?.fullName || '',
                ),
                sap_id: controlledState.team?.spec?.team_id,
                name: formData && slugify(formData.title),
              });
              setDialogToggle(false);
            }}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>

      {/* No selected journey alert */}
      <Dialog onClose={() => setJourneyAlert(false)} open={journeyAlert}>
        <DialogTitle>Attention</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please select at least one journey
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setJourneyAlert(false)}>Close</Button>
        </DialogActions>
      </Dialog>
    </form>
  );
};
