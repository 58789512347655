import React, { useEffect, useState } from 'react';
import { Navigate, useLocation, useParams } from 'react-router';
import { useApi } from '@backstage/core-plugin-api';
import { Progress } from '@backstage/core-components';
import { notebooksApiRef, NotebooksApi } from '../../api';
import { getDatalabUrl } from '../../utils';
import { zflowApiRef } from 'plugin-ml-platform';

export interface InteractiveProps {
  interactive?: boolean;
}

interface NavigateToLatestProps extends InteractiveProps {
  parentEntityKey: string;
  id?: string;
}

const getLatestExperimentChildId = async (client: any, pipelineId: string) => {
  const pipelineDetails = await client.getPipeline(pipelineId);
  return [
    pipelineDetails.last_run?.run_id,
    pipelineDetails.last_run?.created_by,
  ];
};

const getLatestChildId = async (
  client: NotebooksApi,
  parentEntityKey: string,
  parentEntityId: string,
) => {
  if (parentEntityKey === 'scheduledNotebook') {
    const scheduledNBDetails = await client.getScheduledNotebookDetails(
      parentEntityId,
    );
    return [
      scheduledNBDetails?.last_run?.id,
      scheduledNBDetails?.last_run?.created_by,
    ];
  }

  if (parentEntityKey === 'sharedNotebook') {
    const sharedNBDetails = await client.getSharedNotebookDetails(
      parentEntityId,
    );
    return [
      sharedNBDetails.last_version?.id,
      sharedNBDetails.last_version?.created_by,
    ];
  }

  if (parentEntityKey === 'experimentNotebook') {
    const expNBDetails = await client.getExperimentNotebookDetails(
      parentEntityId,
    );
    return [
      expNBDetails.last_experiment?.id,
      expNBDetails.last_experiment?.created_by,
    ];
  }

  return [null, null];
};

const getLatestChildDetails = async (
  client: NotebooksApi,
  parentEntityKey: string,
  childId: string,
) => {
  if (parentEntityKey === 'scheduledNotebook') {
    const scheduledNBDetails = await client.getRunDetails(childId);
    return [scheduledNBDetails.id, scheduledNBDetails.created_by];
  }

  if (parentEntityKey === 'sharedNotebook') {
    const sharedNBDetails = await client.getVersionDetails(childId);
    return [sharedNBDetails.id, sharedNBDetails.created_by];
  }

  if (parentEntityKey === 'experimentNotebook') {
    const expNBDetails = await client.getExperimentDetails(childId);
    return [expNBDetails.id, expNBDetails.created_by];
  }
  return [null, null];
};

export const NavigateToLatest = (props: NavigateToLatestProps) => {
  const { parentEntityKey, interactive, id } = props;
  const { projectId, ...rest } = useParams();
  const location = useLocation();
  const notebookClient = useApi(notebooksApiRef);
  const zflowApiClient = useApi(zflowApiRef);
  const parentEntityId = rest[`${parentEntityKey}Id`] || '';
  const [loading, setLoading] = useState(true);
  const [urlToNavigate, setUrlToNavigate] = useState('');

  useEffect(() => {
    const getChildIdCreatedBy = async () => {
      if (id && id !== 'latest') {
        return await getLatestChildDetails(notebookClient, parentEntityKey, id);
      }
      if (location.pathname.split('/').slice(-2)[0] === 'zflow_experiments') {
        return await getLatestExperimentChildId(zflowApiClient, parentEntityId);
      }
      return await getLatestChildId(
        notebookClient,
        parentEntityKey,
        parentEntityId,
      );
    };

    const getUrlToNavigate = async () => {
      setLoading(true);
      const [childId, createdBy] = await getChildIdCreatedBy();
      if (interactive) {
        window.location.replace(
          `${getDatalabUrl()}/services/zflow_dashboards/user/${createdBy}/zflow/dashboards/voila/render/${childId}.ipynb`,
        );
      } else {
        setUrlToNavigate(location.pathname.replace('latest', childId));
      }
      setLoading(false);
    };

    getUrlToNavigate();
  }, [
    zflowApiClient,
    notebookClient,
    parentEntityKey,
    location,
    id,
    interactive,
    parentEntityId,
  ]);
  const redirectComponent = urlToNavigate ? (
    <Navigate to={urlToNavigate} replace />
  ) : (
    <div>Not found</div>
  );
  const redirectComponentWithLoading = loading ? (
    <Progress />
  ) : (
    redirectComponent
  );
  return interactive ? null : redirectComponentWithLoading;
};
