import React from 'react';
import InfoIcon from '@material-ui/icons/Info';
import { Caption } from '../styles';
import { ReviewContext } from '../context';

export const SectionCaption: React.FC = ({ children }) => (
  <ReviewContext.Consumer>
    {() => (
      <Caption variant="caption">
        <InfoIcon />
        {children}
      </Caption>
    )}
  </ReviewContext.Consumer>
);
