import React from 'react';
import { Alert } from '@material-ui/lab';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@material-ui/core';
import { useApi } from '@backstage/core-plugin-api';
import { applicationReviewApiRef } from '../../../api';

interface Props {
  toggle: boolean;
  name: string;
  review: IAppReview.Review;
  onClose: () => void;
  onSuccess: () => void;
}

export function SubmitDialog(props: Props) {
  const api = useApi(applicationReviewApiRef);
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState<Error>();

  const submitReview = () => {
    setLoading(true);
    api
      .submitReview(props.name, props.review)
      .then(props.onSuccess)
      .catch(setError)
      .finally(() => setLoading(false));
  };

  React.useEffect(() => {
    // Reset the dialog when it's toggled on again
    if (props.toggle && error) {
      setError(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.toggle]);

  return (
    <Dialog open={props.toggle}>
      <DialogTitle>Submit application review</DialogTitle>
      {loading && (
        <DialogContent style={{ textAlign: 'center' }}>
          <CircularProgress />
        </DialogContent>
      )}

      {!loading && !error && (
        <>
          <DialogContent>
            <Alert severity="warning" variant="outlined">
              <Typography>
                Are you sure you want to submit the review for&nbsp;
                <code>{props.name}</code>?
              </Typography>
            </Alert>
          </DialogContent>
          <DialogActions>
            <Button onClick={props.onClose} variant="outlined">
              Cancel
            </Button>
            <Button onClick={submitReview} variant="contained" color="primary">
              Confirm
            </Button>
          </DialogActions>
        </>
      )}

      {!loading && error && (
        <>
          <DialogContent>
            <Alert severity="error">
              <Typography>{error?.message}</Typography>
            </Alert>
          </DialogContent>
          <DialogActions>
            <Button onClick={props.onClose} variant="outlined">
              Dismiss
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
}
