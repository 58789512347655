import { IRunsStep } from '../../api/types/payload';
import { StepRunProblemsModel } from '../common';
import { hasValidationErrors, hasValidationWarnings } from '../helpers';

export class RunsStepModel {
  id: string;
  type: string;
  status: string;
  depends_on: Array<string>;
  problems: StepRunProblemsModel;
  hasValidationErrors: boolean;
  hasValidationWarnings: boolean;

  constructor(json: IRunsStep = {} as IRunsStep) {
    this.id = json.id ?? '';
    this.type = json.type ?? '';
    this.status = json.status ?? '';
    this.depends_on = json.depends_on ?? [];
    this.problems = new StepRunProblemsModel(json.run?.problems ?? {});
    this.hasValidationErrors = hasValidationErrors(json.run?.problems ?? {});
    this.hasValidationWarnings = hasValidationWarnings(
      json.run?.problems ?? {},
    );
  }
}
