import React from 'react';
import { observer } from 'mobx-react-lite';
import TodayIcon from '@material-ui/icons/Today';
import TimerIcon from '@material-ui/icons/Timer';

import { DetailsHeader, PollingIcon } from '../../../common';
import { Text, Tooltip } from '../../../../common';
import { StepActions } from '../Actions';
import { Runtime } from './Runtime';
import {
  durationHumanized,
  getFormattedDateTime,
  timeAgo,
} from '../../../../utils/time';
import { STEP_STATUS } from '../../../../constants';
import { useServices } from '../../../../services';

type DetailsProps = {
  created_at: string;
  status: string;
  finished_at: string;
  human_approval_timeout: string;
};

function Time({ createdAt }: { createdAt: string }) {
  return (
    <Tooltip title={getFormattedDateTime(createdAt)}>
      <Text marginRight={2}>
        <TodayIcon fontSize="inherit" />
        <Text marginLeft={0.5}>Started: {timeAgo(createdAt)}</Text>
      </Text>
    </Tooltip>
  );
}

function Details(props: DetailsProps) {
  switch (props.status) {
    case STEP_STATUS.INIT:
    case STEP_STATUS.IN_PROGRESS:
      return (
        <>
          <Time createdAt={props.created_at} />
          <Runtime startedAt={props.created_at} status={props.status} />
        </>
      );
    case STEP_STATUS.SUCCEEDED:
    case STEP_STATUS.FAILED:
    case STEP_STATUS.ERROR:
    case STEP_STATUS.ABORTING:
    case STEP_STATUS.TIMEOUT:
      return (
        <>
          {props.created_at && <Time createdAt={props.created_at} />}
          {props.finished_at && props.created_at && (
            <Text>
              <TimerIcon fontSize="inherit" />
              <Text marginLeft={0.5}>
                Ran for:{' '}
                {durationHumanized(props.created_at, props.finished_at)}
              </Text>
            </Text>
          )}
        </>
      );
    case STEP_STATUS.PENDING_APPROVAL:
      return (
        <Text>
          <TimerIcon fontSize="inherit" />
          <Text marginLeft={0.5}>
            Human approval timeout: {props.human_approval_timeout}
          </Text>
        </Text>
      );
    case STEP_STATUS.NOT_STARTED:
      return <Text>Step not started</Text>;
    case STEP_STATUS.SKIPPED:
      return <Text>Step skipped</Text>;
    default:
      return <></>;
  }
}

export const Header = observer(() => {
  const { runService, trafficService } = useServices();
  const { step, run } = runService;

  return (
    <DetailsHeader
      status={step.status}
      title={step.id}
      actions={
        <>
          <StepActions
            status={step.status}
            pipelineStatus={run.status}
            skippingStepDisabled={!step.allow_manually_skipping_step}
            type={step.type}
            isAutomatedTraffic={step.is_automated}
            isTrafficPaused={step.traffic_paused}
            isAutomatedRollbackDisabled={step.is_automated_rollback_disabled}
            currentPercentage={trafficService.getCurrentPercentage(step.run.id)}
          />
          <PollingIcon service={runService} />
        </>
      }
      details={
        <Details
          status={step.status}
          human_approval_timeout={step.human_approval_timeout}
          created_at={step.run.created_at}
          finished_at={step.run.finished_at}
        />
      }
    />
  );
});
