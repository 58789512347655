import React from 'react';
import { SearchConfigForGCS } from '../models';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import { AccentColors } from '../constants/AccentColors';
import { extractGCSFields, formatIndexLabelToID } from '../utils';
import { ResultDataList } from '../components/ResultDataList';
import { Link } from '@backstage/core-components';
import { HighlightedGCSTexts } from '../components/SearchResultHighlighter/HighlightedGCSTexts';
import { TeamReference } from 'plugin-ui-components';

interface ResourceTypeMetadata {
  owner: string;
  application: string;
  name: string;
}

const label = 'Resource Types';
const id = formatIndexLabelToID(label);
export const resourceTypes = new SearchConfigForGCS({
  id,
  label,
  datasource: 'sunrise',
  icon: VpnKeyIcon,
  accentColor: AccentColors.Default,
  objectTypes: ['resourceTypes'],
  getUrl: hit => {
    const { application } = extractGCSFields<ResourceTypeMetadata>(hit);
    if (!application) return undefined;
    return `/catalog/default/component/${application}/access-control`;
  },
  getTitle: hit => {
    const { name } = extractGCSFields<ResourceTypeMetadata>(hit);
    return name ? `${hit.title} (${name})` : hit.title;
  },
  render: hit => {
    const { owner, application } = extractGCSFields<ResourceTypeMetadata>(hit);
    return (
      <>
        <ResultDataList
          dense
          list={[
            {
              label: 'Owner',
              value: owner && <TeamReference team={owner} />,
            },
            {
              label: 'Application',
              value: application && (
                <Link to={`/catalog/default/component/${application}`}>
                  {application}
                </Link>
              ),
            },
          ]}
        />
        <HighlightedGCSTexts snippet={hit.snippet} />
      </>
    );
  },
});
