import React from 'react';
import { stringifyEntityRef } from '@backstage/catalog-model';
import { useApi } from '@backstage/core-plugin-api';
import { catalogApiRef } from '@backstage/plugin-catalog-react';
import { useAsync, useAsyncRetry } from 'react-use';
import { personalizationApiRef, ZalandoIdentityApiRef } from 'plugin-core';
import { getUserTeams } from './utils';

interface IUserTeamsContext {
  currentUserTeams: {
    retry: VoidFunction;
    loading: boolean;
    error?: Error;
    value?: IUserTeams;
  };
  customUserTeams: {
    retry: VoidFunction;
    loading: boolean;
    error?: Error;
    value?: IUserTeams;
  };
  setCustomUser: (username: string) => void;
}

// Initialize it once to help eliminate re-renders
const initialValue: IUserTeams = {
  memberTeams: [],
  ledTeams: [],
  watchedTeams: [],
  accountableTeams: [],
  unfollowedTeams: [],
};

export const UserTeamsContext = React.createContext<IUserTeamsContext>({
  currentUserTeams: {
    loading: false,
    retry: () => {},
  },
  customUserTeams: {
    loading: false,
    retry: () => {},
  },
  setCustomUser: () => {},
});

export const UserTeamsProvider: React.FC = ({ children }) => {
  const [customUser, setCustomUser] = React.useState<string>();
  const catalogApi = useApi(catalogApiRef);
  const identityApi = useApi(ZalandoIdentityApiRef);
  const personalizationApi = useApi(personalizationApiRef);

  const { value: currentUser } = useAsync(
    () => identityApi.getLogin(),
    [identityApi],
  );

  const getTeamsFor = React.useCallback(
    async (username?: string, includeWatched?: boolean) => {
      if (username) {
        const userTeams: IUserTeams = await getUserTeams({
          username,
          catalogApi,
          personalizationApi,
          includeWatched,
        }).then(d => ({ ...d, accountableTeams: [] }));
        const { memberTeams = [], ledTeams = [] } = userTeams;
        if (memberTeams.length + ledTeams.length) {
          const teams = [...memberTeams, ...ledTeams];
          userTeams.accountableTeams =
            await personalizationApi.getAccountableTeams(
              teams.map(stringifyEntityRef),
            );
        }
        return userTeams;
      }
      return initialValue;
    },
    [catalogApi, personalizationApi],
  );

  /** The async state of the current logged-in user */
  const currentUserTeams = useAsyncRetry<IUserTeams>(
    () => getTeamsFor(currentUser),
    [currentUser],
  );

  /** The async state of a custom user requested throughout the app */
  const customUserTeams = useAsyncRetry<IUserTeams>(
    () => getTeamsFor(customUser, false),
    [customUser],
  );

  return (
    <UserTeamsContext.Provider
      value={{
        currentUserTeams,
        customUserTeams,
        setCustomUser,
      }}
    >
      {children}
    </UserTeamsContext.Provider>
  );
};

export function useTeamsContext() {
  return React.useContext(UserTeamsContext);
}
