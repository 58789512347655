import React, { useEffect } from 'react';
import { Header, HeaderLabel } from '@backstage/core-components';
import { TechDocsReaderPageContent } from '@backstage/plugin-techdocs';
import { useTechDocsReaderPage } from '@backstage/plugin-techdocs-react';
import { getEntityRelations } from '@backstage/plugin-catalog-react';
import { RELATION_OWNED_BY } from '@backstage/catalog-model';
import { generatePath } from 'react-router-dom';
import {
  renderMermaidDiagrams,
  SHADOW_ROOT_SELECTOR,
} from '../../utils/techDocs';
import { Box } from '@material-ui/core';
import { LastSync, TeamReference } from 'plugin-ui-components';

/**
 * Renders the reader page header.
 */
export const TechDocsReaderPageCustomHeader = () => {
  const {
    title,
    setTitle,
    metadata: { value: metadata },
    entityMetadata: { value: entityMetadata },
  } = useTechDocsReaderPage();

  useEffect(() => {
    if (!metadata) return;
    const { site_name } = metadata;
    setTitle(site_name);
  }, [metadata, setTitle]);

  const { spec } = entityMetadata || {};
  const lifecycle = spec?.lifecycle;
  const specSpec = spec?.spec as IEntityDOCSpec;
  const owner = spec?.owner || specSpec?.team_id || '';
  const category = spec?.category || '';

  const ownedByRelations = entityMetadata
    ? getEntityRelations(entityMetadata, RELATION_OWNED_BY)
    : [];

  const docsRootLink = generatePath('/docs');

  const labels = (
    <>
      {!!ownedByRelations.length && (
        <HeaderLabel
          label="Owner"
          value={
            <TeamReference
              team={owner as string}
              format="nameOnly"
              color="inherit"
            />
          }
        />
      )}
      {lifecycle && <HeaderLabel label="Lifecycle" value={lifecycle} />}
      {category && (
        <HeaderLabel label={(<>Category </>) as any} value={category} />
      )}
      {entityMetadata?.metadata?.modifiedAt && (
        <LastSync
          lastSyncDate={new Date(
            entityMetadata.metadata.modifiedAt as string,
          ).toISODateString()}
        />
      )}
    </>
  );

  return (
    <Header type="Documentation" typeLink={docsRootLink} title={title}>
      {labels}
    </Header>
  );
};

function handleDocumentReady() {
  const shadowRoot = document.querySelector(SHADOW_ROOT_SELECTOR)?.shadowRoot;
  const pageTitle = shadowRoot?.querySelector('title')?.textContent;
  if (pageTitle) {
    document.title = `${pageTitle} | Sunrise`;
  }
  renderMermaidDiagrams();
}

export const TechDocsReaderPageCustom = () => {
  return (
    <Box gridColumn="span 3">
      <TechDocsReaderPageCustomHeader />
      <TechDocsReaderPageContent onReady={handleDocumentReady} />
    </Box>
  );
};
