import React, { useState } from 'react';
import { useApi } from '@backstage/core-plugin-api';
import { notebooksApiRef } from '../../api';
import { constructLinkToDataLabNotebook } from '../../utils';
import { ListEntities, ActionsTableCell } from '../../components';
import { NotebooksProps } from '../NotebooksProps';
import { zflowApiRef } from 'plugin-ml-platform';
import { NO_OF_ROWS_PER_PAGE } from '../../config';
import { Select, MenuItem, FormControl, InputLabel } from '@material-ui/core';
import { SubvalueCell } from '@backstage/core-components';
import {
  SourceNotebookLinkTag,
  TagGroup,
  UserTag,
} from '../../components/Tags';

const ExperimentTypes = [
  {
    label: 'notebook',
    value: 'notebook',
  },
  {
    label: 'zflow',
    value: 'zflow',
  },
];

export const ExperimentNotebooks = (props: NotebooksProps) => {
  const { projectId } = props;
  const notebookClient = useApi(notebooksApiRef);
  const zflowApiClient = useApi(zflowApiRef);
  const [refreshList, setRefreshList] = useState(false);
  const [page, setPage] = useState(0);
  const [archiveNotebookError, setArchiveNotebookError] = useState<Error>();
  const [archivePipelineError, setArchivePipelineError] = useState<Error>();

  const [experimentType, setExperimentType] = useState<string>(
    ExperimentTypes[0].value,
  );

  const onDelete = async (event: any, id: string) => {
    event?.stopPropagation();
    try {
      await notebookClient.deleteExperimentNotebook(id);
    } catch (err) {
      setArchiveNotebookError(err as Error);
    }
    setRefreshList(!refreshList);
  };

  const onDeleteExperiments = async (event: any, id: string) => {
    event?.stopPropagation();
    try {
      await zflowApiClient.archiveExperimentPipeline(id);
    } catch (err) {
      setArchivePipelineError(err as Error);
    }
    setRefreshList(!refreshList);
  };

  const COLUMNS = [
    {
      title: 'Experiment Notebook',
      field: 'name',
      render: (row: any): React.ReactNode => (
        <SubvalueCell
          value={<b>{row.name}</b>}
          subvalue={
            <TagGroup>
              <UserTag user={row.created_by} />
              <SourceNotebookLinkTag
                link={constructLinkToDataLabNotebook(row.notebook_path)}
              />
            </TagGroup>
          }
        />
      ),
    },
    {
      title: 'Actions',
      render: (row: any) => (
        <ActionsTableCell
          parentEntityType="experiment_notebooks"
          id={row.id}
          name={row.name}
          is_interactive={row.is_interactive}
          archived={row.archived}
          childEntityType="experiments"
          onDelete={onDelete}
        />
      ),
    },
  ];

  const COLUMNS_EXPERIMENTS = [
    {
      title: 'Name',
      field: 'name',
      render: (row: any): React.ReactNode => (
        <SubvalueCell
          value={<b>{row.name}</b>}
          subvalue={
            <TagGroup>
              <UserTag user={row.created_by} />
            </TagGroup>
          }
        />
      ),
    },
    {
      title: 'Actions',
      render: (row: any) => (
        <ActionsTableCell
          parentEntityType="pipelines"
          id={row.id}
          name={row.name}
          archived={row.archived}
          childEntityType="zflow_experiments"
          onDelete={onDeleteExperiments}
        />
      ),
    },
  ];
  return (
    <>
      <FormControl variant="outlined" margin="dense" fullWidth>
        <InputLabel>Experiment Type</InputLabel>
        <Select
          onChange={ev => setExperimentType(String(ev.target.value))}
          value={experimentType}
          label="Experiment Type"
          style={{ maxWidth: 200, marginBottom: '1rem' }}
          MenuProps={{
            anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
            getContentAnchorEl: null,
          }}
        >
          {ExperimentTypes.map(item => (
            <MenuItem key={item.value} value={item.value}>
              {item.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <br />
      {experimentType === ExperimentTypes[0].value ? (
        <ListEntities
          getEntitiesPromise={notebookClient.getExperimentNotebooks(
            projectId,
            page,
          )}
          entityKey="experiment_notebooks"
          columns={COLUMNS}
          childEntityKey="experiments"
          refreshList={refreshList}
          page={page}
          onPageChange={setPage}
          error={archiveNotebookError}
          setError={setArchiveNotebookError}
        />
      ) : (
        <ListEntities
          getEntitiesPromise={zflowApiClient.getExperimentPipelines(
            projectId,
            NO_OF_ROWS_PER_PAGE,
            undefined,
            page * NO_OF_ROWS_PER_PAGE,
            false,
          )}
          entityKey="pipelines"
          columns={COLUMNS_EXPERIMENTS}
          childEntityKey="zflow_experiments"
          refreshList={refreshList}
          page={page}
          onPageChange={setPage}
          error={archivePipelineError}
          setError={setArchivePipelineError}
        />
      )}
    </>
  );
};
