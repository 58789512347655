import React from 'react';
import { SectionRadio } from '../common';
import { PlatformsAndLanguagesStats } from './PlatformsAndLanguagesStats';

export function TechStack() {
  return (
    <section>
      <dl>
        <dt>Platforms/Languages</dt>
        <dd>
          <PlatformsAndLanguagesStats />
        </dd>
      </dl>

      <SectionRadio name="tech_ok" />
    </section>
  );
}
