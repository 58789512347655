import React from 'react';
import { CardContent, Grid } from '@material-ui/core';
import { MLModel } from '../../api/definitions';
import { AboutField, InfoCard } from 'plugin-ui-components';
import { ModelDataset } from './ModelDataset';
import { MissingFieldPlaceholder } from '../common/MissingFieldPlaceholder';

export const ModelArchitectureAndData = ({ model }: { model: MLModel }) => {
  return (
    <>
      <InfoCard title="Model Architecture">
        <CardContent>
          <Grid container>
            <AboutField
              label=" "
              children={
                model.model_parameters?.model_architecture || (
                  <MissingFieldPlaceholder
                    field="model architecture"
                    type="model-card"
                  />
                )
              }
              xs={12}
              sm={12}
              lg={12}
            />
          </Grid>
        </CardContent>
      </InfoCard>

      <InfoCard title="Input and Output">
        <CardContent>
          <Grid container>
            <AboutField
              label="Input Format"
              children={
                model.model_parameters?.input_format || (
                  <MissingFieldPlaceholder
                    field="model input format"
                    type="model-card"
                  />
                )
              }
              xs={12}
              sm={12}
              lg={12}
            />
            <AboutField
              label="Output Format"
              children={
                model.model_parameters?.output_format || (
                  <MissingFieldPlaceholder
                    field="model output format"
                    type="model-card"
                  />
                )
              }
              xs={12}
              sm={12}
              lg={12}
            />
          </Grid>
        </CardContent>
      </InfoCard>

      <InfoCard title="Dataset">
        <ModelDataset model_data={model.model_parameters?.data} />
      </InfoCard>
    </>
  );
};
