import React from 'react';
import { ReviewContext } from '../context';
import { Issue } from './Issue';

interface Props {
  field: IAppReview.IssueCheckField;
}

export const WithIssue: React.FC<Props> = ({ children, field }) => {
  const { issues } = React.useContext(ReviewContext);

  const issue = issues.find(i => i.field === field);

  if (!issue) {
    return <>{children}</>;
  }

  return (
    <>
      {children}
      <Issue {...issue} />
    </>
  );
};
