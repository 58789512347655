import React, { useState, useEffect } from 'react';
import { useCopyToClipboard } from 'react-use';
import { Grid, Box, ButtonGroup, Button } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import DashboardIcon from '@material-ui/icons/Dashboard';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { useApi } from '@backstage/core-plugin-api';
import {
  ExperimentNotebookResponse,
  ScheduledNotebookResponse,
  SharedNotebookResponse,
  notebooksApiRef,
} from '../../api';
import { DismissableBanner } from '@backstage/core-components';
import { useNavigate } from 'react-router';
import { Snackbar } from '../Snackbar';
import { Confirmation } from '../Confirmation';
import { Warning } from '../Warning';
import { RunNow } from '../RunNow';
import { zflowApiRef } from 'plugin-ml-platform';
import { RuleSwitch } from '../RuleSwitch';

interface CopyActionsBarProps {
  type: string;
  id: string;
  showRunNow?: boolean;
  hideDelete?: boolean;
  hideCopyLatest?: boolean;
  nameToDelete?: string;
}

export const CopyActionsBar = (props: CopyActionsBarProps) => {
  const { id, type, hideDelete, hideCopyLatest, showRunNow, nameToDelete } =
    props;
  const notebookClient = useApi(notebooksApiRef);
  const zflowApiClient = useApi(zflowApiRef);
  const [copiedState, copyToClipboard] = useCopyToClipboard();
  const [snackBarMsg, setSnackBarMsg] = useState<string>('');
  const navigate = useNavigate();
  const [toggleScheduledNotebookError, setToggleScheduledNotebookError] =
    useState<Error>();
  const [archiveNotebookError, setArchiveNotebookError] = useState<Error>();
  const [runNowError, setRunNowError] = useState<Error>();

  const [details, setDetails] = useState<
    | ScheduledNotebookResponse
    | SharedNotebookResponse
    | ExperimentNotebookResponse
  >();
  const [open, setOpen] = useState(false);

  const openDialog = () => {
    setOpen(true);
  };

  const closeDialog = () => {
    setOpen(false);
  };

  useEffect(() => {
    const getDetails = async () => {
      switch (type) {
        case 'experiments':
          return await notebookClient.getExperimentNotebookDetails(id);
        case 'versions':
          return await notebookClient.getSharedNotebookDetails(id);
        case 'runs':
          return await notebookClient.getScheduledNotebookDetails(id);
        default:
          return undefined;
      }
    };
    getDetails().then(res => setDetails(res));
  }, [notebookClient, id, type]);

  const getDeletionResponse = async () => {
    switch (type) {
      case 'experiments':
        return await notebookClient.deleteExperimentNotebook(id);
      case 'versions':
        return await notebookClient.deleteSharedNotebook(id);
      case 'runs':
        return await notebookClient.deleteScheduledNotebook(id);
      case 'zflow-experiments':
        return await zflowApiClient.archiveExperimentPipeline(id);
      default:
        throw Error(`${type} not implemented for delete response`);
    }
  };
  const onDeleteNotebook = async () => {
    try {
      await getDeletionResponse();
      const pathArr = location.pathname.split('/').slice(0, 6);
      navigate(pathArr.join('/'));
    } catch (err) {
      setArchiveNotebookError(err as Error);
      closeDialog();
    }
  };

  const onCopyUrl = (event: any, isInteractive: boolean) => {
    event?.stopPropagation();
    const baseUrl = `${window.location.href}/latest`;
    const url = isInteractive ? `${baseUrl}/interactive` : baseUrl;
    copyToClipboard(url);
    setSnackBarMsg('Copied to clipboard!');
  };

  const onSwitch = async (scheduledNotebookId: string, disabled: boolean) => {
    try {
      await notebookClient.updateScheduledNotebook(
        scheduledNotebookId,
        disabled,
      );
    } catch (err) {
      setToggleScheduledNotebookError(err as Error);
    }
  };

  return (
    <div>
      <Grid container>
        <Snackbar message={snackBarMsg} onClose={setSnackBarMsg} />
        <Grid item xs={12}>
          <Box paddingBottom={2} display="flex" justifyContent="flex-end">
            <ButtonGroup variant="text">
              {details && 'disabled' in details && (
                <RuleSwitch
                  id={id}
                  disabled={details?.disabled}
                  onSwitch={onSwitch}
                  text="schedule"
                />
              )}
              {showRunNow && (
                <RunNow
                  id={id}
                  setError={setRunNowError}
                  setSnackBarMsg={setSnackBarMsg}
                />
              )}
              {!hideDelete && (
                <Button
                  color="secondary"
                  startIcon={<DeleteIcon />}
                  onClick={openDialog}
                  disabled={details?.archived}
                >
                  Delete
                </Button>
              )}
              {details && 'is_interactive' in details && (
                <Button
                  startIcon={<DashboardIcon />}
                  onClick={e => onCopyUrl(e, true)}
                >
                  Copy latest dashboard URL
                </Button>
              )}
              {!hideCopyLatest && (
                <Button
                  startIcon={<FileCopyIcon />}
                  onClick={e => onCopyUrl(e, false)}
                >
                  Copy latest notebook URL
                </Button>
              )}
            </ButtonGroup>
          </Box>
        </Grid>
        {copiedState.error && (
          <DismissableBanner
            message="Failed to copy text"
            variant="info"
            id="copy_error"
            fixed
          />
        )}
      </Grid>
      <Confirmation
        open={open}
        handleCancel={closeDialog}
        handleConfirm={onDeleteNotebook}
        cancelText="Cancel"
        confirmText="Delete"
        titleText="Deleting Notebook"
        bodyText={`Are you sure you want to delete ${
          details?.name || nameToDelete
        }?`}
      />
      {toggleScheduledNotebookError?.name && (
        <Warning
          name={toggleScheduledNotebookError.name}
          message={toggleScheduledNotebookError.message}
          setError={setToggleScheduledNotebookError}
        />
      )}
      {archiveNotebookError?.name && (
        <Warning
          name={archiveNotebookError.name}
          message={archiveNotebookError.message}
          setError={setArchiveNotebookError}
        />
      )}
      {runNowError?.name && (
        <Warning
          name={runNowError.name}
          message={runNowError.message}
          setError={setRunNowError}
        />
      )}
    </div>
  );
};
