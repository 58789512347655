import { IRunStep } from '../../api/types/payload';
import { RunStepRunModel } from './stepRun.model';
import { STEP_TYPE } from '../../constants';

export enum StepTabsEnum {
  Details,
  Artifacts,
  TestResults,
  Logs,
  DeploymentStatus
}

export class RunStepModel {
  id: string;
  type: string;
  vm: string;
  accountable_user: string;
  allow_manually_skipping_step: boolean;
  desc: string;
  process: string;
  target_cluster: string;
  target_percentage: number;
  is_automated: boolean;
  traffic_paused: boolean;
  is_automated_rollback_disabled: boolean;
  stackset: string;
  stack_version: string;
  run: RunStepRunModel;
  run_history: RunStepRunModel[];
  human_approval_timeout: string;
  status: string;
  depends_on: string[];
  ordinal: number;
  runner: string;
  isOverlayBuild: boolean;

  tabs: Array<StepTabsEnum>;

  constructor(json: IRunStep = {} as IRunStep) {
    this.id = json.id ?? '';
    this.type = json.type ?? '';
    this.vm = json.vm ?? '';
    this.accountable_user = json.accountable_user ?? '';
    this.allow_manually_skipping_step =
      json.allow_manually_skipping_step ?? false;
    this.desc = json.desc ?? '';
    this.process = json.process ?? '';
    this.target_cluster = json.target_cluster ?? '';
    this.target_percentage = json.target_percentage ?? 0;
    this.is_automated = json.is_automated ?? false;
    this.is_automated_rollback_disabled = json.is_automated_rollback_disabled ?? false;
    this.traffic_paused = json.paused ?? false;
    this.stackset = json.stackset ?? '';
    this.stack_version = json.stack_version ?? '';
    this.human_approval_timeout = json.human_approval_timeout ?? '';
    this.status = json.status ?? 'unknown';
    this.depends_on = json.depends_on ?? [];
    this.run_history = (json.run_history || []).map(
      run => new RunStepRunModel(run ?? {}),
    );
    this.run = new RunStepRunModel(json.run ?? {});
    this.ordinal = json.ordinal ?? -1;
    this.runner = json.runner || "";
    this.isOverlayBuild = "overlay" in (json.config || {}) && this.type === STEP_TYPE.SCRIPT;

    switch (this.type) {
      case STEP_TYPE.SCRIPT:
        this.tabs = [StepTabsEnum.Details, StepTabsEnum.Artifacts, StepTabsEnum.TestResults, StepTabsEnum.Logs];
        break;
      case STEP_TYPE.PROCESS:
        this.tabs = [StepTabsEnum.Details, StepTabsEnum.DeploymentStatus, StepTabsEnum.Logs];
        break;
      case STEP_TYPE.DEPLOY:
        this.tabs = [StepTabsEnum.Details, StepTabsEnum.DeploymentStatus];
        break;
      case STEP_TYPE.OVERLAY:
        this.tabs = [StepTabsEnum.Details, StepTabsEnum.Logs];
        break;
      default:
        this.tabs = [StepTabsEnum.Details];
    }
  }
}
