export enum RepositoryType {
  code = 'code',
  config = 'config',
  doc = 'doc',
  tools = 'tools',
  secrets = 'secrets',
}

export enum TemplateType {
  gitignore = 'gitignore',
  application = 'application',
}

export interface CreateRepositoryRequest {
  org_name: string;
  team_id: string;
  name: string;
  description?: string;
  type: string;
  template_type?: string;
  template_name?: string;
  application_template_form: ApplicationTemplateForm;
}

export interface Repository {
  org_name: string;
  team_id: string;
  name: string;
  description?: string;
  type: string;
  application_template?: string;
  gitignore_template?: string;
  application_template_form: ApplicationTemplateForm;
}

export enum Status {
  schedule = 'schedule',
  in_progress = 'in progress',
  success = 'success',
  failure = 'failure',
  error = 'error',
}

export interface RepositoryStatus {
  org_name: string;
  repo_name: string;
  status: string;
  description: string;
}

export interface CustomizedRepositoryStatus {
  status: string;
  description: string;
  field?: string;
}

export type Organization = string;

export interface ApplicationTemplate {
  name: string;
  'source-url': string;
  type: string;
  description: string;
  cookie_cutter_template?: boolean;
}

export interface ApplicationTemplateForm {
  form_values: FormValue[];
}

export interface FormValue {
  key: string;
  value: string;
}
