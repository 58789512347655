import { styled } from '@material-ui/core';
import FileCopyOutlined from '@material-ui/icons/FileCopyOutlined';

export const Nav = styled('nav')(({ theme }: IStyledProps) => ({
  marginBottom: `${theme.spacing(2)}px`,
  display: 'grid',
  gridTemplateColumns: 'repeat(auto-fill, minmax(65px, max-content))',
  gridAutoColumns: 'min-content',
  gridGap: theme.spacing(3),
}));

export const StyledFileCopyOutlined = styled(FileCopyOutlined)(() => ({
  fontSize: '14px',
  opacity: '0.5',
  cursor: 'pointer',
  marginLeft: '8px',
  '&:hover': {
    opacity: '0.8',
  },
}));
