import {
  createApiFactory,
  createApiRef,
  createPlugin,
  createRoutableExtension,
  discoveryApiRef,
} from '@backstage/core-plugin-api';

import { rootRouteRef } from './routes';
import { NLInterfaceApiClient } from './api';
import { oauth2ApiRef } from 'plugin-core';
import { NLInterfaceApi } from './types';

export const NLInterfaceApiRef = createApiRef<NLInterfaceApi>({
  id: 'nl-interface-api',
});

export const NLInterfacePlugin = createPlugin({
  id: 'nl-interface',
  apis: [
    createApiFactory({
      api: NLInterfaceApiRef,
      deps: {
        oauth2Api: oauth2ApiRef,
        discoveryApi: discoveryApiRef,
      },
      factory: ({ oauth2Api, discoveryApi }) =>
        new NLInterfaceApiClient({
          oauth2Api,
          discoveryApi,
        }),
    }),
  ],
  routes: {
    root: rootRouteRef,
  },
});

export const NLInterfacePage = NLInterfacePlugin.provide(
  createRoutableExtension({
    name: 'NLInterfacePage',
    component: () =>
      import('./components/NLInterfacePage').then(m => m.NLInterfacePage),
    mountPoint: rootRouteRef,
  }),
);
