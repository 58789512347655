import { DiscoveryApi, OAuthApi } from '@backstage/core-plugin-api';
import { DocQuestions, NLInterfaceApi, SearchResult } from './types';

export class NLInterfaceApiClient implements NLInterfaceApi {
  public readonly oauth2Api: OAuthApi;
  public readonly discoveryApi: DiscoveryApi;

  constructor(options: { oauth2Api: OAuthApi; discoveryApi: DiscoveryApi }) {
    this.oauth2Api = options.oauth2Api;
    this.discoveryApi = options.discoveryApi;
  }

  async getDocQuestions(count: number): Promise<DocQuestions> {
    const baseUrl = await this.discoveryApi.getBaseUrl('nl-interface');
    const response = await fetch(`${baseUrl}/questions?count=${count}`, {
      headers: {
        Authorization: `Bearer ${await this.oauth2Api.getAccessToken()}`,
      },
    });

    if (response.ok) {
      return await response.json();
    }

    throw new Error('Failed to fetch questions');
  }

  async search(text: string): Promise<SearchResult> {
    const baseUrl = await this.discoveryApi.getBaseUrl('nl-interface');

    const response = await fetch(`${baseUrl}/search`, {
      method: 'POST',
      body: JSON.stringify({ text, namespace: 'build' }),
      headers: {
        Authorization: `Bearer ${await this.oauth2Api.getAccessToken()}`,
        'Content-Type': 'application/json',
      },
    });
    const jsonData = await response.json();

    if (response.ok) {
      return jsonData;
    }

    const errorMessage =
      jsonData?.detail && typeof jsonData.detail !== 'object'
        ? `: ${jsonData.detail}`
        : '';
    throw new Error(`An error occurred while searching ${errorMessage}`);
  }
}
