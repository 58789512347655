import React, { useState, useEffect } from 'react';
import { Chart } from 'react-charts';
import { Grid, Box, Typography, useTheme } from '@material-ui/core';
import { AxisDropDown, AxisOptions } from './AxisDropDown';

const defaultColors = [
  '#faa43a',
  '#ff4e4e',
  '#53cfc9',
  '#a2d925',
  '#decf3f',
  '#734fe9',
  '#cd82ad',
  '#006d92',
  '#de7c00',
  '#f33232',
  '#3f9a80',
  '#53c200',
  '#d7af00',
  '#4c26c9',
  '#d44d99',
  '#0f83ab',
];

interface GraphProps {
  data: AxisOptions[];
}

interface Point {
  x: any;
  y: any;
}

type CHART_TYPES = 'line' | 'area' | 'bar' | 'bubble';
type SCALE_TYPES = 'band' | 'linear';

const getScaleType = (axisData: any[][]): SCALE_TYPES => {
  if (axisData.length === 0) {
    return 'band';
  }
  for (const valueArr of axisData) {
    for (const value of valueArr) {
      if (isNaN(Number(value))) {
        return 'band';
      }
    }
  }
  return 'linear';
};

export const Graph = (props: GraphProps) => {
  const VALID_CHART_TYPES = ['bubble', 'line', 'bar', 'area'].map(x => ({
    key: x,
    value: x,
  }));

  const defaultXAxisKey = 'experiment';
  const defaultXAxis: AxisOptions[] = [
    {
      key: defaultXAxisKey,
      value: defaultXAxisKey,
    },
  ];
  const defaultChartType = VALID_CHART_TYPES[0];

  const theme = useTheme();
  const [xAxis, setXAxis] = useState(defaultXAxisKey);
  const [yAxis, setYAxis] = useState<string[]>([]);
  const [defaultYAxis, setDefaultYAxis] = useState<AxisOptions[]>([]);
  const [xScaleType, setXScaleType] = useState<SCALE_TYPES>('band');
  const [yScaleType, setYScaleType] = useState<SCALE_TYPES>('band');
  const [data, setData] = useState<any[]>([]);
  const [loaded, setloaded] = useState(false);

  const [chartType, setChartType] = useState(defaultChartType.key);

  useEffect(() => {
    setYAxis(props.data.length ? [props.data[0]?.key] : []);
    setDefaultYAxis(
      props.data.length
        ? [
            {
              key: props?.data[0]?.key,
              value: props?.data[0]?.value,
              parentId: props?.data[0]?.parentId,
              createdAt: props?.data[0]?.createdAt,
              type: props?.data[0]?.type,
            },
          ]
        : [],
    );
    if (props.data.length > 0) {
      setloaded(true);
    }
  }, [props.data]);

  useEffect(() => {
    const xData =
      xAxis === defaultXAxisKey
        ? yAxis.map(yKey =>
            props.data.filter(y => y.key === yKey).map(y => y.parentId),
          )
        : yAxis.map(() =>
            props.data.filter(x => x.key === xAxis).map(x => x.value),
          );

    const yData = yAxis.length
      ? yAxis.map(yKey =>
          props.data.filter(y => y.key === yKey).map(y => y.value),
        )
      : [];

    const res = yAxis.map((label, i) => ({
      data: yData[i].map((y, j) => ({ x: xData[i][j] || 'undefined', y })),
      label,
    }));
    setXScaleType(xAxis === defaultXAxisKey ? 'band' : getScaleType(xData));
    setYScaleType(getScaleType(yData));
    setData(res);
  }, [xAxis, yAxis, props.data]);

  return loaded ? (
    <Grid container spacing={1}>
      <Grid item sm={2}>
        <Box paddingLeft={2} paddingRight={3}>
          <Typography> Plot settings </Typography>
          <AxisDropDown
            title="y-axis"
            multiple
            default={defaultYAxis}
            options={props.data}
            onChange={value => setYAxis(value)}
          />
          <AxisDropDown
            title="x-axis"
            default={defaultXAxis[0]}
            options={defaultXAxis.concat(props.data)}
            onChange={value => setXAxis(value)}
          />
          <AxisDropDown
            title="plot type"
            default={defaultChartType}
            options={VALID_CHART_TYPES}
            onChange={value => setChartType(value)}
          />
        </Box>
      </Grid>
      <Grid item sm={10} style={{ height: '400px', marginBottom: '2rem' }}>
        <Chart
          options={{
            defaultColors,
            dark: theme.palette.background.paper !== 'rgb(255, 255, 255)',
            data: xAxis && yAxis ? data : [],
            primaryAxis: {
              getValue: (value: Point) => value.x,
              scaleType: xScaleType,
            },
            secondaryAxes: [
              {
                getValue: (value: Point) => value.y,
                scaleType: yScaleType,
                elementType: chartType as CHART_TYPES,
              },
            ],
          }}
        />
      </Grid>
    </Grid>
  ) : (
    <></>
  );
};
