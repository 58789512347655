import React, { useState } from 'react';
import { useApi } from '@backstage/core-plugin-api';
import { Content, useQueryParamState } from '@backstage/core-components';
import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  Chip,
  Grid,
  Tab,
  Tabs,
  Typography,
} from '@material-ui/core';
import { PluginTracking, SearchBar } from 'plugin-ui-components';
import { SearchResponse, SuggestionEntry } from '../api/definitions';
import { zflowApiRef } from '../api/zflowApiClient';
import {
  notebooksApiRef,
  NotebookSearchResponse,
  NotebookType,
} from 'plugin-ml-experimentation';
import {
  NotebookSuggestionEntries,
  SuggestionEntries,
} from '../constants/SearchConstants';
import { v4 as uuidv4 } from 'uuid';
import { Alert } from '@material-ui/lab';
import { PAGE_SIZE } from '../constants/PagingConstants';
import { useAsync, useDebounce } from 'react-use';
import {
  SEARCH_DEBOUNCE_TIME,
  SEARCH_TERM_MIN_LENGTH,
  TRACK_SEARCH_TERM_DEBOUNCE_TIME,
} from 'plugin-core';

const customDivStyle = {
  fontSize: 18,
  color: '#DE7C02',
};

export const MLPlatformSearchPage = () => {
  const [tab, setTab] = useState(0);
  const [suggestions, setSuggestions] = useState<SearchResponse>();
  const [notebookSuggestions, setNotebookSuggestions] =
    useState<NotebookSearchResponse>();

  const [searchTerm, setSearchTerm] = useQueryParamState<string>('term');
  const [keyword, setKeyword] = React.useState<string>(searchTerm || '');
  const zflowApi = useApi(zflowApiRef);
  const notebooksApi = useApi(notebooksApiRef);

  const { value: teamsWithProjects } = useAsync(() =>
    zflowApi.getTeamsWithProject(),
  );

  const handleChange = (value: string) => {
    setKeyword(value);
    // Prevent from triggering the words that don't meet the required length
    if (value && value.length >= SEARCH_TERM_MIN_LENGTH) {
      setSearchTerm(value);
    } else {
      setSearchTerm('');
      setSuggestions(undefined);
      setNotebookSuggestions(undefined);
    }
  };

  const search = () => {
    if (searchTerm) {
      zflowApi.search(searchTerm).then(setSuggestions);
      notebooksApi
        .search(searchTerm, Math.ceil(PAGE_SIZE / 3))
        .then(setNotebookSuggestions);
    }
  };

  useDebounce(
    () => {
      search();
    },
    SEARCH_DEBOUNCE_TIME,
    [searchTerm],
  );

  useDebounce(
    () => {
      if (searchTerm) PluginTracking.sendSearchQuery(searchTerm);
    },
    TRACK_SEARCH_TERM_DEBOUNCE_TIME,
    [search],
  );

  function setLabel(entry: SuggestionEntry) {
    let searchCount = 0;
    if (suggestions) {
      searchCount = suggestions[entry.field].length;
    }
    return `${entry.title} (${searchCount})`;
  }

  function getEntityID(entry: SuggestionEntry, item: any) {
    switch (entry.field) {
      case 'runs': {
        return item.run_id;
      }
      case 'ml_models': {
        return item.model_id;
      }
      case 'ml_model_groups': {
        return item.model_group_id;
      }
      case 'model_cards': {
        return item.model_card_id;
      }
      default: {
        return item.id;
      }
    }
  }

  function getEntityLink(entry: SuggestionEntry, item: any) {
    const entityID = getEntityID(entry, item);

    const projectPath = `projects/${item.project_id || item.id}`;
    const modelGroupPath = `${projectPath}/model-groups`;
    const pipelinePath = `${projectPath}/pipelines`;
    const experimentPath = `${projectPath}/notebooks/experiment_notebooks`;

    switch (entry.field) {
      case 'runs':
        return `${pipelinePath}/${item.pipeline_id}/runs/${entityID}`;
      case 'ml_models': {
        return `${modelGroupPath}/${item.model_group_id}/models/${entityID}`;
      }
      case 'ml_model_groups': {
        return `${modelGroupPath}/${item.model_group_id}`;
      }
      case 'model_cards': {
        return `${projectPath}/model-cards/${entityID}`;
      }
      case 'pipelines': {
        return `${pipelinePath}/${item.id}`;
      }
      case 'experiments': {
        return `${experimentPath}/${item.id}/zflow_experiments`;
      }
      default: {
        return projectPath;
      }
    }
  }

  function getEntityName(entry: SuggestionEntry, item: any) {
    return entry.field === 'model_cards' ? item.model_name : item.name;
  }

  function getTeamName(item: any) {
    const teamInfo = teamsWithProjects?.find(
      teamWithProject => teamWithProject.team_id === item.team_id,
    );

    return teamInfo ? teamInfo.name : item.team_id;
  }

  function renderSuggestions(entry: SuggestionEntry) {
    if (suggestions) {
      return suggestions[entry.field].map((item: any) => {
        return (
          <Grid container direction="column" spacing={5}>
            <Grid item xl={12} sm={12} xs={12}>
              <Card>
                <CardActionArea href={getEntityLink(entry, item)}>
                  <CardContent>
                    {entry.field === 'projects' && (
                      <Chip
                        variant="outlined"
                        color="primary"
                        label={getTeamName(item)}
                        clickable
                      />
                    )}
                    <Typography gutterBottom variant="subtitle2">
                      {getEntityName(entry, item)}
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
          </Grid>
        );
      });
    }

    return [];
  }

  const getNotebookEntityLink = (notebookType: NotebookType, item: any) => {
    const projectId = item?.project_id;
    const notebookId = item?.id;
    const urlPrefix = `/ml/projects/${projectId}/notebooks`;
    if (notebookType === NotebookType.SharedNotebook) {
      return `${urlPrefix}/shared_notebooks/${notebookId}/versions`;
    }
    if (notebookType === NotebookType.ExperimentNotebook) {
      return `${urlPrefix}/experiment_notebooks/${notebookId}/experiments`;
    }
    return `${urlPrefix}/scheduled_notebooks/${notebookId}/runs`;
  };

  const setNotebookLabel = (entry: SuggestionEntry) => {
    let searchCount = 0;
    if (notebookSuggestions) {
      searchCount =
        notebookSuggestions.experiment_notebooks.length +
        notebookSuggestions.shared_notebooks.length +
        notebookSuggestions.scheduled_notebooks.length;
    }
    if (notebookSuggestions && searchCount > PAGE_SIZE) {
      searchCount = PAGE_SIZE;
      setNotebookSuggestions({
        scheduled_notebooks: notebookSuggestions.scheduled_notebooks,
        shared_notebooks: notebookSuggestions.shared_notebooks.slice(
          0,
          Math.ceil(PAGE_SIZE / 3) * 3 - PAGE_SIZE >= 2 ? -1 : undefined,
        ),
        experiment_notebooks: notebookSuggestions.experiment_notebooks.slice(
          0,
          Math.ceil(PAGE_SIZE / 3) * 3 - PAGE_SIZE >= 1 ? -1 : undefined,
        ),
      });
    }

    return `${entry.title} (${searchCount})`;
  };

  const renderNotebookBlock = (notebookType: NotebookType, item: any) => {
    return (
      <Grid container direction="column" spacing={5}>
        <Grid item xl={12} sm={12} xs={12}>
          <Card>
            <CardActionArea href={getNotebookEntityLink(notebookType, item)}>
              <CardContent>
                <Typography gutterBottom variant="subtitle2">
                  {item?.name}
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid>
      </Grid>
    );
  };

  const getNotebookGroups = (notebookType: NotebookType) => {
    if (notebookType === NotebookType.ScheduledNotebook) {
      return {
        groupName: 'Scheduled Notebooks',
        notebooks: notebookSuggestions?.scheduled_notebooks,
      };
    } else if (notebookType === NotebookType.SharedNotebook) {
      return {
        groupName: 'Shared Notebooks',
        notebooks: notebookSuggestions?.shared_notebooks,
      };
    }
    return {
      groupName: 'Experiment Notebooks',
      notebooks: notebookSuggestions?.experiment_notebooks,
    };
  };

  const renderNotebookGroups = (notebookType: NotebookType) => {
    const group = getNotebookGroups(notebookType);
    if (group.notebooks?.length) {
      return [
        <Grid container direction="column" spacing={10}>
          <Grid item xl={12} sm={12} xs={12}>
            <div style={customDivStyle}>{group.groupName}</div>
          </Grid>
        </Grid>,
        ...group.notebooks.map((item: any) => {
          return renderNotebookBlock(notebookType, item);
        }),
      ];
    }
    return [];
  };

  const renderNotebookSuggestions = () => {
    if (notebookSuggestions) {
      return [
        ...renderNotebookGroups(NotebookType.ScheduledNotebook),
        ...renderNotebookGroups(NotebookType.SharedNotebook),
        ...renderNotebookGroups(NotebookType.ExperimentNotebook),
      ];
    }
    return [];
  };

  const zflowApiTabs = SuggestionEntries.map(entry => ({
    label: setLabel(entry),
    content: renderSuggestions(entry),
  }));

  const tabs = [
    ...zflowApiTabs.slice(0, -1),
    ...NotebookSuggestionEntries.map(entry => ({
      label: setNotebookLabel(entry),
      content: renderNotebookSuggestions(),
    })),
    ...zflowApiTabs.slice(-1),
  ];

  return (
    <Grid item xs>
      <SearchBar
        fullWidth
        value={keyword}
        placeholder="Search for projects, pipelines, runs, model cards, models, notebooks or experiments"
        onChange={ev => handleChange(ev.target.value)}
        /* eslint-disable-next-line jsx-a11y/no-autofocus */
        autoFocus
      />
      <Content>
        <Tabs
          key={uuidv4()}
          value={tab}
          onChange={(_, val: number) => setTab(val)}
          variant="scrollable"
          style={{ backgroundColor: 'var(--paper-bg)' }}
        >
          {tabs.map((t, i) => (
            <Tab key={i} label={t.label} value={i} style={{ height: 64 }} />
          ))}
        </Tabs>
        <Box role="tabpanel" p={3}>
          {tabs[tab]?.content}
        </Box>
        {!suggestions && (
          <Alert variant="outlined" severity="info">
            Only returns the first {PAGE_SIZE} results.
          </Alert>
        )}
      </Content>
    </Grid>
  );
};
