import React from 'react';

const GitHubIssueIcon: React.FC<JSX.IntrinsicElements['svg']> = props => (
  <svg
    fill="#000000"
    width="1em"
    height="1em"
    viewBox="0 0 17 17"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3Z" />
    <path d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0ZM1.5 8a6.5 6.5 0 1 0 13 0 6.5 6.5 0 0 0-13 0Z" />
  </svg>
);

export default GitHubIssueIcon;
