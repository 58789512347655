import { IconButton, TableRow } from '@material-ui/core';
import React from 'react';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import LaunchIcon from '@material-ui/icons/Launch';
import { EventType, isNakadiSQLAdminOf } from '../../domain/EventTypes';
import { StyledTableCell } from '../Generic';
import { HStack, TrackedLink } from 'plugin-ui-components';
import { EventTypeStats } from '../EventTypeStats';
import { DetailedPublishingStats } from '../EventTypeStats/EventTypeStats';
import { useApiCall } from '../../hooks/useApiCall';
import { EventTypesManager } from '../../api/EventTypesApi/EventTypesApi';
import { UnPromisify } from '../../utils/typeUtils';
import { eventTypesApiRef } from '../../api/EventTypesApi';

export const EventTypeView = ({
  eventType,
  nakadiUiURL,
  application,
}: {
  eventType: EventType;
  nakadiUiURL: string;
  application: string;
}) => {
  const [open, setOpen] = React.useState(false);
  const publishingStats = useApiCall<
    EventTypesManager,
    UnPromisify<ReturnType<EventTypesManager['getPublishingStats']>>
  >(eventTypesApiRef, api => api.getPublishingStats(eventType));

  const attributes = [];
  if (eventType?.cleanup_policy === 'compact') {
    attributes.push('compact');
  }
  if (eventType && isNakadiSQLAdminOf(eventType)) {
    attributes.push('nsql query');
  }

  return (
    <>
      <TableRow
        key={eventType.name}
        data-testid={`event-type-container-${eventType.name}`}
      >
        <StyledTableCell component="th" scope="row">
          <HStack alignItems="center" spacing="0.5rem">
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
            <TrackedLink
              key={eventType.name}
              plugin="nakadi"
              eventCategory="Event Type List"
              eventAction="Event type redirect to Nakadi UI"
              eventLabel={eventType.name}
              href={`${nakadiUiURL}/#types/${eventType.name}`}
              target="_blank"
              style={{ display: 'flex' }}
            >
              <span>
                {eventType.name +
                  (!!attributes.length ? ` (${attributes.join(', ')})` : '')}
              </span>
              <LaunchIcon style={{ fontSize: '1em', marginLeft: '0.5rem' }} />
            </TrackedLink>
          </HStack>
        </StyledTableCell>
        <EventTypeStats
          publishingStats={publishingStats}
          eventType={eventType}
          application={application}
          nakadiUiURL={nakadiUiURL}
        />
      </TableRow>
      <DetailedPublishingStats
        isTableOpen={open}
        publishingStats={publishingStats}
      />
    </>
  );
};
