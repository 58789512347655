import React, { useContext } from 'react';
import { CardContent, Chip, Divider, Grid } from '@material-ui/core';
import { Link } from '@backstage/core-components';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import { Alert } from '@material-ui/lab';
import {
  isCommentsField,
  isConfirmationField,
  isURLField,
} from '../../utils/fields';
import { config } from '../config';
import { ReviewSummaryContext } from '../context';
import * as S from '../styles';
import { createGrafanaApplicationDashboardUrlWithParameters } from './utils';
import { GRAFANA_DASHBOARDS } from './Dashboards';

export const DashboardsSummary = () => {
  const okKey: IAppReview.ReviewModelField = 'dashboards_ok';
  const commentsKey: IAppReview.ReviewModelField = 'dashboards_comments';
  const category: IAppReview.ReviewCategory = 'Dashboards';
  const { review } = useContext(ReviewSummaryContext);

  const categoryItems = Object.keys(review).filter(
    item =>
      config[item]?.category === category &&
      !isURLField(item) &&
      !isConfirmationField(item) &&
      !isCommentsField(item),
  );

  // Add "cluster=*" to the Grafana dashboards url if cluster info is missing,
  // otherwise Grafana will use a default cluster (checkout) so dashboard will not work as expected
  GRAFANA_DASHBOARDS.forEach(item => {
    if (categoryItems.includes(item)) {
      review[`${item}_url`] =
        createGrafanaApplicationDashboardUrlWithParameters(
          review[`${item}_url`] as string,
        );
    }
  });

  return (
    <>
      {!!categoryItems.length && (
        <S.StyledCard>
          <S.StyledCardHeader
            title={
              <div>
                <span>{category}</span>
                {review[okKey] === 'n/a' && review[commentsKey] && (
                  <Chip
                    size="small"
                    style={{ margin: '0 0.5rem' }}
                    label="Not applicable"
                  />
                )}
              </div>
            }
            titleTypographyProps={{ component: 'h2' } as any}
          />
          <Divider />
          {review[commentsKey] && (
            <Alert severity="info" style={{ margin: 0, borderRadius: 0 }}>
              <b>Rationale provided:</b> {review[commentsKey]}
            </Alert>
          )}
          {categoryItems.map(item => (
            <CardContent key={item}>
              <Grid container>
                <Grid item xs={10}>
                  <S.Label>{config[item]?.label}</S.Label>
                </Grid>
                <Grid item xs={2}>
                  <S.Label align="right" style={{ fontWeight: 'bold' }}>
                    {review[item] === 'no' ? (
                      'No'
                    ) : (
                      <Link to={review[`${item}_url`]}>
                        View <OpenInNewIcon />
                      </Link>
                    )}
                  </S.Label>
                </Grid>
              </Grid>
            </CardContent>
          ))}
        </S.StyledCard>
      )}
    </>
  );
};
