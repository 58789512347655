import React from 'react';
import { CircularProgress, Tooltip } from '@material-ui/core';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import { useStyles } from './style';
import classNames from 'classnames';

interface Props {
  value?: string;
  loading: boolean;
  error?: Error;
  defaultValue: string;
  active?: boolean;
}

export function ChipLabel({
  value,
  defaultValue,
  error,
  loading,
  active = true,
}: Props) {
  const styles = useStyles();
  if (loading)
    return (
      <div>
        {value || defaultValue}&nbsp;
        <CircularProgress size={12} />
      </div>
    );

  if (error) {
    const reason = error ? error.message || String(error) : 'Unknown';
    return (
      <span className={styles.chipLabel}>
        {defaultValue}
        &nbsp;
        <Tooltip
          title={
            <>
              Error! Could not fetch user details.
              <br />
              Reason: {reason}
            </>
          }
        >
          <ErrorOutlineIcon className="error-icon" color="error" />
        </Tooltip>
      </span>
    );
  }

  return (
    <span
      className={`${styles.userName} ${classNames({
        active,
        inactive: !active,
      })}`}
    >
      {value || defaultValue}
    </span>
  );
}
