import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  root: {
    display: 'inline-flex',
    gap: '0.25em',

    '&:hover .copy-button': {
      opacity: 1,
    },

    '& .copy-button': {
      '--copy-btn-size': '32px',
      opacity: 0,
      display: 'flex',
      flexShrink: 1,
      width: 'var(--copy-btn-size)',
      height: 'var(--copy-btn-size)',

      '& button': {
        width: 'var(--copy-btn-size)',
        height: 'var(--copy-btn-size)',
      },

      '&:hover': {
        color: theme.palette.primary.main,
      },

      '& svg': {
        fontSize: theme.typography.body2.fontSize,
      },
    },
  },
}));
