import { ProjectForm } from '../hooks/useProjectForm';

export interface ZflowApi {
  // Projects
  createProject(form: ProjectForm): Promise<CreateMLProjectResponse>;

  updateProject(
    projectId: string,
    form: ProjectForm,
  ): Promise<UpdateMLProjectResponse>;

  archiveProject(projectId: string): Promise<string>;

  getProject(projectId: string): Promise<MLProject>;

  getProjects(
    limit: number,
    teamId: string,
    offset?: number,
  ): Promise<GetMLProjectsResponse>;

  getUserInfo(): Promise<ZflowUserInfo>;

  getTeamsWithProject(): Promise<Array<UserTeam>>;

  // Pipelines
  getPipelines(
    projectId: string,
    limit: number,
    deployment_environment?: string,
    offset?: number,
  ): Promise<GetMLPipelinesResponse>;

  // Experiment Pipelines
  getExperimentPipelines(
    projectId: string,
    limit: number,
    deployment_environment?: string,
    offset?: number,
    archived?: boolean,
  ): Promise<GetMLPipelinesResponse>;

  archiveExperimentPipeline(pipelineId: string): Promise<string>;

  archiveExperimentRun(runId: string): Promise<string>;

  getPipeline(pipelineId: string): Promise<MLPipeline>;

  getPipelineRuns(
    pipelineId: string,
    limit: number,
    offset?: number,
  ): Promise<GetPipelineRunsResponse>;

  getModelMetrics(
    runId: string,
    limit: number,
    trainingJobIds?: string[] | undefined,
    key?: string,
    offset?: number,
  ): Promise<GetModelMetrics>;

  getPipelineRun(runId: string): Promise<MLPipelineRun>;

  startRun(
    projectId: string,
    pipelineId: string,
    runName: string,
    status: string,
    runInput: string | null,
  ): Promise<StartNewRunResponse>;

  abortRun(runId: string): Promise<AbortRunResponse>;

  // Models
  getModelGroups(
    projectId: string,
    limit: number,
    offset?: number,
    deployment_environment?: string,
  ): Promise<GetModelGroupsResponse>;

  getModelGroup(modelGroupId: string): Promise<ModelGroup>;

  getModels(
    modelGroupId: string,
    limit: number,
    offset?: number,
  ): Promise<GetModelsResponse>;

  getModelsByRunId(runId: string): Promise<GetModelsResponse>;

  getModel(modelId: string): Promise<MLModel>;

  approveModel(modelId: string, comments: string): Promise<string>;
  rejectModel(modelId: string, comments: string): Promise<string>;
  patchModel(modelId: string, payload: any): Promise<string>;

  // Search
  search(query: string): Promise<SearchResponse>;

  getArtifactDetails(artifactId: string): Promise<ArtifactDetails>;
}

export interface ArtifactDetails {
  artifact_id: string;
  created_at: string;
  created_by: string;
  name: string;
  run_id: string;
  type: string;
  artifact: string;
}

export interface Artifact {
  id: string;
  type: string;
}

export interface SearchResponse {
  model_cards: Array<object>;
  pipelines: Array<MLPipeline>;
  projects: Array<MLProject>;
  runs: Array<object>;
  ml_models: Array<MLModel>;
  ml_model_groups: Array<ModelGroup>;
  [index: string]: any;
}

// Projects
export interface GetProjectsParams {
  limit: number;
  offset?: number;
  team_id?: string;
}

export interface UpdateMLProjectResponse {
  project_id: string;
}

export interface GetMLProjectsResponse {
  meta: Meta;
  projects: Array<MLProject>;
}

export interface CreateMLProjectResponse {
  project_id: string;
  status: string | null;
}

export interface UpdateMLProjectResponse {
  project_id: string;
}

export interface MLProject {
  created_at: string;
  created_by: string;
  description: string;
  id: string;
  impacted_kpis: Array<string>;
  name: string;
  team: UserTeam;
  team_id: string;
  archived: boolean;
  archived_by: string;
  last_updated_at: string;
  last_updated_by: string;
  active?: boolean;
}

export interface PatchBody {
  op: string;
  path: string;
  value?: object | ProjectForm;
}

// Pipelines
export interface GetPipelinesParams {
  project_id: string;
  limit: number;
  archived: boolean;
  offset?: number;
  deployment_environment?: string;
  tags?: string;
}

export interface GetPipelineRunsParams {
  pipeline_id: string;
  limit: number;
  offset?: number;
  archived: boolean;
}

export interface StartNewRunParams {
  project_id: string;
  pipeline_id: string;
  run_name: string;
  status: string;
  should_execute_pipeline: boolean;
  execution_input?: string | null;
}

export interface StartNewRunResponse {
  run_id: string;
  status: string;
}

export interface AbortRunResponse {
  run_id: string;
  status: RunStatus;
}

export interface MLPipeline {
  project_id: string;
  created_at: string;
  created_by: string;
  deployment_environment: string;
  id: string;
  name: string;
  archived: boolean;
  last_run?: MLPipelineRun;
  runs?: MLPipelineRun[];
  metadata?: MLPipelineRunMetadata | null;
}

export interface GetMLPipelinesResponse {
  meta: Meta;
  pipelines: Array<MLPipeline>;
}

export interface GetPipelineRunsResponse {
  meta: PagingMeta;
  runs: Array<MLPipelineRun>;
}

export interface GetModelMetrics {
  meta: PagingMeta;
  metrics: Array<MLPipelineRunMetricsParams>;
}

export interface MLPipelineRun {
  archived: boolean;
  created_at: string;
  created_by: string;
  last_modified_at: string;
  metadata: MLPipelineRunMetadata | null;
  metrics: Array<MLPipelineRunMetricsParams>;
  name: string;
  params: Array<MLPipelineRunMetricsParams>;
  pipeline_id: string;
  project_id: string;
  run_id: string;
  status: RunStatus;
  steps: Array<MLPipelineRunStep>;
  artifacts?: Array<Artifact>;
}

export interface MLPipelineRunStepDetails {
  error?: string;
  cause?: string;
  input: { [key: string]: any };
  output: any;
  TrainingJobName?: string;
  TransformJobName?: string;
  ProcessingJobName?: string;
}

export interface MLPipelineRunStep {
  step_id: string;
  name: string;
  run_id: string;
  project_id: string;
  step_type: string;
  details: MLPipelineRunStepDetails;
  status: RunStatus;
  next_step_ids: string[];
  pipeline_id: string;
  started_at: string;
  updated_at: string;
}

export enum RunStatus {
  Initialized = 'INITIALIZED',
  Running = 'RUNNING',
  Failed = 'FAILED',
  Succeeded = 'SUCCEEDED',
  TimedOut = 'TIMED_OUT',
  Aborted = 'ABORTED',
  NotStarted = 'NOT_STARTED',
  Unknown = 'UNKNOWN',
}

export enum RunStepStatus {
  SUCCESS = 'SUCCESS',
}

export interface MLPipelineRunMetadata {
  ZFLOW_CDP_ACCOUNTABLE_USER: string;
  ZFLOW_CDP_BUILD_VERSION: string;
  ZFLOW_CDP_CONFIGURATION_SOURCE: string;
  ZFLOW_PROJECT_ID: string;
  ZFLOW_VERSION: string;
  [index: string]: any;
}

export interface MLModelMetricConfidenceInterval {
  lower_bound?: string;
  upper_bound?: string;
}

export interface MLPipelineRunMetricsParams {
  created_at: string;
  key: string;
  metric_id: string;
  run_id: string;
  value: number;
  type?: string;
  training_job_id?: string;
  confidence_interval?: MLModelMetricConfidenceInterval;
  threshold?: number;
  is_average?: boolean;
}

export interface UserTeam {
  id: string;
  id_name: string;
  name: string;
  nickname: string;
  type: string;
  team_id: string;
}

export interface ZflowUserInfo {
  can_approve_models: boolean;
  user_id: string;
  teams: UserTeam[];
}

declare interface Meta {
  total: number | null;
  deployment_environments?: Array<DeploymentEnvironment>;
}

export interface DeploymentEnvironment {
  id: string;
  name: string;
}

export interface SuggestionEntry {
  field: string;
  title: string;
  path: string;
}

// Models
export interface GetModelGroupsParams {
  project_id: string;
  limit: number;
  offset?: number;
  deployment_environment?: string;
}

export interface GetModelGroupsResponse {
  meta: Meta;
  model_groups: Array<ModelGroup>;
}

export interface GetModelMetricsParams {
  run_id: string;
  limit: number;
  offset?: number;
  training_job_ids?: string;
  key?: string;
}

export interface GetModelsParams {
  model_group_id: string;
  limit: number;
  offset?: number;
}

export interface GetModelsResponse {
  meta: PagingMeta;
  models: Array<MLModel>;
}

export interface ModelGroup {
  created_at: string;
  deployment_environment: string;
  description: string;
  model_group_id: string;
  name: string;
  project_id: string;
  tags: ModelGroupTags;
}

export interface ModelGroupTags {
  [key: string]: any;
}

export interface MLModelTags {
  ZFLOW_MODEL_TAG: string;
  MODEL_NAME: string;
  ZFLOW_PIPELINE_NAME: string;
  ZFLOW_TRAINING_JOB_IDS?: string[];
  [key: string]: any;
}

export enum MLModelContainerMode {
  SINGLE_MODEL = 'SingleModel',
  MULTI_MODEL = 'MultiModel',
}

export interface MLModelContainer {
  ecr_image_uri: string;
  mode: MLModelContainerMode;
  model_artifact_location?: string;
  training_job_id?: string;
}

export interface MLModelOwners {
  name?: string;
  contact?: string;
}

interface MLModelGraphicCollection {
  name?: string;
  image?: string;
}

export interface MLModelGraphics {
  description: string;
  collection: MLModelGraphicCollection[];
}

export interface MLModelDataset {
  name?: string;
  link?: string;
  sensitive?: boolean;
  graphics?: MLModelGraphics;
}

export interface MLModelData {
  train: MLModelDataset;
  eval: MLModelDataset;
}

export interface MLModelParameters {
  model_architecture?: string;
  data: MLModelData;
  input_format?: string;
  output_format?: string;
}

export interface MLModelQuantitativeAnalysis {
  performance_metrics: MLPipelineRunMetricsParams[] | null;
  graphics: MLModelGraphics;
}

export interface MLModelEthicalConsiderations {
  name?: string;
  mitigation_strategy?: string;
}

interface MLModelConsiderations {
  users?: string[];
  use_cases?: string[];
  limitations?: string[];
  tradeoffs?: string[];
  ethical_considerations?: MLModelEthicalConsiderations[];
}

export interface MLModel {
  approval: ModelApproval | null;
  created_at: string | null;
  created_by: string | null;
  deployment: ModelDeployment | null;
  containers: MLModelContainer[];
  model_group_id: string;
  model_id: string;
  name: string;
  pipeline_id: string;
  project_id: string;
  run_id: string;
  step_id: string;
  tags: MLModelTags;
  updated_at: string | null;
  updated_by: string | null;
  citation: string | null;
  license: string | null;
  references: string[] | null;
  overview: string | null;
  owners: MLModelOwners[] | null;
  deployment_environment: string;
  model_parameters: MLModelParameters | null;
  quantitative_analysis: MLModelQuantitativeAnalysis | null;
  considerations: MLModelConsiderations | null;
  version_id: string;
}

export interface ModelApproval {
  approval_status: string;
  comments: string | null;
  model_id: string;
  approved_at: string | null;
  approved_by: string | null;
}

export interface ModelDeployment {
  deployed_at: string | null;
  deployed_by: string | null;
  is_deployed: boolean;
  model_id: string;
}

// Paging
declare interface PagingMeta {
  total: number;
}

export interface MLResourceNotFound {
  name: string;
  id: string;
}

export enum HTTPMethod {
  GET = 'GET',
  POST = 'POST',
  PATCH = 'PATCH',
  PUT = 'PUT',
}

export class ExperimentArchiveError implements Error {
  constructor(public name: string, public message: string) {
    this.name = name;
    this.message = message;
  }
  toString() {
    return `${this.name}: ${this.message}`;
  }
}
