import React from 'react';
import Markdown from 'markdown-to-jsx';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Tooltip from '@mui/material/Tooltip';
import { Link, TableColumn } from '@backstage/core-components';
import InfoOutlined from '@material-ui/icons/InfoOutlined';
import FiberManualRecord from '@material-ui/icons/FiberManualRecord';
import { collectToer, entitySort, isCyberWeekRelatedTag } from 'plugin-core';
import { CriticalityChip } from '../CriticalityChip';
import { FavouriteEntity } from '../FavouriteEntity/FavouriteEntity';
import {
  clickDataClassification,
  DataClassificationChip,
} from '../DataClassificationChip';
import { EventTracker } from '../../Tracking';
import { ReviewChip } from '../../ReviewChip';
import { TeamReference } from '../../TeamReference';
import { ToerChip } from '../../ToerChip';
import { ScorecardChip } from 'plugin-scorecards';
import { HStack } from '../../Stacks';
import { FeatureFlagIcon } from '../../FeatureFlagRibbon';
import { OverflowTooltip } from '../../OverflowTooltip';
import { Entity } from '@backstage/catalog-model';
import IconButton from '@mui/material/IconButton';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { DocCategoryChip } from 'plugin-ui-components';

type ApplicationColumnNames =
  | 'name'
  | 'owner'
  | 'criticality'
  | 'description'
  | 'tags'
  | 'review'
  | 'apec'
  | 'prr'
  | 'star'
  | 'scorecards';

interface ScorecardColumnConfig {
  scorecardsList: IEntityScorecard[];
}

export function createNameDescriptionColumn(
  options: { to?: (entity: Entity) => string } = {},
): TableColumn<ICatalog.EntityRow> {
  return {
    title: 'Name/Description',
    field: 'resolved.name',
    render: ({ entity }) => {
      const isActive = (entity as IEntityApp).spec?.spec?.active;
      const {
        kind = 'Component',
        metadata: { namespace = 'default', name },
      } = entity;
      return (
        <Box width="300px" display="flex" flexDirection="column" gap="0.5rem">
          <Box display="flex" alignItems="center">
            {isActive !== undefined && (
              <Tooltip title={isActive ? 'active' : 'inactive'}>
                <FiberManualRecord
                  className="dot-icon"
                  data-active={String(isActive)}
                />
              </Tooltip>
            )}
            <Link
              to={
                options.to
                  ? options.to(entity)
                  : `/catalog/${namespace}/${kind}/${name}`
              }
            >
              <strong>{name}</strong>
            </Link>
          </Box>
          <OverflowTooltip
            text={entity.metadata.description}
            title={<Markdown>{entity.metadata.description || ''}</Markdown>}
            placement="bottom-start"
            TypographyProps={{
              variant: 'body2',
            }}
            fullWidth
          />
        </Box>
      );
    },
  };
}

export function createNameColumn(): TableColumn<ICatalog.EntityRow> {
  return {
    title: 'Name',
    field: 'resolved.name',
    highlight: true,
    render: ({ entity }) => {
      const isActive = entity.spec?.spec?.active;
      const {
        kind = 'Component',
        metadata: { namespace = 'default', name },
      } = entity;
      return (
        <div>
          {isActive !== undefined && (
            <Tooltip title={isActive ? 'active' : 'inactive'}>
              <FiberManualRecord
                className="dot-icon"
                data-active={String(isActive)}
              />
            </Tooltip>
          )}
          <Link to={`/catalog/${namespace}/${kind}/${name}`}>{name}</Link>
        </div>
      );
    },
  };
}

export function createOwnerColumn(): TableColumn<ICatalog.EntityRow> {
  return {
    title: 'Owner',
    field: 'resolved.ownerTeamNameAndId',
    render: ({ entity }) => (
      <TeamReference team={entity.spec.owner} format="nameOnly" />
    ),
  };
}

export function createSpecTypeColumn(): TableColumn<ICatalog.EntityRow> {
  return {
    title: 'Type',
    field: 'entity.spec.type',
    width: 'auto',
  };
}

export function createLifecycleColumn(): TableColumn<ICatalog.EntityRow> {
  return {
    title: 'Lifecycle',
    field: 'entity.spec.lifecycle',
    width: 'auto',
    searchable: false,
  };
}

export function createCriticalityColumn(): TableColumn<ICatalog.EntityRow> {
  return {
    title: 'Criticality',
    field: 'entity.spec.lifecycle',
    render: ({ entity }) => {
      const classification = entity.metadata?.lastReview?.dataClassification;
      const {
        kind = 'Component',
        metadata: { namespace = 'default', name },
      } = entity;
      return (
        <div className="flex-wrapped">
          {entity.spec?.lifecycle && (
            <Chip
              className="info-chip"
              label={entity.spec.lifecycle}
              size="small"
              variant="outlined"
            />
          )}
          {entity.spec?.spec?.incident_contact && (
            <Tooltip title="Has 24/7 support">
              <Chip
                className="info-chip"
                label="24/7"
                size="small"
                variant="outlined"
              />
            </Tooltip>
          )}
          {classification && (
            <EventTracker {...clickDataClassification()}>
              <Link
                to={`/catalog/${namespace}/${kind}/${name}#application-review`}
                color="textPrimary"
              >
                <DataClassificationChip entity={entity} clickable />
              </Link>
            </EventTracker>
          )}
        </div>
      );
    },
  };
}

export function createMetadataDescriptionColumn(
  order: 'asc' | 'desc' | '' = 'asc',
): TableColumn<ICatalog.EntityRow> {
  return {
    title: 'Description',
    field: 'entity.metadata.description',
    customSort: (a, b) =>
      entitySort(a.entity, b.entity, 'metadata.description', order),
    render: ({ entity }) => (
      <OverflowTooltip
        text={entity.metadata.description}
        title={<Markdown>{entity.metadata.description || ''}</Markdown>}
        placement="bottom-start"
        TypographyProps={{
          variant: 'body2',
        }}
        fullWidth
      />
    ),
    hidden: true,
    searchable: true,
  };
}

const getApiSummary = (apiSummary: string | undefined) => {
  return apiSummary
    ? apiSummary
    : `Unfortunately we aren't able to parse the description of your API.`;
};

export function createMetadataSummaryColumn(): TableColumn<ICatalog.EntityRow> {
  return {
    title: 'Summary',
    field: 'entity.metadata.summary',
    render: ({ entity }) => (
      <Tooltip
        title={
          <Markdown>
            {getApiSummary(entity.metadata?.summary as string)}
          </Markdown>
        }
        placement="bottom-start"
      >
        <span>{getApiSummary(entity.metadata?.summary as string)}</span>
      </Tooltip>
    ),
    searchable: true,
  };
}

export function createTagsColumn(): TableColumn<ICatalog.EntityRow> {
  return {
    title: 'Cyber Week',
    field: 'entity.metadata.cyberweekInScope',
    render: ({ entity }) => {
      const toer = collectToer(entity);

      return (
        <div className="tag-wrapper">
          {entity.metadata.cyberweekInScope && <ToerChip toer={toer} />}
          {entity.metadata.cyberweekInScope && (
            <Tooltip title="Relevant for cyberweek">
              <Chip
                className="info-chip"
                label="relevant"
                size="small"
                variant="outlined"
              />
            </Tooltip>
          )}
          {entity.metadata.tags &&
            entity.metadata.tags
              .filter(v => isCyberWeekRelatedTag(v))
              .map(t => (
                <Chip
                  className="info-chip"
                  key={t}
                  label={t}
                  size="small"
                  variant="outlined"
                />
              ))}
        </div>
      );
    },
  };
}

export function createReviewColumn(): TableColumn<ICatalog.EntityRow> {
  return {
    title: 'Review status',
    field: 'resolved.reviewStatus',
    render: ({ entity }) => {
      if (entity.spec?.type && entity.spec?.type === 'service') {
        return <ReviewChip entity={entity} />;
      }
      return undefined;
    },
  };
}

export function createApecColumn(
  order: 'asc' | 'desc' | '' = 'asc',
): TableColumn<ICatalog.EntityRow> {
  return {
    title: (
      <Tooltip title="Application Production Engineering Checklist">
        <span>
          APEC <InfoOutlined className="header-info" />
        </span>
      </Tooltip>
    ),
    field: 'entity.metadata.apecStatus.status',
    customSort: (a, b) => {
      const metadata1 = a.entity.metadata;
      const metadata2 = b.entity.metadata;
      const s1 = metadata1.apecStatus?.status;
      const s2 = metadata2.apecStatus?.status;
      const [first, second] = order === 'asc' ? [s1, s2] : [s2, s1];
      switch (true) {
        case first === second:
          return 0;
        case first === '':
          return 1;
        case second === '':
          return -1;

        case first === 'red':
        case first === 'amber' && !['red'].includes(second):
        case first === 'green' && !['red', 'amber'].includes(second):
        case first === 'notrelevant' &&
          !['red', 'amber', 'green'].includes(second):
          return order === 'asc' ? -1 : 1;
        default:
          return order === 'asc' ? 1 : -1;
      }
    },
    render: ({ entity }) => {
      const metadata = entity.metadata;
      return (
        <div className="flex-wrapped">
          {metadata.apecStatus?.status && (
            <CriticalityChip
              size="small"
              variant="outlined"
              status={metadata.apecStatus.status}
              reasons={metadata.apecStatus.reasons}
            />
          )}
        </div>
      );
    },
  };
}

export function createPrrColumn(): TableColumn<ICatalog.EntityRow> {
  return {
    title: (
      <Tooltip title="Production Readiness Review Completion">
        <span>
          PRR <InfoOutlined className="header-info" />
        </span>
      </Tooltip>
    ),
    field: 'entity.metadata.productionReadinessReviewDate',
    customSort: (a, b) => {
      const metadata1 = a.entity.metadata;
      const metadata2 = b.entity.metadata;
      const prr1 = metadata1.productionReadinessReviewDate;
      const prr2 = metadata2.productionReadinessReviewDate;
      const d1 = new Date(metadata1.productionReadinessReviewDate);
      const d2 = new Date(metadata2.productionReadinessReviewDate);

      if (!isNaN(d2.getTime())) {
        if (isNaN(d1.getTime())) {
          return -1;
        }
        return Math.sign(d1.getTime() - d2.getTime());
      }

      if (prr2 && !prr1) {
        return -1;
      }

      return 0;
    },

    render: ({ entity }) => {
      return (
        <div className="flex-wrapped">
          {entity.metadata.productionReadinessReviewDate && (
            <Tooltip title="Production Readiness Review">
              <Chip
                className="info-chip"
                label={
                  entity.metadata.productionReadinessReviewDate === 'never'
                    ? entity.metadata.productionReadinessReviewDate
                    : new Date(
                        entity.metadata.productionReadinessReviewDate,
                      ).toISODateString('short')
                }
                size="small"
                variant="outlined"
              />
            </Tooltip>
          )}
        </div>
      );
    },
  };
}

export function createActionsColumn(): TableColumn<ICatalog.EntityRow> {
  return {
    title: '',
    sorting: false,
    width: '0',
    render: ({ entity }) => {
      return (
        <Box display="flex" alignItems="center">
          <FavouriteEntity entity={entity} />
        </Box>
      );
    },
  };
}

export function createSystemColumn(): TableColumn<ICatalog.EntityRow> {
  return {
    title: 'System',
    field: 'resolved.partOfSystemRelationTitle',
    width: 'auto',
  };
}

function createHiddenSearchableColumn(field: string) {
  return {
    field,
    hidden: true,
    searchable: true,
  };
}

function createScorecardsColumn(
  scorecardColumn: ScorecardColumnConfig | undefined,
): TableColumn<ICatalog.EntityRow> {
  return {
    title: (
      <HStack alignItems="center" spacing=".2rem">
        <div>Scorecards</div>
        <FeatureFlagIcon
          style={{ fontSize: '1.3em', marginBottom: 4 }}
          name="tech-insights"
        />
      </HStack>
    ),
    render: ({ entity }) => {
      return (
        <ScorecardChip
          entity={entity}
          {...(scorecardColumn && {
            scorecardsToShow: scorecardColumn.scorecardsList,
          })}
        />
      );
    },
  };
}

function createDocLinksColumn(): TableColumn<ICatalog.EntityRow> {
  return {
    title: 'Helpful links',
    width: '0',
    render: ({ entity }) => {
      const {
        kind,
        metadata: { name, namespace, external },
      } = entity as any as IEntityDoc;

      return (
        <Box display="flex" alignItems="center">
          <Tooltip
            title={
              external
                ? 'There is not embedded view of this documentation'
                : 'Open embedded documentation'
            }
            arrow
            placement="top-end"
          >
            <Link to={external ? '#' : `/docs/${namespace}/${kind}/${name}`}>
              <IconButton size="small" disabled={external}>
                <MenuBookIcon style={{ fontSize: '1.5rem' }} />
              </IconButton>
            </Link>
          </Tooltip>
          <Tooltip
            title="Open external documentation page"
            arrow
            placement="top-end"
          >
            <Link to={`https://${name}.docs.zalando.net`}>
              <IconButton size="small">
                <OpenInNewIcon style={{ fontSize: '1.5rem' }} />
              </IconButton>
            </Link>
          </Tooltip>
          <Tooltip title="View metadata" arrow placement="top-end">
            <Link to={`/catalog/${namespace}/${kind}/${name}`}>
              <IconButton size="small">
                <VisibilityIcon style={{ fontSize: '1.5rem' }} />
              </IconButton>
            </Link>
          </Tooltip>
        </Box>
      );
    },
  };
}

export const createDocCategoryColumn = (): TableColumn<ICatalog.EntityRow> => {
  return {
    title: 'Category',
    field: 'entity.spec.category',
    render: ({ entity }) => {
      const { category } = entity.spec;
      if (!category || category === 'unknown') return <></>;
      return <DocCategoryChip category={category as string} />;
    },
  };
};

export const applicationColumns = (
  order: 'asc' | 'desc' | '' = 'asc',
  skip: Array<ApplicationColumnNames> = [],
  scorecardsColumn?: ScorecardColumnConfig,
) => {
  const columnMap = {
    name: createNameDescriptionColumn,
    owner: createOwnerColumn,
    criticality: createCriticalityColumn,
    description: () => createMetadataDescriptionColumn(order),
    tags: createTagsColumn,
    review: createReviewColumn,
    apec: () => createApecColumn(order),
    prr: createPrrColumn,
    star: createActionsColumn,
    scorecards: () => createScorecardsColumn(scorecardsColumn),
  };

  // Skip columns that are not needed
  const columns = Object.entries(columnMap)
    .filter(([key]) => !skip.includes(key as ApplicationColumnNames))
    .map(([, createColumn]) => createColumn());

  const additionalColumns = [
    createHiddenSearchableColumn('entity.spec.spec.name'),
    createHiddenSearchableColumn('entity.spec.spec.subtitle'),
  ];

  return [...columns, ...additionalColumns];
};

export const apiColumns: TableColumn<ICatalog.EntityRow>[] = [
  createNameColumn(),
  createOwnerColumn(),
  createSpecTypeColumn(),
  createLifecycleColumn(),
  createMetadataSummaryColumn(),
  createActionsColumn(),
];

export const docsColumns: TableColumn<ICatalog.EntityRow>[] = [
  createNameDescriptionColumn({
    to: entity => {
      const {
        kind,
        metadata: { name, namespace, external },
      } = entity as IEntityDoc;

      if (external) {
        return `https://${name}.docs.zalando.net`;
      }
      return `/docs/${namespace}/${kind}/${name}`;
    },
  }),
  createOwnerColumn(),
  createDocCategoryColumn(),
  createDocLinksColumn(),
  createActionsColumn(),
];

export const consumeColumns: TableColumn<ICatalog.EntityRow>[] = [
  createNameColumn(),
  createSystemColumn(),
  createOwnerColumn(),
  createSpecTypeColumn(),
  createLifecycleColumn(),
  createMetadataDescriptionColumn(),
];
