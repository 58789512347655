import React, { useCallback } from 'react';
import { Button, Tooltip, Grid, Typography } from '@material-ui/core';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { useApi } from '@backstage/core-plugin-api';
import {
  loadTestingApiRef,
  TestRun,
  TestState,
} from '../../api/LoadTestOrchestratorApi';
import { useStyles } from '../../StylingComponent/styles';
import { useConfirmationModal } from 'plugin-ui-components';

export interface StateControlButtonsProps {
  testRun: TestRun;
  scheduledFor: Date;
  refetch: () => void;
  showSnackbar: (message: string, severity: 'success' | 'error') => void;
}

export const StateControlButtons: React.FC<StateControlButtonsProps> = ({
  testRun,
  scheduledFor,
  refetch,
  showSnackbar,
}) => {
  const classes = useStyles();
  const loadTestingApi = useApi(loadTestingApiRef);
  const { openConfirmation, closeConfirmation, ConfirmationDialog } =
    useConfirmationModal();

  const updateTestRunState = useCallback(
    async (testRunId: string, scriptSlug: string, newState: TestState) => {
      try {
        await loadTestingApi.updateTestRunStateHandler(testRunId, newState);
        if (newState === 'stopped') {
          showSnackbar(
            `You stopped the test run ${scriptSlug} and cleaned all Kubernetes resources.`,
            'success',
          );
        } else {
          showSnackbar(
            `Updated state for test run ${scriptSlug} to ${newState}`,
            'success',
          );
        }
        refetch();
      } catch (error) {
        showSnackbar(
          `Failed to update state for test run ${scriptSlug} but resources should be deleted`,
          'error',
        );
      }
    },
    [loadTestingApi, refetch, showSnackbar],
  );

  const handleConfirmStop = useCallback(() => {
    updateTestRunState(testRun.id, testRun.script_slug, 'stopped');
    closeConfirmation();
  }, [testRun.id, testRun.script_slug, updateTestRunState, closeConfirmation]);

  const isStateChangeAllowed = (newState: TestState) => {
    const currentState = testRun.state_parameters?.state;
    switch (currentState) {
      case 'scheduled':
        return newState === 'stopped';
      case 'running':
        return newState === 'stopped';
      case 'stopped':
        return newState === 'stopped';
      default:
        return false;
    }
  };

  const getButtonProps = (newState: TestState) => ({
    disabled: !isStateChangeAllowed(newState),
  });

  const isWithinTwoDaysAfterScheduledDate = (date: Date) => {
    const scheduledDate = new Date(date);
    const now = new Date();
    const twoDaysInMilliseconds = 2 * 24 * 60 * 60 * 1000;
    return now.getTime() <= scheduledDate.getTime() + twoDaysInMilliseconds;
  };

  return (
    <Grid container className={classes.container} spacing={1}>
      <Tooltip title="Immediately stops the test by updating runtime VUs to 0 if not already at 0 and deleting relevant K8 resources!">
        <span>
          <Button
            variant="outlined"
            color="primary"
            className={classes.callToActionButton}
            {...getButtonProps('stopped')}
            onClick={openConfirmation}
            disabled={!isWithinTwoDaysAfterScheduledDate(scheduledFor)}
          >
            <WarningAmberIcon />
            Stop
          </Button>
        </span>
      </Tooltip>

      <ConfirmationDialog
        title="Confirm Stop of test run"
        onSave={handleConfirmStop}
        saveButtonText="Confirm"
      >
        <Typography>
          Are you sure you want to IMMEDIATELY stop the test run{' '}
          {testRun.script_slug}? This action will update runtime VUs to 0, if
          not already at 0 and delete relevant Kubernetes resources.
        </Typography>
      </ConfirmationDialog>
    </Grid>
  );
};
