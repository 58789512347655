export enum RunStatus {
  Initialized = 'INITIALIZED',
  Running = 'RUNNING',
  Failed = 'FAILED',
  Succeeded = 'SUCCEEDED',
}

export enum NotebookType {
  ExperimentNotebook = 'experiment_notebook',
  SharedNotebook = 'shared_notebook',
  ScheduledNotebook = 'scheduled_notebook',
}
