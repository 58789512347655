import React from 'react';

import SettingsIcon from '@material-ui/icons/Settings';
import CloudIcon from '@material-ui/icons/Cloud';
import AirlineSeatIndividualSuiteIcon from '@material-ui/icons/AirlineSeatIndividualSuite';
import BuildIcon from '@material-ui/icons/Build';
import HelpIcon from '@material-ui/icons/Help';

import { PIPELINE_STATUS, STEP_STATUS, STEP_TYPE } from '../constants';
import { STATUS_COLORS } from './colors';

export function getColorByStatus(status: string) {
  switch (status) {
    case PIPELINE_STATUS.NOT_STARTED:
      return STATUS_COLORS.default;
    case STEP_STATUS.SKIPPED:
      return STATUS_COLORS.skipped;
    case PIPELINE_STATUS.SUCCEEDED:
      return STATUS_COLORS.success;
    case STEP_STATUS.PENDING_APPROVAL:
      return STATUS_COLORS.warning;
    case PIPELINE_STATUS.FAILED:
    case PIPELINE_STATUS.ERROR:
      return STATUS_COLORS.failed;
    case STEP_STATUS.INIT:
      return STATUS_COLORS.info;
    case PIPELINE_STATUS.IN_PROGRESS:
      return STATUS_COLORS.progress;
    case PIPELINE_STATUS.PENDING:
      return STATUS_COLORS.info;
    case PIPELINE_STATUS.ABORTED:
    case STEP_STATUS.ABORTING:
    case STEP_STATUS.TIMEOUT:
      return STATUS_COLORS.aborted;
    default:
      return STATUS_COLORS.default;
  }
}

export function getIconByStepType(type: string) {
  switch (type) {
    case STEP_TYPE.SCRIPT:
      return <SettingsIcon />;
    case STEP_TYPE.PROCESS:
    case STEP_TYPE.DEPLOY:
      return <CloudIcon />;
    case STEP_TYPE.TRAFFIC:
    case STEP_TYPE.OVERLAY:
      return <BuildIcon />;
    case STEP_TYPE.SLEEP:
      return <AirlineSeatIndividualSuiteIcon />;
    default:
      return <HelpIcon />;
  }
}
