import React from 'react';
import { SearchConfigForGCS } from '../models';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import { AboutField, EventTracker, TeamReference } from 'plugin-ui-components';
import { Link } from '@backstage/core-components';
import { AccentColors } from '../constants/AccentColors';
import { Avatar, Box, Grid } from '@material-ui/core';
import { getTeamNameFromFullName } from 'plugin-core';
import * as Tracking from '../common/tracking';
import { extractGCSFields, formatIndexLabelToID } from '../utils';

interface PeopleMetadata {
  login: string;
  displayName: string;
  picture: string;
  email: string;
  team: string;
  company: string;
  department: string;
  job_title: string;
}

const label = 'People';
const id = formatIndexLabelToID(label);
export const peopleIndex = new SearchConfigForGCS({
  id,
  label,
  datasource: 'PERSON',
  isPredefinedSource: true,
  icon: AccountCircleIcon,
  accentColor: AccentColors.People,
  getTitleIcon: hit => (
    <Avatar src={extractGCSFields<PeopleMetadata>(hit).picture} />
  ),
  getUrl: hit => {
    const { login } = extractGCSFields<PeopleMetadata>(hit);
    return `/catalog/default/user/${login}`;
  },
  getTitle: hit => extractGCSFields<PeopleMetadata>(hit).displayName,
  render: hit => {
    const { email, team, company, department, job_title } =
      extractGCSFields<PeopleMetadata>(hit);
    const organization = getTeamNameFromFullName(team ?? '', 'parentName');

    const place = [company, organization, department].filter(Boolean);
    return (
      <Box marginTop="1rem">
        <Grid container>
          <AboutField xs={12} md={6} label="WHAT DO I DO AT ZALANDO?">
            {job_title ?? '-'}
          </AboutField>

          <AboutField xs={12} md={6} label="HOW YOU CAN REACH OUT?">
            {email ? (
              <EventTracker {...Tracking.userEmailClick()}>
                <Link to={`mailto:${email}`}>{email}</Link>
              </EventTracker>
            ) : (
              '-'
            )}
          </AboutField>

          <AboutField xs={12} md={6} label="MEMBER OF">
            {team ? <TeamReference team={team} /> : '-'}
          </AboutField>

          <AboutField xs={12} md={6} label="WHERE DO I WORK?">
            <div>
              {place.map((item, i) => (
                <div key={i}>{item}</div>
              ))}
            </div>
          </AboutField>
        </Grid>
      </Box>
    );
  },
});
