import React from 'react';
import { useLocation, useNavigate } from 'react-router';
import { Theme, useTheme } from '@material-ui/core';
import 'style-loader!css-loader!sass-loader!./root.scss';
import { SidebarPage } from '@backstage/core-components';
import { AppSideBar } from './AppSideBar';
import { colors } from './colors';
import { errorApiRef, useApi } from '@backstage/core-plugin-api';
import * as Sentry from '@sentry/react';
import { getThemeId, setNewSessionIdExpirationTime } from 'plugin-core';
import { Alerter, UserTeamsProvider } from 'plugin-ui-components';
import { SearchModal } from 'plugin-search';

export const deprecatedDocsPaths = [
  '/zalando-docs',
  '/backstage-docs',
  '/documentation',
];

const Root: React.FC = ({ children }) => {
  const theme = useTheme<Theme>();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const errorApi = useApi(errorApiRef);
  const page = pathname?.split('/').filter(i => i)[0];

  React.useEffect(() => {
    if (deprecatedDocsPaths.includes(pathname)) {
      navigate('/docs', { replace: true });
    }
  }, [pathname, navigate]);

  React.useEffect(() => {
    const root = document.documentElement;

    const themeId = getThemeId();
    const sidebarBg =
      themeId === 'dark' ? theme.palette.background.paper : 'rgb(16, 20, 25)';
    // Define here the CSS variables that can be extracted from MUI theme
    const vars: Array<{ property: string; value: string }> = [
      { property: '--divider-clr', value: theme.palette.divider },
      { property: '--shadow-1', value: theme.shadows[1] },
      { property: '--paper-bg', value: theme.palette.background.paper },
      { property: '--sidebar-bg', value: sidebarBg },
      { property: '--text-primary', value: theme.palette.text.primary },
      { property: '--text-secondary', value: theme.palette.text.secondary },
      { property: '--clr-a200', value: theme.palette.grey.A200 },
      { property: '--clr-green-main', value: theme.palette.success.main },
      { property: '--clr-warning-main', value: theme.palette.warning.main },
      { property: '--clr-warning-light', value: theme.palette.warning.light },
      { property: '--clr-error-main', value: theme.palette.error.main },
      { property: '--clr-info-main', value: theme.palette.info.main },
    ];

    colors.forEach((value, property) => {
      root.style.setProperty(property, value[themeId]);
    });
    vars.forEach(item => root.style.setProperty(item.property, item.value));
  }, [theme]);

  React.useEffect(() => {
    errorApi.error$()?.subscribe(({ error }) => Sentry.captureEvent(error));
  }, [errorApi]);

  React.useEffect(() => {
    setNewSessionIdExpirationTime();
  }, [errorApi]);

  return (
    <UserTeamsProvider>
      <SidebarPage>
        <div className="app-container">
          <AppSideBar />
          <main id={`page-${page}`}>{children}</main>
        </div>
        <Alerter />
      </SidebarPage>
      <SearchModal />
    </UserTeamsProvider>
  );
};

export default Root;
