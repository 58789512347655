import { UnifiedThemeOptions } from '@backstage/theme';
import { buttonClasses } from '@mui/material/Button';
import { iconButtonClasses } from '@mui/material/IconButton';

/** The value is defined in `Root.tsx` and updated when the theme changes */
const disabledColor: string = 'var(--disabled-color)';

export const stylesOverrides: UnifiedThemeOptions['components'] = {
  MuiAlert: {
    styleOverrides: {
      outlinedSuccess: {
        color: '#4caf50',
      },
      outlinedInfo: {
        color: '#2196f3',
      },
      outlinedWarning: {
        color: '#ff9800',
      },
      outlinedError: {
        color: '#f44336',
      },
      standardInfo: {
        border: '1px solid #c0e4ff',
      },
      standardWarning: {
        border: '1px solid #ffe8c8',
      },
      standardError: {
        border: '1px solid #ffe0dc',
      },
      message: {
        flexGrow: 1,
      },
    },
  },
  MuiFormHelperText: {
    styleOverrides: {
      root: {
        '&$disabled': {
          color: disabledColor,
        },
      },
    },
  },
  MuiFormLabel: {
    styleOverrides: {
      root: {
        '&$disabled': {
          color: disabledColor,
        },
      },
    },
  },
  MuiInputBase: {
    styleOverrides: {
      root: {
        '&$disabled': {
          '&::before': {
            borderColor: disabledColor,
          },
        },
      },
      input: {
        '&$disabled': {
          color: disabledColor,
        },
      },
      inputMultiline: {
        resize: 'vertical',
      },
    },
  },
  MuiInput: {
    styleOverrides: {
      underline: {
        '&:before': {
          borderBottom: '1px solid currentColor',
        },
      },
    },
  },
  MuiChip: {
    styleOverrides: {
      outlined: {
        borderColor: disabledColor,
        color: 'inherit',
      },
    },
  },
  MuiButtonBase: {
    styleOverrides: {
      root: {
        [`&.${iconButtonClasses.root}.${iconButtonClasses.disabled}`]: {
          color: 'var(--disabled-color)',
          borderColor: 'var(--disabled-color)',
        },
        [`&.${buttonClasses.root}.${buttonClasses.disabled}`]: {
          color: 'var(--disabled-color)',
          borderColor: 'var(--disabled-color)',
        },
      },
    },
  },
  MuiButton: {
    styleOverrides: {
      disabled: {},
      outlined: {
        borderColor: 'var(--mui-button-outlined-border)',
        '&:hover': {
          backgroundColor: 'rgba(175,175,175,0.36)',
        },
        '&$disabled': {
          borderColor: disabledColor,
          color: disabledColor,
        },
      },
      containedPrimary: {
        '&$disabled': {
          backgroundColor: 'var(--primary-button-disabled-bg)',
          color: 'var(--primary-button-disabled-color)',
        },
      },
    },
  },
  MuiDivider: {
    styleOverrides: {
      root: {
        backgroundColor: disabledColor,
      },
    },
  },
  MuiIconButton: {
    styleOverrides: {
      root: {
        color: 'currentColor',
        '&$disabled': {
          color: disabledColor,
        },
      },
    },
  },
  MuiAutocomplete: {
    styleOverrides: {
      popupIndicator: {
        color: 'currentColor',
      },
      clearIndicator: {
        color: 'currentColor',
      },
      tag: {
        '& ~ input:empty:not(:focus)': {
          height: 0,
          padding: '0 !important',
        },
      },
    },
  },
  MuiListItem: {
    styleOverrides: {
      root: {
        '&$selected, &$selected:hover': {
          backgroundColor: 'var(--mui-selected-list-item)',
        },
      },
    },
  },
  MuiSelect: {
    styleOverrides: {
      icon: {
        color: 'currentColor',
      },
    },
  },
  MuiListItemIcon: {
    styleOverrides: {
      root: {
        color: 'var(--mui-icon-color)',
      },
    },
  },
  MuiToggleButtonGroup: {
    styleOverrides: {
      root: {
        backgroundColor: 'transparent',
      },
      grouped: {
        borderColor: '#8e8e8e',
        color: 'currentColor',
      },
    },
  },
  MuiToggleButton: {
    styleOverrides: {
      root: {
        '&$selected': {
          color: 'white',
          backgroundColor: '#000',
          '&:hover': {
            backgroundColor: '#403f3f',
          },
        },
      },
    },
  },
  MuiTabs: {
    styleOverrides: {
      flexContainer: {
        '& > button': {
          '& > span': {
            gap: 10,
          },
        },
      },
      flexContainerVertical: {
        '& > button': {
          minHeight: 'auto',

          '& > span': {
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'start',
          },
        },
      },
    },
  },
  MuiTab: {
    styleOverrides: {
      root: {
        letterSpacing: '0.07em',
        '@media (min-width: 600px)': {
          minWidth: 120,
        },
      },
      textColorInherit: {
        opacity: 1,
      },
      labelIcon: {
        '& > span': {
          '& > *:first-child': {
            marginBottom: '0 !important',
          },
        },
      },
    },
  },
  MuiTableRow: {
    styleOverrides: {
      root: {
        '&:nth-of-type(odd)': {
          backgroundColor: 'var(--tr-bg)',
        },
      },
    },
  },
  MuiTableCell: {
    styleOverrides: {
      root: {
        wordBreak: 'normal',
        borderBottom: 'none',
      },
      sizeSmall: {
        padding: '12px 16px 12px 20px',
        lineHeight: 1,
      },
      head: {
        lineHeight: 1,
      },
    },
  },
  MuiPaginationItem: {
    styleOverrides: {
      page: {
        '&:hover': {
          backgroundColor: 'var(--mui-pagination-page-hover)',
        },
        '&.Mui-selected': {
          backgroundColor: 'var(--mui-pagination-page-selected)',
          pointerEvents: 'none',
        },
      },
    },
  },
  MuiStepIcon: {
    styleOverrides: {
      text: {
        fill: 'var(--mui-step-text-color)',
      },
    },
  },
  MuiSvgIcon: {
    styleOverrides: {
      colorDisabled: {
        color: 'var(--disabled-color)',
      },
    },
  },
  MuiTooltip: {
    styleOverrides: {
      tooltip: {
        backgroundColor: '#1a1a1a',
        opacity: 1,
        fontSize: '0.8rem',
        fontWeight: 'normal',
      },
      arrow: {
        color: '#1a1a1a',
      },
    },
  },
  MuiOutlinedInput: {
    styleOverrides: {
      notchedOutline: {
        borderColor: 'var(--disabled-color)',
      },
    },
  },
  MuiSkeleton: {
    styleOverrides: {
      text: {
        '&.team-reference': {
          display: 'inline-block',
          width: 100,
        },
      },
    },
  },
  MuiTypography: {
    styleOverrides: {
      // TODO: Add these typography styles and re-align with providers to match the design guidelines
      // h1: {
      //   fontSize: '2rem',
      //   fontWeight: 700,
      // },
      // h2: {
      //   fontSize: '1.75rem',
      //   fontWeight: 700,
      // },
      h3: {
        // fontSize: '1.5rem',
        fontSize: '1.3rem',
        fontWeight: 700,
      },
      h4: {
        fontSize: '1.25rem',
        fontWeight: 700,
        lineHeight: 1.6,
        marginBottom: 0,
      },
      // h5: {
      //   fontSize: '1.125rem',
      //   fontWeight: 700,
      // },
      h6: {
        color: 'var(--text-secondary)',
        textTransform: 'uppercase',
        // fontSize: '1rem',
        fontSize: '0.630rem',
        fontWeight: 700,
        letterSpacing: 0.5,
        overflow: 'hidden',
        whiteSpace: 'nowrap',
      },
    },
  },
  BackstageHeader: {
    styleOverrides: {
      rightItemsBox: {
        paddingInlineEnd: '1rem',
      },
      subtitle: {
        maxWidth: 'auto',
      },
    },
  },
  MuiInputAdornment: {
    styleOverrides: {
      root: {
        color: 'inherit',
      },
    },
  },
  MuiPopover: {
    styleOverrides: {
      paper: {
        '& [role*=listbox] > li': {
          display: 'flex',
          paddingInline: '0.5rem',
        },
      },
    },
  },
  MuiBreadcrumbs: {
    styleOverrides: {
      root: {
        '& li, & a': {
          color: '#fff',
        },
      },
    },
  },
};
