import {
  IDeploymentValidation,
  IStepRunProblems,
  IValidationProblem,
} from '../api/types/payload';
import { modifyMessageForResourceOverwriteProblems } from './helpers';

export class StepRunProblemsModel {
  deploymentValidationProblems: IDeploymentValidation;
  scriptValidationProblems: Array<IValidationProblem>;

  constructor(json: IStepRunProblems = {} as IStepRunProblems) {
    this.deploymentValidationProblems =
      modifyMessageForResourceOverwriteProblems(
        json.deployment_validation || {},
      );
    this.scriptValidationProblems = json.script_validation || [];
  }
}
