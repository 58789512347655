import React from 'react';

const APIsIcon: React.FC<JSX.IntrinsicElements['svg']> = props => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9 0C11.73 0 14.0025 1.9425 14.5125 4.53C16.4625 4.665 18 6.27 18 8.25C18 10.32 16.32 12 14.25 12H12.125V12.875L13.425 13.85L13.625 14V14.25V15.0854C14.2076 15.2913 14.625 15.8469 14.625 16.5C14.625 17.3284 13.9534 18 13.125 18C12.2966 18 11.625 17.3284 11.625 16.5C11.625 15.8469 12.0424 15.2913 12.625 15.0854V14.5L11.325 13.525L11.125 13.375V13.125V12H9.5V13.5854C10.0826 13.7913 10.5 14.3469 10.5 15C10.5 15.8284 9.82843 16.5 9 16.5C8.17157 16.5 7.5 15.8284 7.5 15C7.5 14.3469 7.9174 13.7913 8.5 13.5854V12H6.875V13.125V13.375L6.675 13.525L5.375 14.5V15.2815C5.75352 15.5538 6 15.9981 6 16.5C6 17.3284 5.32843 18 4.5 18C3.67157 18 3 17.3284 3 16.5C3 15.7137 3.60506 15.0687 4.375 15.0051V14.25V14L4.575 13.85L5.875 12.875V12H4.5C2.0175 12 0 9.9825 0 7.5C0 5.1825 1.755 3.27 4.0125 3.03C4.95 1.23 6.8325 0 9 0ZM4.5 10.5H14.25C15.495 10.5 16.5 9.495 16.5 8.25C16.5 7.005 15.495 6 14.25 6H13.125V5.625C13.125 3.345 11.28 1.5 9 1.5C7.11 1.5 5.5275 2.7675 5.0325 4.5H4.5C2.8425 4.5 1.5 5.8425 1.5 7.5C1.5 9.1575 2.8425 10.5 4.5 10.5ZM4.5 17C4.77614 17 5 16.7761 5 16.5C5 16.2239 4.77614 16 4.5 16C4.22386 16 4 16.2239 4 16.5C4 16.7761 4.22386 17 4.5 17ZM13.125 17C13.4011 17 13.625 16.7761 13.625 16.5C13.625 16.2239 13.4011 16 13.125 16C12.8489 16 12.625 16.2239 12.625 16.5C12.625 16.7761 12.8489 17 13.125 17ZM9.5 15C9.5 15.2761 9.27614 15.5 9 15.5C8.72386 15.5 8.5 15.2761 8.5 15C8.5 14.7239 8.72386 14.5 9 14.5C9.27614 14.5 9.5 14.7239 9.5 15Z"
      fill="currentColor"
    />
  </svg>
);

export default APIsIcon;
