import React from 'react';
import { OverridableComponent } from '@material-ui/core/OverridableComponent';
import { SvgIconTypeMap } from '@material-ui/core/SvgIcon/SvgIcon';

export const ZalandoIcon: OverridableComponent<SvgIconTypeMap<{}, 'svg'>> = (
  props: any,
) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 60 70"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12.0713 59.9999C9.86428 59.9999 8.63356 59.4818 7.98119 59.1024C7.05583 58.5795 5.39482 57.2516 3.89112 53.2958C1.48519 46.9663 0.0647748 39.235 0 30.148V30.1156C0.0647748 21.0286 1.48519 13.3019 3.89112 6.98175C5.39482 3.03048 7.06971 1.69797 7.98119 1.17515C8.63356 0.781871 9.85966 0.263672 12.0713 0.263672C13.0699 0.272723 14.0662 0.360908 15.0509 0.527398C21.7458 1.61006 29.1487 4.2427 37.042 8.73067H37.0743C44.9075 13.3297 50.8945 18.4469 55.1743 23.6751C57.8439 26.9509 58.1586 29.07 58.1586 30.1202C58.1586 31.1705 57.8347 33.3127 55.1743 36.5746C50.8945 41.826 44.9075 46.9247 37.0743 51.5191H37.042C29.1487 56.0256 21.7458 58.6582 15.0509 59.7408C14.0661 59.9062 13.0698 59.9929 12.0713 59.9999Z"
        fill="#FF6900"
      />
    </svg>
  );
};
