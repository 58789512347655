import {
  createApiRef,
  DiscoveryApi,
  OAuthApi,
} from '@backstage/core-plugin-api';

export type ComplianceAndSecurityVulnerabilities = {
  spec: any;
  TeamID: string;
  Entries: number;
};

export class ComplianceAndSecurityVulnerabilitiesApi {
  private readonly discoveryApi: DiscoveryApi;
  private readonly oauth2Api: OAuthApi;

  constructor(options: { discoveryApi: DiscoveryApi; oauth2Api: OAuthApi }) {
    this.discoveryApi = options.discoveryApi;
    this.oauth2Api = options.oauth2Api;
  }

  async fetchComplianceAndSecurityVulnerabilities(): Promise<
    ComplianceAndSecurityVulnerabilities[]
  > {
    const token = await this.oauth2Api.getAccessToken();
    const proxyUrl = await this.discoveryApi.getBaseUrl('proxy');
    const apiUrl = `${proxyUrl}/compliance-and-security-vulnerability-api/vulnerabilities`;

    const response = await fetch(apiUrl, {
      headers: { Authorization: `Bearer ${token}` },
    });

    return await response.json();
  }
}

export const complianceAndSecurityVulnerabilitiesApiRef =
  createApiRef<ComplianceAndSecurityVulnerabilitiesApi>({
    id: 'plugin.compliance-and-security.vulnerabilities',
  });
