import { Theme, styled, Typography } from '@material-ui/core';
import { getThemeId } from 'plugin-core';
import React from 'react';
import { ChangedLineProps } from './ChangeTracker';

const Wrapper = styled('div')(({ theme }: { theme: Theme }) => ({
  margin: theme.spacing(1),
}));

const PlusMinus = styled(Typography)(({ theme }: { theme: Theme }) => ({
  marginRight: `${theme.spacing()}px`,
  width: `${theme.spacing(2)}px`,
  display: 'inline-block',
  flexShrink: 0,
}));

const LineWrapper = styled('div')(
  ({
    theme,
    deleted,
    themeID,
  }: {
    theme: Theme;
    deleted?: boolean;
    themeID?: string;
  }) => {
    let backgroucndColor = !!deleted ? '#ffeef0' : '#e6ffed';

    if (themeID === 'dark') {
      backgroucndColor = !!deleted ? '#a33942' : '#2a6531';
    }

    return {
      display: 'flex',
      backgroundColor: backgroucndColor,
      padding: theme.spacing(0, 1),
    };
  },
);

export const ChangedLine = (props: ChangedLineProps) => {
  const themeID = getThemeId();
  const { label, changeType, oldValue, newValue } = props;
  let oldValueElement = null;
  let newValueElement = null;

  if (changeType === 'added' || changeType === 'changed') {
    newValueElement = (
      <LineWrapper themeID={themeID}>
        <PlusMinus variant="body1">+</PlusMinus>
        <Typography variant="body1">
          {Array.isArray(newValue) ? newValue.join(', ') : newValue}
        </Typography>
      </LineWrapper>
    );
  }
  if (changeType === 'changed' || changeType === 'deleted') {
    oldValueElement = (
      <LineWrapper themeID={themeID} deleted>
        <PlusMinus>-</PlusMinus>
        <Typography variant="body1">
          {Array.isArray(oldValue) ? oldValue.join(', ') : oldValue}
        </Typography>
      </LineWrapper>
    );
  }

  return (
    <Wrapper>
      <Typography variant="body1">{label}</Typography>
      {oldValueElement}
      {newValueElement}
    </Wrapper>
  );
};
