// Based on this example from material-ui
// https://github.com/mui-org/material-ui/blob/master/docs/src/pages/components/autocomplete/Virtualize.tsx

import React, { useEffect } from 'react';
import { VariableSizeList, ListChildComponentProps } from 'react-window';

const LIST_BOX_PADDING = 8; // px
const ITEM_SIZE = 36; // px

function renderRow(props: ListChildComponentProps) {
  const { data, index, style } = props;
  return React.cloneElement(data[index], {
    style: {
      ...style,
      top: (style.top as number) + LIST_BOX_PADDING,
    },
  });
}

const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef<HTMLDivElement>((props, ref) => {
  const outerProps = React.useContext(OuterElementContext);
  return <div ref={ref} {...props} {...outerProps} />;
});

function useResetCache(data: any) {
  const ref = React.useRef<VariableSizeList>(null);
  useEffect(() => {
    // eslint-disable-next-line eqeqeq
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true);
    }
  }, [data]);
  return ref;
}

export const ListBoxComponent = React.forwardRef<HTMLDivElement>(
  function ListBoxComponent(props, ref) {
    const { children, ...other } = props;
    const itemData = React.Children.toArray(children);
    const itemCount = itemData.length;

    const getHeight = () => {
      if (itemCount > 8) {
        return 8 * ITEM_SIZE;
      }
      return [...Array(itemCount).keys()]
        .map(() => ITEM_SIZE)
        .reduce((a, b) => a + b, 0);
    };

    const gridRef = useResetCache(itemCount);

    return (
      <div ref={ref}>
        <OuterElementContext.Provider value={other}>
          <VariableSizeList
            itemData={itemData}
            height={getHeight() + 2 * LIST_BOX_PADDING}
            width="100%"
            ref={gridRef}
            outerElementType={OuterElementType}
            innerElementType="ul"
            itemSize={() => ITEM_SIZE}
            overscanCount={5}
            itemCount={itemCount}
          >
            {renderRow}
          </VariableSizeList>
        </OuterElementContext.Provider>
      </div>
    );
  },
);
