import React from 'react';
import { Box, BoxProps, makeStyles } from '@material-ui/core';

export interface StackProps extends BoxProps {
  /** Determines the spacing between the elements in the stack */
  spacing?: string;
}

const useStyles = makeStyles(() => ({
  wrapper: {
    display: 'flex',
    '&> *': {
      margin: 0,
    },
  },
  hstack: {
    flexDirection: 'row',
    '&> * + *': {
      marginLeft: (props: StackProps) => (props.spacing ? props.spacing : 0),
    },
  },
  vstack: {
    flexDirection: 'column',
    '&> * + *': {
      marginTop: (props: StackProps) => (props.spacing ? props.spacing : 0),
    },
  },
}));

function HStack(props: StackProps) {
  const classes = useStyles(props);
  return (
    <Box {...props} className={`${classes.wrapper} ${classes.hstack}`}>
      {props.children}
    </Box>
  );
}

function VStack(props: StackProps) {
  const classes = useStyles(props);
  return (
    <Box {...props} className={`${classes.wrapper} ${classes.vstack}`}>
      {props.children}
    </Box>
  );
}

export { HStack, VStack };
