import React from 'react';
import { useAsyncEntity } from '@backstage/plugin-catalog-react';
import { useApi } from '@backstage/core-plugin-api';
import { LinkButton, Progress } from '@backstage/core-components';
import { Alert } from '@material-ui/lab';
import {
  Button,
  Card,
  CardContent,
  Link,
  Step,
  StepContent,
  Stepper,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { useAsync, useLocalStorage } from 'react-use';
import { applicationReviewApiRef } from '../api';
import { plural } from '../utils/language';
import { IReviewContext, ReviewContext } from './context';
import { tierAssessmentFields } from './CriticalityAssessment';
import { IncompleteFormDialog } from './dialogs/IncompleteFormDialog/IncompleteFormDialog';
import { IStep, steps } from './steps';
import * as S from './styles';
import { SubmitDialog } from './dialogs';
import WarningIcon from '@material-ui/icons/Warning';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ReplayIcon from '@material-ui/icons/Replay';
import { stringifyEntityRef } from '@backstage/catalog-model';
import { isConfirmationField } from '../utils/fields';
import { catalogAdditionalApiRef } from 'plugin-catalog';

const storageKey = 'application_review';
const SEVEN_DAYS = 7 * 24 * 60 * 60 * 1000;

interface IFormStatus extends IAppReview.FormStatus {
  activeStep: number;
}

export function ApplicationReviewForm() {
  const catalogAdditionalApi = useApi(catalogAdditionalApiRef);
  const { entity, refresh } = useAsyncEntity();
  const metadata = entity?.metadata;
  const pristine = React.useRef(true);
  const appReviewStorageKey = `${storageKey}_${metadata?.name}`;
  const appReviewFormStorageKey = `${appReviewStorageKey}_form`;
  const [success, setSuccess] = React.useState(false);
  const [dialogToggle, setDialogToggle] = React.useState(false);
  const [localStorageReview, setLocalStorageReview, removeLocalStorageReview] =
    useLocalStorage(appReviewStorageKey, {});
  const [localForm, setLocalForm, removeLocalForm] = useLocalStorage(
    appReviewFormStorageKey,
    {
      activeStep: 0,
      expiresAt: '',
    },
  );
  const [review, setReview] = React.useState<IAppReview.Review>(
    localStorageReview || {},
  );
  const [formStatus, setFormStatus] = React.useState<IFormStatus>(
    localForm || { activeStep: 0 },
  );
  const [activeStep, setActiveStep] = React.useState<number>(
    formStatus.activeStep || 0,
  );

  const [incompleteFormError, setIncompleteFormError] = React.useState(false);
  const [remark, setRemark] = React.useState<boolean>(false);
  const api = useApi(applicationReviewApiRef);

  const { loading, value: storedReviews } = useAsync(() => {
    if (metadata) {
      return api.getReviews(metadata.name, 1);
    }
    return Promise.resolve([] as IAppReview.StoredReview[]);
  }, [entity, api]);

  const { value: issues } = useAsync(() => {
    if (metadata) {
      return api.getIssues(metadata.name);
    }
    return Promise.resolve([] as IAppReview.Issue[]);
  }, [entity, api]);

  React.useEffect(() => {
    let lastReview: IAppReview.Review | undefined = undefined;
    if (storedReviews?.length && !Object.keys(review).length) {
      lastReview = storedReviews[0].review;
      for (const key in lastReview) {
        if (isConfirmationField(key) || !lastReview[key]) {
          delete lastReview[key];
        }
      }
    }
    if (storedReviews?.length && localForm?.expiresAt) {
      const lastReviewDate = new Date(storedReviews[0].time);
      // Diff between current date and expiration date
      const expiresAtTodayDiff =
        new Date(localForm?.expiresAt).getTime() - new Date().getTime();
      // Diff between last review date and localStorage creation date
      const expiresAtLastReviewDiff =
        new Date(localForm?.expiresAt).getTime() -
        SEVEN_DAYS -
        lastReviewDate.getTime();
      // Replace localStorage with API data if it's older than seven days or if there is a newer review
      if (expiresAtTodayDiff <= 0 || expiresAtLastReviewDiff <= 0) {
        lastReview = storedReviews[0].review;
        const expiresAt = new Date(Date.now() + SEVEN_DAYS);
        setLocalStorageReview({
          ...lastReview,
        });
        setLocalForm({ activeStep: 0, expiresAt: expiresAt.toISODateString() });
        setActiveStep(0);
      }
    }

    if (lastReview) setReview(lastReview);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storedReviews]);

  const onSubmit = (ev: React.FormEvent) => {
    ev.preventDefault();
    const currentField = steps[activeStep].field;
    setFormStatus(status => ({
      ...status,
      [currentField]: 'completed',
    }));
    setLocalStorageReview({
      ...review,
    });
    setActiveStep(step => step + 1);
  };

  const onSubmitReview = () => {
    const isOk = steps
      .filter(s => !s.optional)
      .reduce(
        (decision, step) =>
          decision &&
          (step.validate
            ? step.validate(review)
            : formStatus[step.field] === 'completed'),
        true,
      );
    if (isOk) {
      setDialogToggle(true);
    } else {
      setIncompleteFormError(true);
    }
  };

  const onReset = (ev: React.FormEvent) => {
    const newReview = { ...review };
    const formFields = [
      ...Array.from(ev.currentTarget.querySelectorAll('textarea')),
      ...Array.from(ev.currentTarget.querySelectorAll('input')),
    ];
    new Set(formFields.map(item => item.name)).forEach(f => {
      delete newReview[f];
    });
    setReview(newReview);

    const field = steps[activeStep].field;
    const newFormStatus = { ...formStatus };
    delete newFormStatus[field];
    setFormStatus(newFormStatus);
  };

  const onReviewChange = (
    name: keyof IAppReview.Review,
    value: IAppReview.Review[keyof IAppReview.Review],
  ) => {
    /** Resets the criticality assessment form when in_production is set to no */
    let reset = {};
    if (name === 'in_production' && value === 'no') {
      reset = tierAssessmentFields.reduce(
        (acc: any, curr) => ({ ...acc, [curr]: '' }),
        {},
      );
    }
    setReview(r => ({ ...r, ...reset, [name]: value }));
    if (pristine.current) {
      pristine.current = false;
    }
  };

  const onSuccess = async () => {
    const entityRef = stringifyEntityRef(entity!);
    const refreshResponse = await catalogAdditionalApi.refreshEntity(
      entityRef,
      5,
    );

    const completed = !(!refreshResponse.refreshed || refreshResponse?.error);
    if (!completed) {
      // Entity refresh in Catalog failed or is taking more than 5 seconds
      setRemark(true);
    }

    setDialogToggle(false);
    removeLocalStorageReview();
    removeLocalForm();
    // Ensures that the dialog has been animated to close
    setTimeout(() => {
      setSuccess(true);
      if (refresh) {
        refresh();
      }
    }, 300);
  };

  React.useEffect(() => {
    const newFormStatus: IFormStatus = { ...formStatus, activeStep };
    const expiresAt = new Date(Date.now() + SEVEN_DAYS);
    setLocalForm({
      ...newFormStatus,
      expiresAt: expiresAt.toISODateString(),
    });
  }, [formStatus, appReviewFormStorageKey, activeStep, setLocalForm]);

  const context: IReviewContext = {
    review,
    onReviewChange,
    activeStep,
    setActiveStep,
    totalSteps: steps.length,
    currentStep: steps[activeStep],
    issues: issues || [],
  };

  React.useEffect(() => {
    /**
     * Scrolls into the current step when
     * - The activeStep is bigger than 0
     * - The active step element exists in the steps array
     * - The active step element is in the document
     */
    if (activeStep && steps[activeStep]?.field) {
      const interval = setInterval(() => {
        const currentStep = document.getElementById(steps[activeStep].field);
        if (currentStep) {
          currentStep.scrollIntoView({ behavior: 'smooth' });
          clearInterval(interval);
        }
      }, 100);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) {
    return <Progress />;
  }

  if (success) {
    return (
      <S.Container>
        <Card>
          <Alert severity="success" variant="standard" style={{ margin: 0 }}>
            <Typography>
              Successfully submitted review for&nbsp;
              <em>{metadata?.name}</em>
            </Typography>
            <br />
            {remark && (
              <div>
                <Alert severity="warning">
                  This application review will be visible in Sunrise within 24
                  hours.
                </Alert>
                <br />
              </div>
            )}
            <Typography>
              <LinkButton to="../" color="primary" variant="contained">
                Back to the application's overview
              </LinkButton>
            </Typography>
          </Alert>
        </Card>
      </S.Container>
    );
  }

  const blockerIssues = issues?.filter(i => i.type === 'blocker') || [];

  const blockers: Partial<
    Record<
      IAppReview.FormStepField,
      { issues: IAppReview.Issue[]; step: IStep }
    >
  > = {};

  if (blockerIssues.length) {
    blockerIssues.forEach(blocker => {
      const blockedSection = steps.find(step =>
        step.fields.includes(blocker.field),
      );
      if (blockedSection) {
        if (blockers.hasOwnProperty(blockedSection.field)) {
          blockers[blockedSection.field]!.issues.push(blocker);
        } else {
          blockers[blockedSection.field] = {
            issues: [blocker],
            step: blockedSection,
          };
        }
      }
    });
  }

  return (
    <ReviewContext.Provider value={context}>
      <S.Container>
        <Typography variant="h1">Application review</Typography>
        <Alert severity="info">
          The progress of this form is currently stored in browser memory and it
          won't be synced across other browsers.
        </Alert>

        {!!blockerIssues.length && (
          <Alert severity="error" id="blockers">
            This application review is blocked by issues in the following
            sections:
            <ul style={{ marginBottom: 0 }}>
              {Object.keys(blockers).map((field, i) => {
                const stepIndex = steps.findIndex(
                  step => step.field === field,
                )!;
                const step = steps[stepIndex]!;
                const issueCount =
                  blockers[field as IAppReview.FormStepField]!.issues.length;
                return (
                  <li key={i}>
                    <Link
                      href={`#${step.field}`}
                      color="error"
                      onClick={() => setActiveStep(stepIndex)}
                    >
                      {step.header} ({issueCount} issue{plural(issueCount)})
                    </Link>
                  </li>
                );
              })}
            </ul>
          </Alert>
        )}

        <form onSubmit={onSubmit} onReset={onReset}>
          <Stepper
            activeStep={activeStep}
            orientation="vertical"
            className="p-inline-0 bg-none"
            nonLinear
          >
            {steps.map((item, i) => {
              return (
                <Step
                  key={i}
                  completed={formStatus[item.field] === 'completed'}
                  id={item.field}
                >
                  <S.CustomStepButton
                    onClick={() => {
                      if (pristine.current) {
                        pristine.current = false;
                      }
                      setActiveStep(i);
                    }}
                  >
                    {item.header}
                    {!item.optional && <span>*</span>}
                    {!!blockers[item.field] && (
                      <Tooltip
                        title={
                          <>
                            This section has{' '}
                            {blockers[item.field]!.issues.length}
                            &nbsp;issue
                            {plural(blockers[item.field]!.issues.length)}
                            &nbsp;blocking this review
                          </>
                        }
                      >
                        <WarningIcon className="review-section-icon error" />
                      </Tooltip>
                    )}
                    {item.field === 'cyberweek_ok' &&
                      metadata?.cyberweekInScope && (
                        <Tooltip title="This application is relevant for cyber week and needs your attention">
                          <ErrorOutlineIcon className="review-section-icon warn" />
                        </Tooltip>
                      )}
                  </S.CustomStepButton>
                  <StepContent>
                    <Card>
                      <CardContent>
                        <item.component />
                      </CardContent>
                    </Card>
                    <S.FormActions>
                      <div className="actions-wrapper">
                        {activeStep > 0 && (
                          <Button
                            onClick={() => setActiveStep(step => step - 1)}
                            startIcon={<ArrowUpwardIcon />}
                          >
                            Previous
                          </Button>
                        )}
                        <Button type="reset" startIcon={<ReplayIcon />}>
                          Reset
                        </Button>
                      </div>
                      <div className="actions-wrapper">
                        <Button
                          variant="contained"
                          color="primary"
                          type="submit"
                        >
                          Save and continue
                        </Button>
                      </div>
                    </S.FormActions>
                  </StepContent>
                </Step>
              );
            })}
          </Stepper>
          {!!Object.keys(blockers).length && (
            <Typography>
              Submitting this application review is blocked until&nbsp;
              <Link href="#blockers">all issues</Link> are resolved.
            </Typography>
          )}
          <Button
            variant="contained"
            color="primary"
            onClick={onSubmitReview}
            style={{ display: 'block', margin: '1rem auto 0' }}
            disabled={!!Object.keys(blockers).length}
          >
            Submit review
          </Button>
        </form>

        <SubmitDialog
          toggle={dialogToggle}
          name={metadata?.name || ''}
          review={review}
          onClose={() => setDialogToggle(false)}
          onSuccess={onSuccess}
        />
        <IncompleteFormDialog
          toggle={incompleteFormError}
          onClose={() => setIncompleteFormError(false)}
        />
      </S.Container>
    </ReviewContext.Provider>
  );
}
