import React from 'react';
import { useNavigate } from 'react-router';

interface RouteGuardProps {
  canAccess: boolean;
  redirectUrl: string;
}

export const RouteGuard: React.FC<RouteGuardProps> = ({
  canAccess,
  redirectUrl,
  children,
}) => {
  const navigate = useNavigate();
  React.useEffect(() => {
    if (!canAccess) navigate(redirectUrl);
  }, [canAccess, redirectUrl, navigate]);

  return <>{canAccess && children}</>;
};
