import React from 'react';
import {
  CardHeaderProps,
  CardContentProps,
  CardProps,
  Divider,
  Tooltip,
} from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import { ComingSoonWrapper } from '../ComingSoonWrapper';
import * as S from './styles';

export interface InfoCardProps extends Omit<CardProps, 'title'> {
  title: React.ReactNode;
  subheader?: React.ReactNode;
  cardFooter?: React.ReactNode;
  cardHeaderProps?: CardHeaderProps;
  cardContentProps?: CardContentProps;
  disabled?: boolean;
  disabledText?: string;
  tooltipText?: React.ReactNode;
}

export const InfoCard = React.forwardRef((props: InfoCardProps, ref) => {
  const {
    title,
    subheader,
    children,
    cardHeaderProps,
    cardContentProps,
    disabled = false,
    disabledText,
    tooltipText,
    cardFooter,
    ...cardProps
  } = props;

  const hasHeaderContent = title || subheader;

  const HeaderTooltip = () =>
    tooltipText ? (
      <Tooltip title={tooltipText} placement="right" interactive>
        <InfoIcon />
      </Tooltip>
    ) : (
      <></>
    );

  return (
    <S.StyledCard {...cardProps} ref={ref}>
      {hasHeaderContent ? (
        <>
          <S.StyledCardHeader
            title={
              <>
                {title} <HeaderTooltip />
              </>
            }
            subheader={subheader}
            titleTypographyProps={
              {
                component: 'h3',
                variant: 'h3',
              } as any
            }
            {...cardHeaderProps}
          />

          <Divider />
        </>
      ) : (
        ''
      )}

      {disabled ? (
        <ComingSoonWrapper comingSoonText={disabledText}>
          <S.StyledCardContent {...cardContentProps}>
            {children}
          </S.StyledCardContent>
        </ComingSoonWrapper>
      ) : (
        <S.StyledCardContent disabled={disabled} {...cardContentProps}>
          {children}
        </S.StyledCardContent>
      )}

      {cardFooter && (
        <>
          <Divider />
          {cardFooter}
        </>
      )}
    </S.StyledCard>
  );
});
