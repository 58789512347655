import React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { AboutField, VStack } from 'plugin-ui-components';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import { ScorecardCompletion } from '../common';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import { Link } from '@backstage/core-components';

type ScorecardDetails = Pick<
  TechInsights.Scorecard,
  'name' | 'title' | 'description' | 'checks'
> & {
  completionPercentage?: number;
  infoTableColumns?: Array<{ label: string; value: number }>;
};

interface Props {
  scorecard: IEntityScorecard | TechInsights.Scorecard;
}

function extractDetails(
  scorecard: IEntityScorecard | TechInsights.Scorecard,
): ScorecardDetails {
  if ((scorecard as IEntityScorecard).kind === 'Scorecard') {
    const obj = scorecard as IEntityScorecard;
    return {
      name: obj.metadata.name,
      title: obj.metadata.title ?? '',
      description: obj.metadata.description ?? '',
      checks: obj.spec.checks,
      completionPercentage: obj.spec.completionPercentage,
      infoTableColumns: [
        {
          label: 'Number of checks',
          value: obj.spec.checks.length,
        },
        {
          label: 'Applies to',
          value: obj.relations?.length ?? 0,
        },
        {
          label: 'Failing',
          value: obj.spec.failing,
        },
      ],
    };
  }
  const obj = scorecard as TechInsights.Scorecard;
  return {
    name: obj.name,
    title: obj.title,
    description: obj.description,
    checks: obj.checks,
  };
}

export function ScorecardOverview({ scorecard }: Props) {
  const {
    name,
    title,
    description,
    checks,
    completionPercentage,
    infoTableColumns,
  } = extractDetails(scorecard);

  const showDetails =
    completionPercentage !== undefined && infoTableColumns?.length;

  return (
    <Grid container>
      <Grid item xs={12} lg={6}>
        <Card>
          <CardHeader title={title} />
          <CardContent>
            <Grid container gap={4} padding={2}>
              <AboutField xs={10} md={5} label="Name" value={name} canCopy />
              <AboutField
                xs={10}
                md={5}
                label="Checks"
                value={
                  <VStack spacing="0.2rem" m={0}>
                    {checks.map(check => (
                      <Link
                        key={check.id}
                        to={`/tech-insights/checks/${check.name}`}
                      >
                        {check.title}
                      </Link>
                    ))}
                  </VStack>
                }
              />
              <AboutField
                xs={10}
                md={5}
                label="Description"
                value={description}
              />
            </Grid>
          </CardContent>
        </Card>
      </Grid>
      {showDetails && (
        <Grid item xs={12} lg={6}>
          <Card>
            <CardHeader title="Stats" />
            <CardContent>
              <Box display="flex" gap="1rem">
                <ScorecardCompletion
                  completion={completionPercentage}
                  type="circular"
                  size="large"
                />

                <Table size="small">
                  <TableHead>
                    <TableRow>
                      {infoTableColumns.map(column => (
                        <TableCell key={column.label}>
                          <strong>{column.label}</strong>
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      {infoTableColumns.map(column => (
                        <TableCell key={`${column.label}-value`}>
                          {column.value}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableBody>
                </Table>
              </Box>
            </CardContent>
          </Card>
        </Grid>
      )}
    </Grid>
  );
}
