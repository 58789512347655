import React from 'react';
import { SearchConfigForGCS } from '../models';
import DashboardIcon from '@material-ui/icons/Dashboard';
import { ResultDataList } from '../components/ResultDataList';
import { AccentColors } from '../constants/AccentColors';
import { extractGCSFields, formatIndexLabelToID } from '../utils';
import { Box, Link, Typography } from '@material-ui/core';
import { TeamReference } from 'plugin-ui-components';
import { slugify } from 'plugin-core';

interface ToolingMetadata {
  id: number;
  name: string;
  journey: string | string[];
  step: string | string[];
  sapId: string;
  owner: string;
  status: string;
  purpose: string;
  interfaceUrl: string;
  trainingUrl: string;
  supportUrl: string;
  documentationUrl: string;
  chatUrl: string;
}

const label = 'Tooling & Services';
const id = formatIndexLabelToID(label);
export const toolingIndex = new SearchConfigForGCS({
  id,
  label,
  datasource: 'sunrise',
  objectTypes: ['tooling'],
  icon: DashboardIcon,
  accentColor: AccentColors.Tooling,
  getUrl: hit => {
    const name = slugify(hit.title || '');
    return name ? `/catalog/default/Tooling/${name}` : '';
  },
  getTitle: hit => hit.title ?? 'Unknown',
  render: hit => {
    const { journey, sapId, status, step, purpose, owner, interfaceUrl } =
      extractGCSFields<ToolingMetadata>(hit);
    return (
      <>
        <ResultDataList
          multiColumn
          list={[
            {
              label: 'Interface',
              value: interfaceUrl && (
                <Link href={interfaceUrl}>{interfaceUrl}</Link>
              ),
            },
            {
              label: 'Owner',
              value: sapId ? (
                <TeamReference team={sapId} />
              ) : (
                <Typography variant="body2">{owner}</Typography>
              ),
            },
            { label: 'Status', value: <b>{status}</b> },
            {
              label: 'Journey',
              value: (
                <b>{Array.isArray(journey) ? journey?.join(', ') : journey}</b>
              ),
            },
            {
              label: 'Step',
              value: (
                <b>
                  {(Array.isArray(step) ? step?.join(', ') : step)?.replace(
                    /\d+-/g,
                    '',
                  )}
                </b>
              ),
            },
          ]}
        />
        {purpose && (
          <Box marginTop={1}>
            <Typography variant="body2" color="textSecondary">
              {purpose}
            </Typography>
          </Box>
        )}
      </>
    );
  },
});
