import {
  createPlugin,
  createRoutableExtension,
} from '@backstage/core-plugin-api';

import { rootRouteRef } from './routes';

export const toolingCatalogPlugin = createPlugin({
  id: 'tooling-catalog',
  routes: {
    root: rootRouteRef,
  },
});

export const ToolingCatalogPage = toolingCatalogPlugin.provide(
  createRoutableExtension({
    name: 'ToolingCatalogPage',
    component: () =>
      import('./components/ToolingCatalogPage').then(m => m.default),
    mountPoint: rootRouteRef,
  }),
);
