import React, { useEffect, useState, useCallback } from 'react';
import { useParams } from 'react-router';
import {
  BaseLayout,
  ListEntities,
  KVTable,
  StatusTableCell,
  ActionsTableCell,
  CopyActionsBar,
} from '../../components';
import { useApi } from '@backstage/core-plugin-api';
import { Graph } from '../../components/Graph';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
} from '@material-ui/core';
import ExpandMore from '@material-ui/icons/ExpandMore';
import TableChartOutlined from '@material-ui/icons/TableChartOutlined';
import InsertChartOutlined from '@material-ui/icons/InsertChartOutlinedOutlined';
import { zflowApiRef } from 'plugin-ml-platform';
import { NO_OF_ROWS_PER_PAGE } from '../../config';
import { Content, SubvalueCell } from '@backstage/core-components';
import { TagGroup, UserTag } from '../../components/Tags';

export const ZflowExperiments = () => {
  const { experimentNotebookId: pipelineId = '' } = useParams();
  const zflowApiClient = useApi(zflowApiRef);
  const [refreshList, setRefreshList] = useState(false);
  const [page, setPage] = useState(0);
  const [activeAccordian, setActiveAccordian] = useState('table');
  const [plotData, setPlotData] = useState<any[]>([]);
  const [archiveZflowExperimentError, setArchiveZflowExperimentError] =
    useState<Error>();
  const [zflowExperimentPipelineName, setZflowExperimentPipelineName] =
    useState('');

  useEffect(() => {
    const getExperimentNotebook = async () => {
      const res = await zflowApiClient.getPipeline(pipelineId);
      setZflowExperimentPipelineName(res.name);
    };
    getExperimentNotebook();
  }, [pipelineId, zflowApiClient]);

  const onDelete = async (event: any, id: string) => {
    event?.stopPropagation();
    try {
      await zflowApiClient.archiveExperimentRun(id);
    } catch (err) {
      setArchiveZflowExperimentError(err as Error);
    }
    setRefreshList(!refreshList);
  };

  const COLUMNS = [
    {
      title: 'Experiment',
      field: 'name',
      render: (row: any): React.ReactNode => (
        <SubvalueCell
          value={<b>{row.name}</b>}
          subvalue={
            <TagGroup>
              <UserTag user={row.created_by} />
            </TagGroup>
          }
        />
      ),
    },
    {
      title: 'Status',
      field: 'row.status',
      render: (row: any) => <StatusTableCell status={row?.status} />,
    },
    {
      title: 'Created At',
      field: 'created_at',
    },
    {
      title: 'Metrics',
      render: (row: any) => <KVTable data={row.metrics} />,
    },
    {
      title: 'Params',
      render: (row: any) => <KVTable data={row.params} />,
    },
    {
      title: 'Actions',
      render: (row: any) => (
        <ActionsTableCell
          parentEntityType="pipelines"
          id={row.pipeline_id}
          name={row.name}
          archived={row.archived}
          childEntityType="zflow_experiments"
          childId={row.run_id}
          onDelete={onDelete}
        />
      ),
    },
  ];

  const getPlotData = useCallback(async () => {
    const res = await zflowApiClient.getPipelineRuns(
      pipelineId,
      NO_OF_ROWS_PER_PAGE,
      page * NO_OF_ROWS_PER_PAGE,
    );
    const metrics = res.runs
      .map(run => {
        return run.metrics.map((x: any) => ({
          ...x,
          type: 'metrics',
          parentId: run.name,
        }));
      })
      .reduce((acc, curr) => acc.concat(curr), []);
    const params = res.runs
      .map(run => {
        return run.params.map((x: any) => ({
          ...x,
          type: 'params',
          parentId: run.name,
        }));
      })
      .reduce((acc, curr) => acc.concat(curr), []);
    setPlotData(metrics.concat(params));
  }, [pipelineId, page, zflowApiClient]);

  useEffect(() => {
    getPlotData();
  }, [pipelineId, getPlotData]);

  const accordianComponents = [
    {
      id: 'graph',
      icon: <InsertChartOutlined />,
      title: 'Graph view',
      component: <Graph data={plotData} />,
    },
    {
      id: 'table',
      title: 'Table view',
      icon: <TableChartOutlined />,
      component: (
        <ListEntities
          getEntitiesPromise={zflowApiClient.getPipelineRuns(
            pipelineId,
            NO_OF_ROWS_PER_PAGE,
            page * NO_OF_ROWS_PER_PAGE,
          )}
          entityKey="runs"
          columns={COLUMNS}
          childEntityKey=""
          title="Zflow Experiments"
          refreshList={refreshList}
          page={page}
          onPageChange={setPage}
          error={archiveZflowExperimentError}
          setError={setArchiveZflowExperimentError}
        />
      ),
    },
  ];

  return (
    <BaseLayout notebookName={zflowExperimentPipelineName}>
      <Content>
        <CopyActionsBar
          type="zflow-experiments"
          id={pipelineId}
          nameToDelete={zflowExperimentPipelineName}
          hideCopyLatest
        />
        {accordianComponents.map(c => (
          <Accordion
            key={c.id}
            expanded={c.id === activeAccordian}
            onChange={() => setActiveAccordian(c.id)}
          >
            <AccordionSummary expandIcon={<ExpandMore />}>
              <Box paddingRight="10px">{c.icon}</Box>
              <Box paddingTop="2px">{c.title}</Box>
            </AccordionSummary>
            <AccordionDetails style={{ display: 'block' }}>
              {c.component}
            </AccordionDetails>
          </Accordion>
        ))}
      </Content>
    </BaseLayout>
  );
};
