import React, { useContext } from 'react';
import {
  Theme,
  CardHeader,
  Chip,
  Grid,
  styled,
  Typography,
} from '@material-ui/core';
import * as S from '../styles';
import { classifications } from './classifications';
import { ReviewSummaryContext } from '../context';
import { FlexCardContent } from './styles';

const getColorForCaption = (caption: string, theme: Theme) => {
  switch (caption) {
    case 'GREEN':
      return theme.palette.success.main;
    case 'YELLOW':
      return theme.palette.gold;
    case 'ORANGE':
      return theme.palette.warning.dark;
    case 'RED':
      return theme.palette.error.main;
    default:
      return theme.palette.success.main;
  }
};

const StyledChip = styled(Chip)(
  ({ theme, label }: { theme: Theme; label: string }) => ({
    backgroundColor: getColorForCaption(label, theme),
  }),
);

export const DataClassificationSummary = () => {
  const category: IAppReview.ReviewCategory = 'Data classification';
  const { review } = useContext(ReviewSummaryContext);

  const caption = classifications.filter(
    c => c.value === review.data_classification,
  )[0]?.caption;

  return (
    <>
      {review.data_classification && (
        <S.StyledCard>
          <Grid container>
            <Grid item xs={6}>
              <CardHeader
                title={category}
                titleTypographyProps={{ component: 'h2' } as any}
              />
            </Grid>
            <Grid item xs={6}>
              <FlexCardContent>
                <StyledChip label={review.data_classification} />
                <Typography style={{ fontWeight: 'bold' }}>
                  {caption}
                </Typography>
              </FlexCardContent>
            </Grid>
          </Grid>
        </S.StyledCard>
      )}
    </>
  );
};
