import React from 'react';
import {
  Box,
  CircularProgress,
  LinearProgress,
  Typography,
  withStyles,
} from '@material-ui/core';
import useStyles, { getLinearCompletionStyles } from '../styles';
import { HStack } from 'plugin-ui-components';
import { getColor } from '../../utils';

interface CustomLinearProgressProps {
  color: 'success' | 'warning' | 'error';
  value: number;
}

function CustomLinearProgress({ color, value }: CustomLinearProgressProps) {
  const Progress = withStyles(theme => getLinearCompletionStyles(theme, color))(
    LinearProgress,
  );
  return (
    <Progress
      variant="determinate"
      value={value}
      style={{
        borderRadius: 5,
        flex: 1,
      }}
    />
  );
}

interface ScorecardCompletionProps {
  completion: number;
  type: 'linear' | 'circular';
  size?: 'small' | 'medium' | 'large';
}

export function ScorecardCompletion({
  completion,
  type,
  size,
}: ScorecardCompletionProps) {
  const classes = useStyles();

  const getClassName = (completionPercentage: number): string => {
    if (completionPercentage >= 90) {
      return classes.highCompletion;
    }
    if (completionPercentage >= 70) {
      return classes.mediumCompletion;
    }
    return classes.lowCompletion;
  };

  function getSize(): number {
    switch (size) {
      case 'small':
        return 30;
      case 'medium':
        return 70;
      case 'large':
        return 100;
      default:
        return 70;
    }
  }

  return (
    <>
      {type === 'circular' && (
        <Box position="relative" width="fit-content" className="completion">
          <CircularProgress
            variant="determinate"
            value={100}
            className={classes.completionLayer}
            style={{ width: getSize(), height: getSize() }}
          />
          <CircularProgress
            variant="determinate"
            value={completion}
            className={`${getClassName(completion)} ${
              classes.completionProgress
            }`}
            style={{ width: getSize(), height: getSize() }}
          />
          <Box
            position="absolute"
            top="0"
            left="0"
            right="0"
            bottom="0"
            display="flex"
            justifyContent="center"
            alignItems="center"
            maxHeight={getSize()}
          >
            <Typography
              variant="body2"
              style={{
                fontWeight: 'bold',
              }}
            >
              {completion || 0}%
            </Typography>
          </Box>
        </Box>
      )}
      {type === 'linear' && (
        <HStack alignItems="center" flex={1} spacing=".5rem">
          <Box width="100%" mr={1}>
            <CustomLinearProgress
              color={getColor(completion)}
              value={completion}
            />
          </Box>
          <Box minWidth={35}>
            <Typography variant="body2" color="textSecondary">{`${
              completion || 0
            }%`}</Typography>
          </Box>
        </HStack>
      )}
    </>
  );
}
