import {
  createApiRef,
  DiscoveryApi,
  OAuthApi,
} from '@backstage/core-plugin-api';
import { DEFAULT_SELECT_OPTION } from '../constants/common';
import { PAGE_SIZE } from '../constants/PagingConstants';
import { ProjectForm } from '../hooks/useProjectForm';
import {
  ZflowApi,
  MLProject,
  GetMLProjectsResponse,
  CreateMLProjectResponse,
  UpdateMLProjectResponse,
  GetMLPipelinesResponse,
  GetPipelineRunsResponse,
  SearchResponse,
  UserTeam,
  GetProjectsParams,
  GetPipelinesParams,
  PatchBody,
  GetModelGroupsResponse,
  GetModelsResponse,
  MLModel,
  GetModelGroupsParams,
  GetModelsParams,
  ModelGroup,
  GetPipelineRunsParams,
  MLPipelineRun,
  MLPipeline,
  ZflowUserInfo,
  GetModelMetrics,
  GetModelMetricsParams,
  StartNewRunParams,
  StartNewRunResponse,
  AbortRunResponse,
  ArtifactDetails,
  ExperimentArchiveError,
} from './definitions';
import { HttpClient } from './httpClient';

export const zflowApiRef = createApiRef<ZflowApi>({
  id: 'zflow-api',
});

export default class ZflowAPIClient implements ZflowApi {
  public readonly httpClient: HttpClient;

  constructor(options: { oauth2Api: OAuthApi; discoveryApi: DiscoveryApi }) {
    this.httpClient = new HttpClient({
      pluginProxyEndpoint: 'zflow',
      ...options,
    });
  }

  // Projects
  async createProject(
    projectForm: ProjectForm,
  ): Promise<CreateMLProjectResponse> {
    return await this.httpClient
      .post(`/api/projects`, projectForm)
      .catch(error => error.violations);
  }

  async updateProject(
    projectId: string,
    projectForm: ProjectForm,
  ): Promise<UpdateMLProjectResponse> {
    const patch_body: PatchBody = {
      op: 'REPLACE',
      path: '/project_details',
      value: projectForm,
    };

    return await this.httpClient
      .patch(`/api/projects/${projectId}`, patch_body)
      .catch(error => error.violations);
  }

  async archiveProject(projectId: string): Promise<string> {
    const patch_body: PatchBody = {
      op: 'REPLACE',
      path: '/archive',
      value: {
        archive: true,
      },
    };

    return await this.httpClient
      .patch(`/api/projects/${projectId}`, patch_body)
      .catch(error => error.violations);
  }

  async getProject(projectId: string): Promise<MLProject> {
    return await this.httpClient.get(`/api/projects/${projectId}`);
  }

  async getProjects(
    limit: number,
    teamId: string | null,
    offset?: number | null,
  ): Promise<GetMLProjectsResponse> {
    const params: GetProjectsParams = {
      limit: limit,
    };

    if (teamId && teamId !== DEFAULT_SELECT_OPTION) {
      params.team_id = teamId;
    }

    if (offset) {
      params.offset = offset;
    }
    return await this.httpClient.get('/api/projects', params);
  }

  async getUserInfo(): Promise<ZflowUserInfo> {
    return await this.httpClient.get(`/api/users`);
  }

  async getTeamsWithProject(): Promise<Array<UserTeam>> {
    const params = {
      with_project: true,
    };
    return await this.httpClient.get(`/api/teams`, params);
  }

  // Pipelines
  async getPipelines(
    projectId: string,
    limit: number,
    deployment_environment?: string,
    offset?: number,
    archived?: boolean,
  ): Promise<GetMLPipelinesResponse> {
    const params: GetPipelinesParams = {
      project_id: projectId,
      limit: limit,
      archived: archived || false,
    };

    params.tags = 'deployed'; // show only pipelines, ignore experiments
    if (offset) {
      params.offset = offset;
    }

    if (
      deployment_environment &&
      deployment_environment !== DEFAULT_SELECT_OPTION
    ) {
      params.deployment_environment = deployment_environment;
    }

    return await this.httpClient.get('/api/pipelines', params);
  }

  async getPipeline(pipelineId: string): Promise<MLPipeline> {
    return await this.httpClient.get(`/api/pipelines/${pipelineId}`);
  }

  async getPipelineRuns(
    pipelineId: string,
    limit: number,
    offset?: number,
  ): Promise<GetPipelineRunsResponse> {
    const params: GetPipelineRunsParams = {
      pipeline_id: pipelineId,
      limit: limit,
      archived: false,
    };

    if (offset) {
      params.offset = offset;
    }

    return await this.httpClient.get('/api/runs', params);
  }

  async getPipelineRun(runId: string): Promise<MLPipelineRun> {
    return await this.httpClient.get(`/api/runs/${runId}`);
  }

  async startRun(
    projectId: string,
    pipelineId: string,
    runName: string,
    status: string,
    executionInput: string | null,
  ): Promise<StartNewRunResponse> {
    const params: StartNewRunParams = {
      project_id: projectId,
      pipeline_id: pipelineId,
      run_name: runName,
      status: status,
      should_execute_pipeline: true,
    };
    if (executionInput) params.execution_input = executionInput;
    return await this.httpClient
      .post('/api/runs', params)
      .catch(error => error.violations);
  }

  async abortRun(runId: string): Promise<AbortRunResponse> {
    return await this.httpClient
      .put(`/api/runs/${runId}`, { stop_pipeline_execution: true })
      .catch(error => error.violations);
  }

  // Models
  async getModelGroups(
    projectId: string,
    limit: number,
    offset?: number,
    deploymentEnvironment?: string,
  ): Promise<GetModelGroupsResponse> {
    const params: GetModelGroupsParams = {
      project_id: projectId,
      limit: limit,
    };
    if (offset) {
      params.offset = offset;
    }
    if (
      deploymentEnvironment &&
      deploymentEnvironment !== DEFAULT_SELECT_OPTION
    ) {
      params.deployment_environment = deploymentEnvironment;
    }
    return await this.httpClient.get('/api/model-groups', params);
  }

  async getModelGroup(modelGroupId: string): Promise<ModelGroup> {
    return await this.httpClient.get(`/api/model-groups/${modelGroupId}`);
  }

  async getModels(
    modelGroupId: string,
    limit: number,
    offset?: number,
  ): Promise<GetModelsResponse> {
    const params: GetModelsParams = {
      model_group_id: modelGroupId,
      limit: limit,
    };

    if (offset) {
      params.offset = offset;
    }
    return await this.httpClient.get('/api/models', params);
  }

  async getModel(modelId: string): Promise<MLModel> {
    return await this.httpClient.get(`/api/models/${modelId}`);
  }

  async getModelMetrics(
    runId: string,
    limit: number,
    trainingJobIds?: string[],
    key?: string,
    offset?: number,
  ): Promise<GetModelMetrics> {
    const params: GetModelMetricsParams = {
      run_id: runId,
      limit: limit,
    };

    if (offset) {
      params.offset = offset;
    }

    if (key && key !== DEFAULT_SELECT_OPTION) {
      params.key = key;
    }

    if (trainingJobIds) {
      params.training_job_ids = trainingJobIds.join(',');
    }

    if (offset) {
      params.offset = offset;
    }

    return await this.httpClient.get('/api/metrics', params);
  }
  getPipelineFromType =
    (tags: string) =>
    async (
      projectId: string,
      limit: number,
      deployment_environment?: string,
      offset?: number,
      archived?: boolean,
    ) => {
      const params: GetPipelinesParams = {
        project_id: projectId,
        limit: limit,
        archived: archived || false,
      };

      params.tags = tags;
      if (offset) {
        params.offset = offset;
      }

      if (
        deployment_environment &&
        deployment_environment !== DEFAULT_SELECT_OPTION
      ) {
        params.deployment_environment = deployment_environment;
      }
      return await this.httpClient.get('/api/pipelines', params);
    };

  async getExperimentPipelines(
    projectId: string,
    limit: number,
    deployment_environment?: string,
    offset?: number,
    archived?: boolean,
  ): Promise<GetMLPipelinesResponse> {
    return this.getPipelineFromType('experimental')(
      projectId,
      limit,
      deployment_environment,
      offset,
      archived,
    );
  }

  async archiveExperimentPipeline(pipelineId: string): Promise<string> {
    const patch_body: PatchBody = {
      op: 'REPLACE',
      path: '/archived',
      value: {
        archived: true,
      },
    };
    return await this.httpClient
      .patch(`/api/pipelines/${pipelineId}`, patch_body)
      .catch(error => {
        throw new ExperimentArchiveError(error.message, error.violations[0]);
      });
  }

  async archiveExperimentRun(runId: string): Promise<string> {
    const patch_body = {
      archived: true,
    };
    return await this.httpClient
      .patch(`/api/runs/${runId}`, patch_body)
      .catch(error => {
        throw new ExperimentArchiveError(error.message, error.violations[0]);
      });
  }

  // Search
  async search(query: string): Promise<SearchResponse> {
    return await this.httpClient.get(
      `/api/entities?q=${query}&limit=${PAGE_SIZE}`,
    );
  }

  async getArtifactDetails(artifactId: string): Promise<ArtifactDetails> {
    return await this.httpClient.get(`/api/artifacts/${artifactId}`);
  }

  async patchModel(modelId: string, payload: any): Promise<string> {
    return await this.httpClient.patch(`/api/models/${modelId}`, payload);
  }

  async approveModel(modelId: string, comments: string): Promise<string> {
    const payload = {
      path: '/approve-model',
      op: 'APPROVE_MDOEL',
      value: {
        approval: {
          approval_status: 'Approved',
          comments: comments,
        },
      },
    };
    return await this.patchModel(modelId, payload);
  }

  async rejectModel(modelId: string, comments: string): Promise<string> {
    const payload = {
      path: '/approve-model',
      op: 'APPROVE_MDOEL',
      value: {
        approval: {
          approval_status: 'Rejected',
          comments: comments,
        },
      },
    };
    return await this.patchModel(modelId, payload);
  }

  async getModelsByRunId(runId: string): Promise<GetModelsResponse> {
    return await this.httpClient.get(`/api/models?run_id=${runId}`);
  }
}
