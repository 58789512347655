import { Content, Header, Page } from '@backstage/core-components';
import { configApiRef, useApi } from '@backstage/core-plugin-api';
import { Box, Card, CardContent, CardHeader } from '@material-ui/core';
import React from 'react';
import { useAsync } from 'react-use';
import { useMutation } from 'react-query';
import { oauth2ApiRef } from 'plugin-core';
import { ToolingForm, ToolingFormInput } from '../ToolingForm';
import { Alert } from '@material-ui/lab';
import { stringifyEntityRef } from '@backstage/catalog-model';
import { catalogAdditionalApiRef } from 'plugin-catalog';
import { Tracking } from '../../tracking';
import { EventTracker } from 'plugin-ui-components';
import Container from '@mui/material/Container';

export const ToolingRegisterPage = () => {
  const oauth2Api = useApi(oauth2ApiRef);
  const configApi = useApi(configApiRef);
  const catalogAdditionalApi = useApi(catalogAdditionalApiRef);
  const { value: token } = useAsync(() => oauth2Api.getAccessToken());

  // * Create a mutation to send the tooling object to the API
  const toolingMutation = useMutation({
    mutationFn: async (newTooling: ToolingFormInput) => {
      // Clean empty fields to avoid validation error in backend
      for (const field in newTooling) {
        if (newTooling.hasOwnProperty(field)) {
          const value = (newTooling as any)[field];
          // compare value with an empty string
          if (value === '') {
            // remove the field from the object
            delete (newTooling as any)[field];
          }
        }
      }
      const resp = await fetch(
        `${configApi.getString('backend.baseUrl')}/tooling`,
        {
          method: 'POST',
          headers: new Headers({
            authorization: `Bearer ${token}`,
            'content-type': 'application/json',
          }),
          body: JSON.stringify(newTooling),
        },
      );
      if (!resp.ok) throw new Error('Failed to create tooling');
      const entityRef = {
        kind: 'Tooling',
        namespace: 'default',
        name: newTooling.name,
      };
      const refreshResp = await catalogAdditionalApi.importEntity(
        stringifyEntityRef(entityRef),
      );
      if (refreshResp.error || !refreshResp.added) {
        throw new Error(
          "Tooling was successfully added but the creation in the catalog can take up to 10 minutes to finalize. Then refresh the Toolings page to see if it's available.",
        );
      }
    },
  });

  return (
    <Page themeId="tooling">
      <Header
        title="Create a new Tooling entry"
        subtitle="Fill out this form to add a new entry to the tooling catalog."
      />
      <Content>
        <Container maxWidth="lg">
          <Card>
            <CardHeader title="Enter Tooling information" />
            <CardContent>
              {toolingMutation.isSuccess && (
                <EventTracker {...Tracking.createTooling()}>
                  <Box marginBottom={2}>
                    <Alert severity="success">
                      Tooling was successfully added but the creation in the
                      catalog can take up to 10 minutes to finalize. Then
                      refresh the Toolings page to see if it's available.
                    </Alert>
                  </Box>
                </EventTracker>
              )}
              {toolingMutation.isError && (
                <Box marginBottom={5}>
                  <Alert severity="error">
                    An error occurred while submitting the form:{' '}
                    {(toolingMutation.error as Error).message}
                  </Alert>
                </Box>
              )}
              <ToolingForm
                mode="create"
                isLoading={toolingMutation.isLoading}
                onSubmit={tooling => toolingMutation.mutate(tooling)}
              />
            </CardContent>
          </Card>
        </Container>
      </Content>
    </Page>
  );
};
