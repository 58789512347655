import React from 'react';
import { FormControl, Chip, TextField, makeStyles } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';

export interface AxisOptions {
  key: string;
  value: string | number;
  parentId?: string;
  createdAt?: string;
  type?: string;
}

interface AxisDropDownProps {
  title: string;
  options: AxisOptions[];
  onChange: (value: any) => void;
  multiple?: boolean;
  default?: AxisOptions | AxisOptions[];
}

const useStyles = makeStyles(() => ({
  formControl: {
    minWidth: '100%',
    marginTop: '20px',
    maxWidth: '170px',
  },
}));

type OptionMapType = { [key: string]: Set<string> };

const getOptions = (props: AxisDropDownProps) => {
  const optionsMap: OptionMapType = props.options.reduce((acc, option) => {
    const groupKey = option.type || '';
    const res: OptionMapType = acc.hasOwnProperty(groupKey)
      ? acc
      : { ...acc, [groupKey]: new Set() };
    res[groupKey].add(option.key);
    return res;
  }, {});

  return Object.keys(optionsMap).reduce((acc, type) => {
    const a = Array.from(optionsMap[type]);
    const res = a.map(key => ({ key, type, value: key }));
    return acc.concat(res);
  }, [] as AxisOptions[]);
};

export const AxisDropDown = (props: AxisDropDownProps) => {
  const classes = useStyles();
  const renderTags = (values: AxisOptions[], getTagProps: any) => (
    <>
      {values.map((value, index) => (
        <Chip
          key={value.key}
          label={value.key}
          variant="outlined"
          size="small"
          color="primary"
          title={value.key}
          {...getTagProps({ index })}
        />
      ))}
    </>
  );

  const renderInput = (params: any) => (
    <TextField
      variant="outlined"
      margin="dense"
      label={props.title}
      {...params}
    />
  );

  return (
    <FormControl className={classes.formControl}>
      <Autocomplete
        id={props.title}
        multiple={props.multiple}
        options={getOptions(props)}
        getOptionLabel={option => option.key || ''}
        defaultValue={props.default}
        groupBy={option => option.type || ''}
        filterSelectedOptions
        renderTags={renderTags}
        renderInput={renderInput}
        onChange={(e, values) => {
          e.stopPropagation();
          const value = Array.isArray(values) ? values : [values];
          const res = value.filter(x => x).map(x => x?.key);
          props.onChange(props.multiple ? res : res[0]);
        }}
        disableClearable
      />
    </FormControl>
  );
};
