import React, { useContext, useState, useEffect } from 'react';
import { Alert } from '@material-ui/lab';
import { Link } from '@backstage/core-components';
import {
  CardContent,
  Chip,
  Divider,
  Grid,
  Typography,
} from '@material-ui/core';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import {
  isCommentsField,
  isConfirmationField,
  isURLField,
} from '../../utils/fields';
import { replaceIfNeeded } from '../../utils/language';
import { config } from '../config';
import { ReviewSummaryContext } from '../context';
import * as S from '../styles';

const options: Record<any, IAppReview.ItemResponseHuminazed> = {
  not_sure: 'Not sure',
  yes: 'Yes',
  no: 'No',
  unknown: 'Unknown',
  green: 'Green',
  red: 'Red',
  amber: 'Amber',
  notrelevant: 'Not relevant',
};

interface IData {
  okKey: string;
  commentsKey: string;
  categoryKeys: string[];
}

export const DefaultSummary = ({
  category,
}: {
  category: IAppReview.ReviewCategory;
}) => {
  const { review, entity } = useContext(ReviewSummaryContext);
  const [data, setData] = useState<IData>({
    okKey: '',
    commentsKey: '',
    categoryKeys: [],
  });

  useEffect(() => {
    let okKey: string = '';
    let commentsKey: string = '';
    const categoryKeys: string[] = [];

    Object.keys(review).forEach(key => {
      if (config[key]?.category === category) {
        if (isCommentsField(key)) {
          commentsKey = key;
        } else if (isConfirmationField(key)) {
          okKey = key;
        } else {
          categoryKeys.push(key);
        }
      }
    });

    setData({ okKey, commentsKey, categoryKeys });
  }, [category, review]);

  return (
    <>
      {(!!data.categoryKeys.length || data.commentsKey) && (
        <S.StyledCard>
          <S.StyledCardHeader
            title={
              <div>
                <span>{category}</span>
                {review[data.okKey] === 'n/a' && review[data.commentsKey] && (
                  <Chip
                    size="small"
                    style={{ margin: '0 0.5rem' }}
                    label="Not applicable"
                  />
                )}
              </div>
            }
            titleTypographyProps={{ component: 'h2' } as any}
          />
          <Divider />
          {data.commentsKey && review[data.commentsKey] && (
            <Alert severity="info" style={{ margin: 0, borderRadius: 0 }}>
              <b>Rationale provided:</b> {review[data.commentsKey]}
            </Alert>
          )}
          {data.categoryKeys.map(item => (
            <CardContent key={item}>
              <Grid container>
                <Grid item xs={10}>
                  <S.Label>
                    {replaceIfNeeded(config[item]?.label, entity.spec?.spec)}
                  </S.Label>
                </Grid>
                <Grid item xs={2}>
                  {isURLField(item) ? (
                    <S.Label align="right" style={{ fontWeight: 'bold' }}>
                      {review[item] === 'no' ? (
                        'No'
                      ) : (
                        <Link to={review[item]}>
                          View <OpenInNewIcon />
                        </Link>
                      )}
                    </S.Label>
                  ) : (
                    <Typography align="right" style={{ fontWeight: 'bold' }}>
                      {options[review[item]] || review[item]}
                    </Typography>
                  )}
                </Grid>
              </Grid>
            </CardContent>
          ))}
        </S.StyledCard>
      )}
    </>
  );
};
