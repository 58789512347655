import React, { useState } from 'react';
import { ButtonGroup, IconButton } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import DashboardIcon from '@material-ui/icons/Dashboard';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { useCopyToClipboard } from 'react-use';
import { DismissableBanner } from '@backstage/core-components';
import { Snackbar } from '../Snackbar';
import { Confirmation } from '../Confirmation';

interface ActionsTableCellProps {
  parentEntityType: string;
  id: string;
  name: string;
  is_interactive?: boolean;
  archived: boolean;
  childEntityType: string;
  childId?: string;
  onDelete: (e: React.SyntheticEvent, id: string) => any;
}

export const ActionsTableCell = (props: ActionsTableCellProps) => {
  const {
    parentEntityType,
    id,
    name,
    is_interactive,
    archived,
    childEntityType,
    childId,
    onDelete,
  } = props;
  const [copiedState, copyToClipboard] = useCopyToClipboard();
  const [snackBarMsg, setSnackBarMsg] = useState('');

  const [open, setOpen] = useState(false);

  const openDialog = (e: React.SyntheticEvent) => {
    e.stopPropagation();
    setOpen(true);
  };

  const closeDialog = (e: React.SyntheticEvent) => {
    e.stopPropagation();
    setOpen(false);
  };

  const onCopyUrl = (event: any, _id: string, isInteractive: boolean) => {
    event?.stopPropagation();
    const location = window.location.href.split('/notebooks/')[0];
    const parentEntityUrl = `${location}/notebooks/${
      parentEntityType === 'pipelines'
        ? 'experiment_notebooks'
        : parentEntityType
    }/${_id}/${childEntityType}`;
    const baseUrl = `${parentEntityUrl}/${childId || 'latest'}`;
    const childEntityUrl = isInteractive ? `${baseUrl}/interactive` : baseUrl;
    const urlToCopy =
      parentEntityType === 'experiment_notebooks' ||
      parentEntityType === 'pipelines'
        ? parentEntityUrl
        : childEntityUrl;
    copyToClipboard(urlToCopy);
    setSnackBarMsg('Copied to clipboard!');
  };

  const handleDelete = (e: any) => {
    setSnackBarMsg(`Deleting ${id}`);
    onDelete(e, childId || id);
    setOpen(false);
  };

  const getTitle = (type: string) =>
    childId ? `Copy ${type} url` : `Copy latest ${type} url`;

  return (
    <>
      <Snackbar message={snackBarMsg} onClose={setSnackBarMsg} />
      <ButtonGroup size="small" variant="outlined">
        <IconButton
          onClick={e => onCopyUrl(e, id, true)}
          title={getTitle('dashboard')}
          disabled={!is_interactive}
        >
          {is_interactive && parentEntityType !== 'pipelines' ? (
            <DashboardIcon />
          ) : null}
        </IconButton>
        <IconButton
          onClick={e => onCopyUrl(e, id, false)}
          title={getTitle('notebook')}
        >
          <FileCopyIcon />
        </IconButton>
        <IconButton
          color="secondary"
          onClick={openDialog}
          disabled={archived}
          title="Delete"
        >
          <DeleteIcon />
        </IconButton>
      </ButtonGroup>
      {copiedState.error ? (
        <DismissableBanner
          message="Failed to copy text"
          variant="info"
          id="copy_error"
          fixed
        />
      ) : null}
      <Confirmation
        open={open}
        handleCancel={closeDialog}
        handleConfirm={handleDelete}
        cancelText="Cancel"
        confirmText="Delete"
        titleText={`Deleting ${childId ? 'Execution' : 'Notebook'}`}
        bodyText={`Are you sure you want to delete ${name}?`}
      />
    </>
  );
};
